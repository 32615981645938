import {forwardRef} from 'react';
import {Control, Controller} from 'react-hook-form';
import {Input} from "@/components/tailwind/input.tsx";

interface NullableTextInputProps {
    name: string;
    control: Control<any>;
    className?: string;
    placeholder?: string;
    'aria-invalid'?: boolean;
    id?: string;
    autoComplete?: string;
}

export const NullableTextInput = forwardRef<HTMLInputElement, NullableTextInputProps>(
    ({name, control, ...props}, forwardedRef) => (
        <Controller
            name={name}
            control={control}
            render={({field: {ref, onChange, value, ...fieldProps}}) => (
                <Input
                    type="text"
                    ref={forwardedRef}
                    value={value ?? ''}
                    onChange={e => onChange(e.target.value.trim() || null)}
                    {...fieldProps}
                    {...props}
                />
            )}
        />
    )
);

NullableTextInput.displayName = 'NullableTextInput';
