import {useNavigate, useParams} from "react-router-dom";
import {Heading, Subheading} from "@/components/tailwind/heading.tsx";
import {useEffect, useState} from "react";
import {v4 as uuid} from 'uuid'
import {Input} from "@/components/tailwind/input.tsx";
import {Button} from "@/components/tailwind/button.tsx";
import {Text} from "@/components/tailwind/text.tsx";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from 'yup'
import {Divider} from "@/components/tailwind/divider.tsx";
import {Select} from "@/components/tailwind/select.tsx";
import {useAuth} from "@/context/use-auth.tsx";
import {UserDTO} from "@/models/user.ts";
import {createNewUser} from "@/services/user-services.ts";
import ValidationAlert from "../../../components/validation-alert.tsx";
import {ProblemDetail} from "@/types/problem-details.ts";
import {Trans, useTranslation} from "react-i18next";

type NewUserFormInput = {
    id: string,
    username: string,
    password: string,
    confirm_password: string,
    role: "ADMIN" | "USER",
    name: string,
    active: boolean,
}


function validation() {
    const {t} = useTranslation()

    return Yup.object().shape(
        {
            id:
                Yup.string()
                    .uuid(t("userForm.errors.invalid-id"))
                    .required(t("userForm.errors.missing-id")),
            username:
                Yup.string()
                    .matches(/^[A-Za-z0-9]/, t("userForm.errors.invalid-username"))
                    .required(t("userForm.errors.missing-username")),
            password:
                Yup.string()
                    .min(8, t("userForm.errors.password-min-length", {minLength: 8}))
                    .required(t("userForm.errors.missing-password")),
            confirm_password:
                Yup.string()
                    .required(t("userForm.errors.missing-confirm-password"))
                    .oneOf([Yup.ref('password')], t("userForm.errors.password-mismatch")),
            role:
                Yup.string()
                    .oneOf(["ADMIN", "USER"], t("userForm.errors.invalid-role"))
                    .required(t("userForm.errors.missing-role")),
            active:
                Yup.boolean().default(false),
            name:
                Yup.string().required(t("userForm.errors.missing-name"))
        }
    )
}

export default function NewUserPage() {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {userId} = useParams()
    const [userData, setUserData] = useState<UserDTO>()
    const {token} = useAuth()
    const {
        register,
        handleSubmit,
        setError,
        reset,
        formState: {errors},
        watch
    } = useForm<NewUserFormInput>({
            resolver: yupResolver(validation()),
            defaultValues: {active: true}
        }
    );
    const isActive = watch("active", true)

    useEffect(() => {
        // create default
        const newUser = {} as UserDTO
        setUserData(newUser)
    }, []);

    useEffect(() => {
        reset({
            id: userData?.id,
            name: userData?.name,
            username: userData?.username,
            active: userData?.active,
            role: userData?.role,
        } as NewUserFormInput)
    }, [userData])

    function handleSubmission(form: NewUserFormInput) {
        const userData: UserDTO = {
            active: form.active,
            id: form.id,
            name: form.name,
            password: form.password,
            role: form.role,
            username: form.username
        }

        const response = createNewUser(userData, token!!)

        response
            .then((_) => {
                    // TODO initiate toaster as well (See also https://github.com/steinhauer-software/die-nachbarschaftshilfe-app/issues/16)
                    // TODO centralize paths (See also https://github.com/steinhauer-software/die-nachbarschaftshilfe-app/issues/17)

                    navigate("/admin/users")
                }
            )
            .catch(
                (reason: ProblemDetail) => {
                    // some reason
                    console.warn("An error occurred while performing create user request", reason)

                    if (reason.validationIssues) {
                        reason.validationIssues.map(
                            finding => {
                                setError(
                                    finding.field as "id" | "username" | "password" | "confirm_password" | "role" |
                                        "name" | "active",
                                    {message: finding.message}
                                )
                            }
                        )


                    }

                    // set additional error message
                    setError(
                        "root.serverError",
                        {
                            type: `${reason.status}`,
                            message: t("serverError.response-code", {statusCode: reason.status})
                        }
                    )

                }
            )

    }

    return (
        <form onSubmit={handleSubmit(handleSubmission)} className="mx-auto max-w-4xl">
            <Heading>{userId === "new" ? t("userForm.mainHeader.new") : t("userForm.mainHeader.edit")}</Heading>
            <Divider className="my-10 mt-6"/>
            <Input
                id="id"
                type="hidden"
                {...register("id")}
                defaultValue={uuid()}
            />

            <section className="grid gap-x-8 gap-y-6 sm:grid-cols-2">
                <div className="space-y-1">
                    <Subheading>{t("userForm.fields.name.subheading")}</Subheading>
                    <Text>{t("userForm.fields.name.text")}</Text>
                </div>
                <div>
                    <Input aria-label={t("userForm.fields.name.text")} type="text" {...register("name")}
                           placeholder={t("userForm.fields.name.placeholder")} invalid={!!errors.name}
                           autoComplete="name"/>
                    {errors.name &&
                        <ValidationAlert message={t(errors.name.message || 'userForm.errors.unknown-name-issue')}/>}
                </div>
            </section>

            <Divider className="my-10" soft/>

            <section className="grid gap-x-8 gap-y-6 sm:grid-cols-2">
                <div className="space-y-1">
                    <Subheading id="usernameHeadline"><Trans
                        i18nKey="userForm.fields.username.subheading"/></Subheading>
                    <Text id="usernameDescriptions"><Trans i18nKey="userForm.fields.username.text"/></Text>
                </div>

                <div>
                    <Input aria-label={t("userForm.fields.username.text")} type="text"
                           autoComplete="username" {...register("username")}
                           placeholder={t("userForm.fields.username.placeholder")} invalid={!!errors.username}/>
                    {errors.username &&
                        <ValidationAlert
                            message={t(errors.username.message || 'userForm.errors.unknown-username-issue')}/>}
                </div>
            </section>

            <Divider className="my-10" soft/>

            <section className="grid gap-x-8 gap-y-6 sm:grid-cols-2">
                <div className="space-y-1">
                    <Subheading id="password"><Trans i18nKey="userForm.fields.password.subheading"/></Subheading>
                    <Text><Trans i18nKey="userForm.fields.password.text"/><br/><sup><Trans
                        i18nKey="userForm.fields.password.additionalText" values={{minLength: "8"}}/></sup></Text>
                </div>
                <div>
                    <Input aria-label={t("userForm.fields.password.text")} type="password"
                           autoComplete="new-password" {...register("password")} placeholder="*****" minLength={8}
                           invalid={!!errors.password}/>
                    {errors.password &&
                        <ValidationAlert
                            message={t(errors.password.message || 'userForm.errors.unknown-password-issue')}/>}
                </div>
            </section>

            <Divider className="my-10" soft/>

            <section className="grid gap-x-8 gap-y-6 sm:grid-cols-2">
                <div className="space-y-1">
                    <Subheading id="confirmPassword"><Trans
                        i18nKey="userForm.fields.confirmPassword.subheading"/></Subheading>
                    <Text><Trans i18nKey="userForm.fields.confirmPassword.text"/></Text>
                </div>
                <div>
                    <Input aria-label={t("userForm.fields.confirmPassword.text")} autoComplete="new-password"
                           type="password" {...register("confirm_password")} placeholder="*****"
                           invalid={!!errors.confirm_password}/>
                    {errors.confirm_password &&
                        <ValidationAlert
                            message={errors.confirm_password.message || "Bitte wiederholtes Passwort überprüfen"}/>}
                </div>
            </section>

            <Divider className="my-10" soft/>

            <section className="grid gap-x-8 gap-y-6 sm:grid-cols-2">
                <div className="space-y-1">
                    <Subheading><Trans i18nKey="userForm.fields.role.subheading"/></Subheading>
                    <Text><Trans i18nKey="userForm.fields.role.text"/></Text>
                </div>
                <div>
                    <Select aria-label={t("userForm.fields.role.text")} {...register("role")} invalid={!!errors.role}>
                        {["USER", "ADMIN"].map((role) => (
                            <option key={role} value={role}>{t(`userForm.fields.role.options.${role}`)}</option>
                        ))}
                    </Select>
                    {errors.role &&
                        <ValidationAlert message={t(errors.role.message || 'unknown-role-issue')}/>}
                </div>
            </section>

            <Divider className="my-10" soft/>

            <section className="grid gap-x-8 gap-y-6 sm:grid-cols-2">
                <div className="space-y-1">
                    <Subheading id="active-headline"><Trans i18nKey="userForm.fields.active.subheading"/></Subheading>
                    <Text id="active-description"><Trans i18nKey="userForm.fields.active.text"/></Text>
                </div>
                <div>
                    {/*<RadixBasedCheckbox {...register("active")} />*/}
                    <div className="flex items-center space-x-2">
                        <input type={"checkbox"}
                               {...register("active")}
                               aria-labelledby="active-headline active-description"
                        />
                        <Text>({t("userForm.fields.active.checkbox." + (isActive ? "active" : "inactive"))})</Text>
                    </div>
                    {errors.active &&
                        <ValidationAlert message={t(errors.active.message || "unknown-active-issue")}/>}
                </div>
            </section>

            <Divider className="my-10" soft/>

            <section className="flex justify-end gap-4">
                <div className="space-x-1.5">
                    <Button aria-label={t("userForm.buttons.cancel.ariaLabel")} href="/admin/users"><Trans
                        i18nKey="userForm.buttons.cancel.text"/></Button>
                    <Button aria-label={t("userForm.buttons.submit.ariaLabel")} type="submit"><Trans
                        i18nKey="userForm.buttons.submit.text"/></Button>
                </div>
            </section>

        </form>
    )
}
