import {Controller, useForm} from 'react-hook-form';
import {z} from 'zod';
import {zodResolver} from '@hookform/resolvers/zod';
import {Trans, useTranslation} from 'react-i18next';
import {Dialog, DialogActions, DialogBody, DialogTitle} from '@/components/tailwind/dialog';
import {Button} from '@/components/tailwind/button';
import {Member, MembershipStatus, MemberUpdateMembershipRequest} from "@/models/member.ts";
import ValidationAlert from "@/components/validation-alert.tsx";
import {Field, Label} from "@/components/tailwind/fieldset.tsx";
import {updateMemberMembership} from "@/services/member-services.ts";
import {useAuth} from "@/context/use-auth.tsx";
import {useEffect, useMemo} from "react";
import {Input} from "@/components/tailwind/input.tsx";
import {Select} from "@/components/tailwind/select.tsx";

interface MemberEditMembershipDialogProps {
    isOpen: boolean;
    onSave: (data: Member) => void;
    onClose: () => void;
    memberData: Member;
    availableStatus: MembershipStatus[]
}

function isValidDate(dateStr: string): boolean {
    const date = new Date(dateStr);
    return !isNaN(date.getTime());
}


const memberEditMembershipSchema = z.object({
    membershipStartDate: z.string()
        .refine((value) => Boolean(value) && isValidDate(value),
            {
                message: "memberEditDialog.membership.errors.invalid-membership-start-date-format"
            }
        )
        .refine(
            (value) => value !== undefined && value !== null && value !== "",
            {
                message: "memberEditDialog.membership.errors.membership-start-required"
            }
        ),

    membershipStatus: z.object({
        id: z.string()
            .uuid({
                message: "memberEditDialog.membership.errors.invalid-membership-status"
            })
            .min(1, {
                message: "memberEditDialog.membership.errors.membership-status-required"
            }),
        value: z.string()
            .min(1, {
                message: "memberEditDialog.membership.errors.membership-status-required"
            })
    })
}) satisfies z.ZodType<{
    membershipStartDate: string;
    membershipStatus: MembershipStatus;
}>;


export type MemberEditMembershipFormData = z.infer<typeof memberEditMembershipSchema>;

export default function MemberEditMembershipDialog({
                                                       isOpen,
                                                       onSave,
                                                       onClose,
                                                       memberData,
                                                       availableStatus,
                                                   }: MemberEditMembershipDialogProps) {
    const {t} = useTranslation();
    const defaultValues = useMemo(() => ({
        membershipStartDate: memberData.membershipStartDate,
        membershipStatus: memberData.membershipStatus,
    }), [memberData.membershipStartDate, memberData.membershipStatus]);

    const {
        handleSubmit,
        setError,
        control,
        formState: {errors},
        reset
    } = useForm<MemberEditMembershipFormData>({
        resolver: zodResolver(memberEditMembershipSchema),
        defaultValues
    });

    useEffect(() => {
        if (isOpen && memberData) {
            reset(defaultValues);
        }
    }, [isOpen, memberData, reset]);

    const {token} = useAuth();

    async function saveData(data: MemberEditMembershipFormData): Promise<boolean> {

        const membershipStartDate = new Date(data.membershipStartDate);
        const dateOfBirth = new Date(memberData.dateOfBirth!!)
        if (membershipStartDate < dateOfBirth) {
            setError("membershipStartDate", {
                type: "manual",
                message: "memberEditDialog.membership.errors.start-date-before-birthday"
            })

            return false;
        }

        const membershipData: MemberUpdateMembershipRequest = {
            memberId: memberData.id,
            membershipStartDate: data.membershipStartDate,
            membershipStatusId: data.membershipStatus.id,
        }

        try {
            const response = await updateMemberMembership(token, membershipData);
            if (response) {
                onSave(response)
            }
            return true
        } catch (error: any) {
            console.error("Error updating member: ", error);
            if (Boolean(error.status)) {
                setError("root", {
                    message: t("serverError.response-code-with-details", {
                        statusCode: error.status,
                        errorDetails: error.error
                    })
                });
            } else {
                setError("root", {message: t("serverError.unknown-with-details", {errorDetails: error.message})});
            }

            return false;
        }

    }

    async function onSubmit(data: MemberEditMembershipFormData) {
        if (await saveData(data)) {
            handleClosing();
        }
    }

    function handleClosing() {
        reset();
        onClose();
    }

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle>
                <Trans i18nKey="memberEditDialog.membership.title"/>
            </DialogTitle>

            <DialogBody>
                {errors.root && (
                    <ValidationAlert message={errors.root?.message ||
                        t("serverError.unknown")}/>
                )}
                <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
                    <div>
                        <Field>
                            <Label><Trans i18nKey="memberEditDialog.membership.fields.membershipStart.label"/></Label>
                            <Controller
                                name="membershipStartDate"
                                control={control}
                                render={({field}) => (
                                    <Input
                                        type="date"
                                        value={field.value || ''}
                                        max={new Date().toISOString().split('T')[0]}
                                        onChange={(e) => {
                                            const value = e.target.value || null;
                                            field.onChange(value);
                                        }}
                                        aria-label={t("memberEditDialog.membership.fields.membershipStart.ariaLabel")}
                                        invalid={Boolean(errors.membershipStartDate)}
                                    />
                                )}
                            />
                        </Field>
                        {errors.membershipStartDate && (
                            <ValidationAlert
                                message={t(errors.membershipStartDate?.message ||
                                    "memberEditDialog.membership.errors.unknown-membership-start-date-issue")}
                            />
                        )}
                    </div>

                    <div>
                        <Field>
                            <Label><Trans i18nKey="memberEditDialog.membership.fields.status.label"/></Label>
                            <Controller
                                name="membershipStatus"
                                control={control}
                                rules={{required: true}}
                                defaultValue={availableStatus?.[0]}
                                render={({field}) => (
                                    <Select
                                        id="membership-status"
                                        value={field.value?.id}
                                        onChange={e => {
                                            const id = e.target.value;
                                            field.onChange(availableStatus?.find(s => s.id === id));
                                        }}
                                        aria-label={t("memberEditDialog.membership.fields.status.ariaLabel")}
                                        invalid={Boolean(errors.membershipStatus)}
                                    >
                                        {availableStatus?.map(item => (
                                            <option key={item.id} value={item.id}>
                                                {item.value}
                                            </option>
                                        ))}
                                    </Select>
                                )}
                            />

                        </Field>
                        {errors.membershipStatus && (
                            <ValidationAlert
                                message={t(errors.membershipStatus?.message ||
                                    "memberEditDialog.membership.errors.unknown-membership-status-issue")}
                            />
                        )}
                    </div>

                    <DialogActions>
                        <Button
                            type="button"
                            plain
                            onClick={handleClosing}
                            aria-label={t("memberEditDialog.membership.buttons.cancel.ariaLabel")}
                        >
                            <Trans i18nKey="memberEditDialog.membership.buttons.cancel.text"/>
                        </Button>
                        <Button
                            type="submit"
                            aria-label={t("memberEditDialog.membership.buttons.save.ariaLabel")}
                        >
                            <Trans i18nKey="memberEditDialog.membership.buttons.save.text"/>
                        </Button>
                    </DialogActions>
                </form>
            </DialogBody>
        </Dialog>
    );
}
