import {useEffect, useMemo, useState} from 'react';
import {Dialog, DialogActions, DialogBody, DialogTitle} from '@/components/tailwind/dialog';
import {Button} from '@/components/tailwind/button';
import {Input} from '@/components/tailwind/input';
import {Field, Label} from "@/components/tailwind/fieldset.tsx";
import {z} from 'zod';
import {v4 as uuid} from 'uuid';
import {Trans, useTranslation} from 'react-i18next';
import ValidationAlert from "@/components/validation-alert.tsx";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {createServiceGroup, updateServiceGroup} from "@/services/service-services.ts";
import {useAuth} from "@/context/use-auth.tsx";
import {ServiceGroup} from "@/models/service.ts";
import {Textarea} from "@/components/tailwind/textarea.tsx";
import {ValidationIssue} from "@/types/problem-details.ts";
import {useToast} from "@/hooks/use-toast.ts";

const serviceGroupSchema = z.object({
    id: z.string().uuid(),
    name: z.string().min(1, {message: "serviceGroupDialog.errors.name-required"}),
    description: z.string().optional(),
});

export type ServiceGroupFormValues = z.infer<typeof serviceGroupSchema>;


type ServiceGroupDialogProps = {
    isOpen: boolean;
    onClose: () => void;
    onSave: () => void;
    initialData?: Partial<ServiceGroupFormValues>;
    title?: string;
}

export function ServiceGroupDialog({
                                       isOpen,
                                       onClose,
                                       onSave,
                                       initialData,
                                       title = initialData?.id ? 'serviceGroupDialog.title.edit' : 'serviceGroupDialog.title.create'
                                   }: ServiceGroupDialogProps) {
    const {t} = useTranslation();
    const {token} = useAuth()
    const {toast} = useToast()

    const defaultValues = useMemo(() => ({
        id: initialData?.id || uuid(),
        name: initialData?.name || '',
        description: initialData?.description,
    }), [initialData?.id, initialData?.name, initialData?.description])
    const {
        register,
        handleSubmit,
        setError,
        formState: {errors},
        reset
    } = useForm<ServiceGroupFormValues>({
        resolver: zodResolver(serviceGroupSchema),
        defaultValues
    });

    useEffect(() => {
        if (isOpen) {
            reset(defaultValues);
        }
    }, [isOpen, initialData]);

    function showSuccessToast() {
        toast({
            title: t("serviceGroupDialog.toast.success.title"),
            description: t("serviceGroupDialog.toast.success.description"),
            variant: "default",
        })
    }


    async function onSubmit(data: ServiceGroupFormValues) {
        if (await (saveData(data))) {
            reset();
            onSave();
            showSuccessToast()
            onClose();
        }
    }

    async function saveData(data: ServiceGroupFormValues): Promise<boolean> {
        const serviceGroup: ServiceGroup = {
            id: data.id,
            name: data.name,
            description: data.description,
        }

        try {
            if (!Boolean(initialData?.id)) {
                await createServiceGroup(token, serviceGroup)
            } else {
                await updateServiceGroup(token, serviceGroup)
            }

            return true
        } catch (error: any) {
            console.error("Error persisting service group", error)

            if (!!error.validationIssues) {
                error.validationIssues.map(
                    (finding: ValidationIssue) => {
                        setError(
                            finding.field as keyof Omit<ServiceGroupFormValues, 'id'>,
                            {
                                message: finding.message
                            }
                        )
                    }
                )

            }

            if (!!error.status) {
                setError("root", {
                    message: t("serverError.response-code-with-details", {
                        statusCode: error.status,
                        errorDetails: error.error
                    })
                })
            }

            return false
        }
    }

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle>{t(title)}</DialogTitle>

            <DialogBody>
                <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                    <div className="space-y-2">
                        <Field>
                            <Label><Trans i18nKey="serviceGroupDialog.fields.name.label"/></Label>

                            <Input
                                {...register("name")}
                                type="text"
                                placeholder={t('serviceGroupDialog.fields.name.placeholder')}
                                invalid={!!errors.name}
                            />
                        </Field>
                        {errors.name && (
                            <ValidationAlert message={t(errors.name?.message ||
                                "serviceGroupDialog.errors.unknown-name-issue")}
                            />
                        )}
                    </div>

                    <div className="space-y-2">
                        <Field>
                            <Label htmlFor="description">
                                <Trans i18nKey="serviceGroupDialog.fields.description.label"/>
                            </Label>
                            <Textarea
                                {...register("description")}
                                placeholder={t('serviceGroupDialog.fields.description.placeholder')}
                                invalid={!!errors.description}
                            />
                        </Field>
                        {errors.description && (
                            <ValidationAlert
                                message={t(errors.description?.message ||
                                    "serviceGroupDialog.errors.unknown-description-issue")}
                            />
                        )}
                    </div>

                    <DialogActions>
                        <Button
                            plain
                            onClick={onClose}
                            aria-label={t("serviceGroupDialog.buttons.cancel.ariaLabel")}
                        >
                            <Trans i18nKey="serviceGroupDialog.buttons.cancel.text"/>
                        </Button>
                        <Button
                            type="submit"
                            aria-label={t("serviceGroupDialog.buttons.submit.ariaLabel")}
                        >
                            <Trans i18nKey="serviceGroupDialog.buttons.submit.text"/>
                        </Button>
                    </DialogActions>
                </form>
            </DialogBody>
        </Dialog>
    );
}

export function useServiceGroupDialog() {
    const [isOpen, setIsOpen] = useState(false);
    const [initialData, setInitialData] = useState<Partial<ServiceGroupFormValues | undefined>>(undefined)

    function openDialog(data?: Partial<ServiceGroupFormValues>) {
        setInitialData(data)
        setIsOpen(true)
    }

    function closeDialog() {
        setIsOpen(false)
    }

    return {
        isOpen,
        initialData,
        openServiceGroupDialog: openDialog,
        closeDialog,
        serviceGroupDialogProps: {
            isOpen,
            onClose: closeDialog,
            initialData
        }
    }
}
