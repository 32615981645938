import {useNavigate, useParams} from "react-router-dom";
import {Heading, Subheading} from "@/components/tailwind/heading.tsx";
import {useEffect, useState} from "react";
import {Input} from "@/components/tailwind/input.tsx";
import {Button} from "@/components/tailwind/button.tsx";
import {Text} from "@/components/tailwind/text.tsx";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from 'yup'
import {Divider} from "@/components/tailwind/divider.tsx";
import {Select} from "@/components/tailwind/select.tsx";
import {useAuth} from "@/context/use-auth.tsx";
import {UserBaseDTO, UserDTO} from "@/models/user.ts";
import {editUser, getUserData} from "@/services/user-services.ts";
import ValidationAlert from "../../../components/validation-alert.tsx";
import {ProblemDetail} from "@/types/problem-details.ts";
import {Trans, useTranslation} from "react-i18next";
import DeleteConfirmation from "../../../components/delete-confirmation.tsx";
import ChangePasswordDialog from "@/components/change-password-dialog.tsx";

type UserEditFormInput = {
    id: string,
    role: "ADMIN" | "USER",
    name: string,
    active: boolean,
}

function validation() {
    const {t} = useTranslation()

    return Yup.object().shape(
        {
            id: Yup.string()
                .uuid(t("userForm.errors.invalid-id"))
                .required(t("userForm.errors.missing-id")),
            role: Yup.string()
                .oneOf(["ADMIN", "USER"], t("userForm.errors.invalid-role"))
                .required(t("userForm.errors.missing-role")),
            active: Yup.boolean().default(false),
            name: Yup.string().required(t("userForm.errors.missing-name"))
        }
    )
}

export default function EditUserPage() {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {userId} = useParams()
    const [userData, setUserData] = useState<UserDTO>()
    const {token, user} = useAuth()
    const {
        register,
        handleSubmit,
        setError,
        reset,
        formState: {errors},
        watch
    } = useForm<UserEditFormInput>({resolver: yupResolver(validation())});

    const isActive = watch("active")

    useEffect(() => {
        const userFromServer = getUserData(userId!!, token!!)
        userFromServer
            .then((userData) =>
                setUserData(userData as UserDTO)
            )
    }, []);

    useEffect(() => {
        reset({
            id: userData?.id,
            name: userData?.name,
            active: userData?.active,
            role: userData?.role,
        } as UserEditFormInput)
    }, [userData])

    const onSubmit = async (formData: UserEditFormInput) => {
        try {
            const userData: UserBaseDTO = {
                active: formData.active,
                id: formData.id,
                name: formData.name,
                role: formData.role,
            }

            await editUser(userData, token!!)
            navigate("/admin/users")

        } catch (error) {
            const reason = error as ProblemDetail
            console.warn("Ein Fehler ist beim Bearbeiten des Benutzers aufgetreten", reason)

            if (reason.validationIssues) {
                reason.validationIssues.forEach(finding => {
                    setError(
                        finding.field as "id" | "role" | "name" | "active",
                        {message: finding.message}
                    )
                })
            }

            setError(
                "root",
                {
                    type: `${reason.status}`,
                    message: t("serverError.response-code", {statusCode: reason.status})
                }
            )
        }
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} className="mx-auto max-w-4xl">
                <Heading>{t("userForm.mainHeader.edit")}</Heading>

                <Divider className="my-10 mt-6"/>

                <Input
                    id="id"
                    type="hidden"
                    {...register("id")}
                />

                {(errors.root || errors.id) && (
                    <section aria-label={t("userForm.errors.section.ariaLabel")}>
                        {errors.root &&
                            <ValidationAlert message={t(errors.root.message || "unknown-server-issue")}/>}

                        {errors.id &&
                            <ValidationAlert message={t(errors.id.message || "unknown-id-issue")}/>}
                    </section>
                )}

                <section className="grid gap-x-8 gap-y-6 sm:grid-cols-2">
                    <div className="space-y-1">
                        <Subheading>{t("userForm.fields.name.subheading")}</Subheading>
                        <Text>{t("userForm.fields.name.text")}</Text>
                    </div>
                    <div>
                        <Input aria-label={t("userForm.fields.name.text")}
                               type="text"
                               {...register("name")}
                               placeholder={t("userForm.fields.name.placeholder")}
                               invalid={!!errors.name}
                               autoComplete="name"/>
                        {errors.name &&
                            <ValidationAlert message={t(errors.name.message || 'userForm.errors.unknown-name-issue')}/>}
                    </div>
                </section>

                <Divider className="my-10" soft/>

                <section className="grid gap-x-8 gap-y-6 sm:grid-cols-2">
                    <div className="space-y-1">
                        <Subheading id="usernameHeadline">
                            <Trans i18nKey="userForm.fields.username.subheading"/>
                        </Subheading>
                        <Text id="usernameDescriptions">
                            <Trans i18nKey="userForm.fields.username.text"/>
                        </Text>
                    </div>
                    <div className="flex flex-col justify-center bold">
                        <Text aria-labelledby="usernameHeadline usernameDescriptions">
                            {userData?.username}
                        </Text>
                    </div>
                </section>

                <Divider className="my-10" soft/>

                <section className="grid gap-x-8 gap-y-6 sm:grid-cols-2">
                    <div className="space-y-1">
                        <Subheading>
                            <Trans i18nKey="userForm.fields.role.subheading"/>
                        </Subheading>
                        <Text>
                            <Trans i18nKey="userForm.fields.role.text"/>
                        </Text>
                    </div>
                    <div>
                        <Select aria-label={t("userForm.fields.role.text")}
                                {...register("role")}
                                invalid={!!errors.role}>
                            {["USER", "ADMIN"].map((role) => (
                                <option key={role} value={role}>
                                    {t(`userForm.fields.role.options.${role}`)}
                                </option>
                            ))}
                        </Select>
                        {errors.role &&
                            <ValidationAlert message={t(errors.role.message || 'unknown-role-issue')}/>}
                    </div>
                </section>

                <Divider className="my-10" soft/>

                <section className="grid gap-x-8 gap-y-6 sm:grid-cols-2">
                    <div className="space-y-1">
                        <Subheading id="active-headline">
                            <Trans i18nKey="userForm.fields.active.subheading"/>
                        </Subheading>
                        <Text id="active-description">
                            <Trans i18nKey="userForm.fields.active.text"/>
                        </Text>
                    </div>
                    <div>
                        <div className="flex items-center space-x-2">
                            <input type="checkbox"
                                   {...register("active")}
                                   aria-labelledby="active-headline active-description"
                            />
                            <Text>
                                ({t("userForm.fields.active.checkbox." + (isActive ? "active" : "inactive"))})
                            </Text>
                        </div>
                        {errors.active &&
                            <ValidationAlert message={t(errors.active.message || "unknown-active-issue")}/>}
                    </div>
                </section>

                <Divider className="my-10" soft/>

            </form>

            <div id="password-change-section" className="mx-auto max-w-4xl">
                <section className="grid gap-x-8 gap-y-6 sm:grid-cols-2">
                    <div className="space-y-1">
                        <Subheading id="change-password-headline">
                            <Trans i18nKey="userForm.buttons.changePassword.subheading"/>
                        </Subheading>
                        <Text id="change-password-description">
                            <Trans i18nKey="userForm.buttons.changePassword.description"/>
                        </Text>
                    </div>
                    {userData && (
                        <div className="flex flex-col items-center justify-center">
                            <ChangePasswordDialog effectedUserId={userData.id}/>
                        </div>
                    )}
                </section>
            </div>

            <form onSubmit={handleSubmit(onSubmit)} className="mx-auto max-w-4xl">

                <Divider className="my-10" soft/>

                <section className="grid gap-x-8 gap-y-6 sm:grid-cols-2">
                    <div>
                        {user?.userName !== userData?.username && (
                            <DeleteConfirmation
                                userId={userId!!}
                                name={userData?.name!!}
                                username={userData?.username!!}
                                aria-label={t("userForm.buttons.delete.ariaLabel")}>
                                <Trans i18nKey="userForm.buttons.delete.text"/>
                            </DeleteConfirmation>
                        )}
                    </div>
                    <div className="flex justify-end gap-4">
                        <Button aria-label={t("userForm.buttons.cancel.ariaLabel")}
                                href="/admin/users">
                            <Trans i18nKey="userForm.buttons.cancel.text"/>
                        </Button>
                        <Button aria-label={t("userForm.buttons.submit.ariaLabel")}
                                type="submit">
                            <Trans i18nKey="userForm.buttons.submit.text"/>
                        </Button>
                    </div>
                </section>
            </form>
        </>
    )
}
