import {handleResponse} from "@/services/service-helpers.ts";
import {
    CreateMemberDTO,
    Member,
    MemberDeleteBankingDetailsRequest,
    MemberFormOptions,
    MemberListItem,
    MemberUpdateBankingDetailsRequest,
    MemberUpdateBasicInfoRequest,
    MemberUpdateCommentsRequest,
    MemberUpdateContactDetailsRequest,
    MemberUpdateMembershipRequest
} from "@/models/member.ts";

const NBH_MEMBERS_BASE_URL = `${import.meta.env.VITE_NBH_URL_ROOT}/api/members`
const NBH_MEMBER_OPTIONS_BASE_URL = `${import.meta.env.VITE_NBH_URL_ROOT}/api/options/memberForm`

export async function getMembers(token: string) {
    const response = await fetch(NBH_MEMBERS_BASE_URL, {
        method: 'GET',
        headers: new Headers({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        })
    });

    return handleResponse<MemberListItem[]>(response)

}

// TODO: Use the backend, once we have the data there
export async function getMemberFormOptions(token: string) {
    const response = await fetch(NBH_MEMBER_OPTIONS_BASE_URL, {
        method: 'GET',
        headers: new Headers({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        })
    });

    return handleResponse<MemberFormOptions>(response)
}

export async function createNewMember(token: string, memberData: CreateMemberDTO) {
    const response = await fetch(NBH_MEMBERS_BASE_URL, {
        method: "POST",
        headers:
            new Headers({
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            }),
        body: JSON.stringify(memberData)
    })

    return handleResponse<Member>(response)
}

export async function getMemberData(token: string | null, memberId: string | undefined): Promise<Member | void> {
    if (!Boolean(token)) {
        console.error("No token provided for member data retrieval.")
        return Promise.reject("No token provided")
    }
    if (!Boolean(memberId)) {
        console.error("No memberId provided for member data retrieval.")
        return Promise.reject("No memberId provided.")
    }

    const response = await fetch(`${NBH_MEMBERS_BASE_URL}/${memberId}`, {
        method: 'GET',
        headers: new Headers({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        })
    });

    return handleResponse<Member>(response)
}

export async function deleteMemberData(token: string | null, memberId: string | undefined): Promise<void> {
    if (!Boolean(token)) {
        console.error("No token provided for member data deletion.")
        return Promise.reject("No token provided")
    }
    if (!Boolean(memberId)) {
        console.error("No memberId provided for member data deletion.")
        return Promise.reject("No memberId provided.")
    }

    const response = await fetch(`${NBH_MEMBERS_BASE_URL}/${memberId}`, {
        method: 'DELETE',
        headers: new Headers({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        })
    });

    return handleResponse<void>(response)
}

export type AddPointsDTO = {
    memberId: string,
    points: number,
    description: string
}

export type CurrentPointsDTO = {
    memberId: string,
    points: number,
    statusFrom: string
}

export async function addPointsToMember(token: string | null, pointsData: AddPointsDTO): Promise<CurrentPointsDTO | void> {
    if (!Boolean(token)) {
        console.error("No token provided while trying to add points to member.")
        return Promise.reject("No token provided")
    }

    const response = await fetch(`${NBH_MEMBERS_BASE_URL}/${pointsData.memberId}/points`, {
        method: 'POST',
        headers: new Headers({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }),
        body: JSON.stringify(pointsData)
    });

    return handleResponse<CurrentPointsDTO>(response)
}

export async function updateBasicMemberInformation(
    token: string | null,
    basicInformationRq: MemberUpdateBasicInfoRequest,
): Promise<Member | void> {
    if (!Boolean(token)) {
        console.error("No token provided while trying to update member information.")
        return Promise.reject("No token provided")
    }

    const {memberId, ...basicInformationPayload} = basicInformationRq

    const response = await fetch(`${NBH_MEMBERS_BASE_URL}/${memberId}/basic-details`, {
        method: 'PATCH',
        headers: new Headers({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }),
        body: JSON.stringify(basicInformationPayload)
    });

    return handleResponse<Member>(response)

}

export async function updateMemberComments(
    token: string | null,
    updateCommentsRq: MemberUpdateCommentsRequest,
): Promise<Member | void> {
    if (!Boolean(token)) {
        console.error("No token provided while trying to update member information.")
        return Promise.reject("No token provided")
    }

    const {memberId, ...commentsPayload} = updateCommentsRq

    const response = await fetch(`${NBH_MEMBERS_BASE_URL}/${memberId}/comments`, {
        method: 'PATCH',
        headers: new Headers({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }),
        body: JSON.stringify(commentsPayload)
    });

    return handleResponse<Member>(response)

}

export async function updateMemberContactDetails(
    token: string | null,
    updateContactDetails: MemberUpdateContactDetailsRequest,
): Promise<Member | void> {
    if (!Boolean(token)) {
        console.error("No token provided while trying to update member information.")
        return Promise.reject("No token provided")
    }

    const {memberId, ...commentsPayload} = updateContactDetails

    const response = await fetch(`${NBH_MEMBERS_BASE_URL}/${memberId}/contact-details`, {
        method: 'PATCH',
        headers: new Headers({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }),
        body: JSON.stringify(commentsPayload)
    });

    return handleResponse<Member>(response)

}

export async function updateMemberMembership(
    token: string | null,
    updateMembershipDetails: MemberUpdateMembershipRequest,
): Promise<Member | void> {
    if (!Boolean(token)) {
        console.error("No token provided while trying to update member information.")
        return Promise.reject("No token provided")
    }

    const {memberId, ...membershipPayload} = updateMembershipDetails

    const response = await fetch(`${NBH_MEMBERS_BASE_URL}/${memberId}/membership`, {
        method: 'PATCH',
        headers: new Headers({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }),
        body: JSON.stringify(membershipPayload)
    });

    return handleResponse<Member>(response)

}

export async function updateBankingDetails(
    token: string | null,
    updateBankingDetails: MemberUpdateBankingDetailsRequest
) {
    if (!Boolean(token)) {
        console.error("No token provided while trying to update member information.")
        return Promise.reject("No token provided")
    }

    const {memberId, ...bankingDetailsPayload} = updateBankingDetails

    const response = await fetch(`${NBH_MEMBERS_BASE_URL}/${memberId}/banking-details`, {
        method: 'PATCH',
        headers: new Headers({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }),
        body: JSON.stringify(bankingDetailsPayload)
    });

    return handleResponse<Member>(response)
}

export async function deleteBankingDetails(
    token: string | null,
    bankingDetails: MemberDeleteBankingDetailsRequest
) {
    if (!Boolean(token)) {
        console.error("No token provided while trying to update member information.")
        return Promise.reject("No token provided")
    }

    const {memberId, bankAccountId} = bankingDetails

    const response = await fetch(`${NBH_MEMBERS_BASE_URL}/${memberId}/banking-details/${bankAccountId}`, {
        method: 'DELETE',
        headers: new Headers({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }),
    });

    return handleResponse<Member>(response)
}

export function generateCompleteMemberName(memberData: Member): string {
    return (
        [
            memberData.salutation?.value,
            memberData.title,
            memberData.firstName,
            memberData.lastName
        ]
            .filter(Boolean)
            .join(' ')
    )
}
