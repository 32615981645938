import {useEffect, useMemo, useState} from 'react';
import {Dialog, DialogActions, DialogBody, DialogTitle} from '@/components/tailwind/dialog';
import {Button} from '@/components/tailwind/button';
import {Input} from '@/components/tailwind/input';
import {Description, Field, Fieldset, Label, Legend} from "@/components/tailwind/fieldset.tsx";
import {z} from 'zod';
import {v4 as uuid} from 'uuid';
import {Trans, useTranslation} from 'react-i18next';
import ValidationAlert from "@/components/validation-alert.tsx";
import {Controller, useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {createService, updateService} from "@/services/service-services.ts";
import {useAuth} from "@/context/use-auth.tsx";
import {ServiceDTO, ServiceGroup} from "@/models/service.ts";
import {Textarea} from "@/components/tailwind/textarea.tsx";
import {ValidationIssue} from "@/types/problem-details.ts";
import {Checkbox, CheckboxField, CheckboxGroup} from "@/components/tailwind/checkbox.tsx";
import {Text} from "@/components/tailwind/text.tsx";
import * as Headless from '@headlessui/react'
import {useToast} from "@/hooks/use-toast.ts";

const serviceSchema = z.object({
    name: z.string()
        .min(1, {message: "serviceDialog.errors.name-required"}),
    description: z.string()
        .transform(val => (!val ? undefined : val))
        .optional(),
    pointsPerUnit: z.number({message: "serviceDialog.errors.pointsPerUnit-required"})
        .min(1, "serviceDialog.errors.pointsPerUnit-required"),
    isFixedPoints: z.boolean()
        .default(false),
    isSpecialAssignment: z.boolean()
        .default(false),
});

export type ServiceFormValues = z.infer<typeof serviceSchema>;


type ServiceDialogProps = {
    isOpen: boolean;
    onClose: () => void;
    onSave: () => void;
    serviceGroup: ServiceGroup | undefined,
    serviceId?: string | undefined;
    title?: string;
}

export function ServiceDialog({
                                  isOpen,
                                  onClose,
                                  onSave,
                                  serviceGroup,
                                  serviceId,
                                  title = serviceId ? 'serviceDialog.title.edit' : 'serviceDialog.title.create'
                              }: ServiceDialogProps) {
    const {t} = useTranslation();
    const {token} = useAuth()
    const {toast} = useToast()

    const defaultValues = useMemo(() => ({
        pointsPerUnit: 1,
    }), [])

    const {
        control,
        register,
        handleSubmit,
        setError,
        formState: {errors},
        reset,
    } = useForm<ServiceFormValues>({
        resolver: zodResolver(serviceSchema),
        defaultValues
    });

    useEffect(() => {
        if (isOpen) {
            reset();
        }
    }, [isOpen, reset]);


    function showSuccessToast() {
        toast({
            title: t("serviceDialog.toast.success.title"),
            description: t("serviceDialog.toast.success.description"),
            variant: "default",
        })
    }

    async function onSubmit(data: ServiceFormValues) {
        if (await (saveData(data))) {
            reset();
            onSave();
            showSuccessToast();
            onClose();
        }
    }

    async function saveData(data: ServiceFormValues): Promise<boolean> {
        const serviceDto: ServiceDTO = {
            id: serviceId || uuid(),
            serviceGroupId: serviceGroup?.id,
            name: data.name,
            description: data.description,
            pointsPerUnit: data.pointsPerUnit,
            isFixedPoints: data.isFixedPoints,
            isSpecialAssignment: data.isSpecialAssignment,
        }

        try {
            if (!Boolean(serviceId)) {
                await createService(token, serviceDto)
            } else {
                await updateService(token, serviceDto)
            }

            return true
        } catch (error: any) {
            console.error("Error persisting service group", error)

            if (!!error.validationIssues) {
                error.validationIssues.map(
                    (finding: ValidationIssue) => {
                        setError(
                            finding.field as keyof Omit<ServiceFormValues, 'id'>,
                            {
                                message: finding.message
                            }
                        )
                    }
                )

            }

            if (!!error.status) {
                setError("root", {
                    message: t("serverError.response-code-with-details", {
                        statusCode: error.status,
                        errorDetails: error.error
                    })
                })
            }

            return false
        }
    }

    function getDialogTitle(serviceGroup: ServiceGroup | undefined): string {
        if (serviceGroup === undefined) {
            return t("serviceDialog.serviceWithoutGroupTitleMarker")
        } else {
            return t("serviceDialog.serviceGroupPrefix") + ": " + serviceGroup?.name
        }
    }

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle>{t(title)} ({getDialogTitle(serviceGroup)})</DialogTitle>

            <DialogBody>
                {errors.root && (
                    <ValidationAlert message={errors.root?.message ||
                        t("serverError.unknown")}/>
                )}

                <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                    <div className="space-y-2">
                        <Field>
                            <Label><Trans i18nKey="serviceDialog.fields.name.label"/></Label>

                            <Input
                                {...register("name")}
                                type="text"
                                placeholder={t('serviceDialog.fields.name.placeholder')}
                                invalid={!!errors.name}
                            />
                        </Field>
                        {errors.name && (
                            <ValidationAlert message={t(errors.name?.message ||
                                "serviceDialog.errors.unknown-name-issue")}
                            />
                        )}
                    </div>

                    <div className="space-y-2">
                        <Field>
                            <Label>
                                <Trans i18nKey="serviceDialog.fields.description.label"/>
                            </Label>
                            <Textarea
                                {...register("description")}
                                placeholder={t('serviceDialog.fields.description.placeholder')}
                                invalid={!!errors.description}
                            />
                        </Field>
                        {errors.description && (
                            <ValidationAlert
                                message={t(errors.description?.message ||
                                    "serviceDialog.errors.unknown-description-issue")}
                            />
                        )}
                    </div>

                    <div className="space-y-2">
                        <Headless.Field className="flex items-center gap-6">
                            <Label className="w-[120px] flex-shrink-0">
                                <Trans i18nKey="serviceDialog.fields.pointsPerUnit.label"/>
                            </Label>
                            <Input
                                type="number"
                                min="1"
                                {...register("pointsPerUnit", {valueAsNumber: true})}
                                placeholder={t('serviceDialog.fields.pointsPerUnit.placeholder')}
                                invalid={!!errors.pointsPerUnit}
                                className="w-full"
                            />
                        </Headless.Field>
                        {errors.pointsPerUnit && (
                            <ValidationAlert
                                message={t(errors.pointsPerUnit?.message ||
                                    "serviceDialog.errors.unknown-pointsPerUnit-issue")}
                            />
                        )}
                    </div>

                    <Fieldset>
                        <Legend><Trans i18nKey="serviceDialog.flags.legend"/></Legend>
                        <Text><Trans i18nKey="serviceDialog.flags.text"/></Text>
                        <CheckboxGroup>
                            <CheckboxField>
                                <Controller
                                    name="isFixedPoints"
                                    control={control}
                                    render={({field}) => {
                                        const value = field.value === undefined ? false : !!field.value
                                        return (
                                            <Checkbox
                                                checked={value}
                                                onChange={(checked) => field.onChange(checked)}
                                            />
                                        )
                                    }}
                                />
                                <Label>
                                    <Trans i18nKey="serviceDialog.fields.isFixedPoints.label"/>
                                </Label>
                                <Description>
                                    <Trans i18nKey="serviceDialog.fields.isFixedPoints.description"/>
                                    {errors.isFixedPoints && (
                                        <ValidationAlert
                                            message={t(errors.isFixedPoints?.message ||
                                                "serviceDialog.errors.unknown-isFixedPoints-issue")}
                                        />
                                    )}
                                </Description>
                            </CheckboxField>

                            <CheckboxField>
                                <Controller
                                    name="isSpecialAssignment"
                                    control={control}
                                    render={({field}) => {
                                        const value = field.value === undefined ? false : !!field.value
                                        return (
                                            <Checkbox
                                                checked={value}
                                                onChange={(checked) => field.onChange(checked)}
                                            />
                                        )
                                    }}
                                />

                                <Label>
                                    <Trans i18nKey="serviceDialog.fields.isSpecialAssignment.label"/>
                                </Label>

                                <Description>
                                    <Trans i18nKey="serviceDialog.fields.isSpecialAssignment.description"/>
                                    {errors.isSpecialAssignment && (
                                        <ValidationAlert
                                            message={t(errors.isSpecialAssignment?.message ||
                                                "serviceDialog.errors.unknown-isSpecialAssignment-issue")}
                                        />
                                    )}
                                </Description>
                            </CheckboxField>

                        </CheckboxGroup>
                    </Fieldset>

                    <DialogActions>
                        <Button
                            plain
                            onClick={onClose}
                            aria-label={t("serviceDialog.buttons.cancel.ariaLabel")}
                        >
                            <Trans i18nKey="serviceDialog.buttons.cancel.text"/>
                        </Button>
                        <Button
                            type="submit"
                            aria-label={t("serviceDialog.buttons.submit.ariaLabel")}
                        >
                            <Trans i18nKey="serviceDialog.buttons.submit.text"/>
                        </Button>
                    </DialogActions>
                </form>
            </DialogBody>
        </Dialog>
    );
}

export function useServiceDialog() {
    const [isOpen, setIsOpen] = useState(false);
    const [serviceGroup, setServiceGroup] = useState<ServiceGroup | undefined>(undefined)
    const [serviceId, setServiceId] = useState<string | undefined>(undefined)

    function openDialog(serviceGroup?: ServiceGroup | undefined, serviceId?: string | undefined) {
        setServiceGroup(serviceGroup)
        setServiceId(serviceId)
        setIsOpen(true)
    }

    function closeDialog() {
        setIsOpen(false)
    }

    return {
        isServiceDialogOpen: isOpen,
        initialServiceGroupDialogData: serviceGroup,
        openServiceDialog: openDialog,
        closeServiceDialog: closeDialog,
        serviceGroup,
        serviceId,
        serviceDialogProps: {
            isOpen: isOpen,
            onClose: closeDialog,
            serviceGroup: serviceGroup,
            serviceId: serviceId
        }
    }
}
