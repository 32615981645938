import {useForm} from 'react-hook-form';
import {z} from 'zod';
import {zodResolver} from '@hookform/resolvers/zod';
import {Trans, useTranslation} from 'react-i18next';
import {Dialog, DialogActions, DialogBody, DialogTitle} from '@/components/tailwind/dialog';
import {Button} from '@/components/tailwind/button';
import {Member, MemberUpdateCommentsRequest} from "@/models/member.ts";
import ValidationAlert from "@/components/validation-alert.tsx";
import {Field, Label} from "@/components/tailwind/fieldset.tsx";
import {updateMemberComments} from "@/services/member-services.ts";
import {useAuth} from "@/context/use-auth.tsx";
import {useEffect, useMemo} from "react";
import {Textarea} from "@/components/tailwind/textarea.tsx";

interface MemberEditCommentsDialogProps {
    isOpen: boolean;
    onSave: (data: Member) => void;
    onClose: () => void;
    memberData: Member;
}

const memberEditCommentsSchema = z.object({
    generalComments: z.string()
        .trim()
        .nullable()
        .transform(val => (!val ? undefined : val))
        .optional(),
    availabilityComments: z.string()
        .trim()
        .nullable()
        .transform(val => (!val ? undefined : val))
        .optional()
});

export type MemberEditCommentsFormData = z.infer<typeof memberEditCommentsSchema>;

export default function MemberEditCommentsDialog({
                                                  isOpen,
                                                  onSave,
                                                  onClose,
                                                  memberData,
                                              }: MemberEditCommentsDialogProps) {
    const {t} = useTranslation();
    const defaultValues = useMemo(() => ({
        generalComments: memberData.generalComments,
        availabilityComments: memberData.availabilityComments,
    }), [memberData.generalComments, memberData.availabilityComments]);

    const {
        register,
        handleSubmit,
        setError,
        formState: {errors},
        reset
    } = useForm<MemberEditCommentsFormData>({
        resolver: zodResolver(memberEditCommentsSchema),
        defaultValues
    });

    useEffect(() => {
        if (isOpen && memberData) {
            reset(defaultValues);
        }
    }, [isOpen, memberData, reset]);

    const {token} = useAuth();

    async function saveData(data: MemberEditCommentsFormData): Promise<boolean> {

        const commentData: MemberUpdateCommentsRequest = {
            memberId: memberData.id,
            generalComments: data.generalComments,
            availabilityComments: data.availabilityComments,
        }

        try {
            const response = await updateMemberComments(token, commentData);
            if (response) {
                onSave(response)
            }
            return true
        } catch (error: any) {
            console.error("Error updating member: ", error);
            if (Boolean(error.status)) {
                setError("root", {
                    message: t("serverError.response-code-with-details", {
                        statusCode: error.status,
                        errorDetails: error.error
                    })
                });
            } else {
                setError("root", {message: t("serverError.unknown-with-details", {errorDetails: error.message})});
            }

            return false;
        }

    }

    async function onSubmit(data: MemberEditCommentsFormData) {
        if (await saveData(data)) {
            handleClosing();
        }
    }

    function handleClosing() {
        reset();
        onClose();
    }

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle>
                <Trans i18nKey="memberEditDialog.comments.title"/>
            </DialogTitle>

            <DialogBody>
                {errors.root && (
                    <ValidationAlert message={errors.root?.message ||
                        t("serverError.unknown")}/>
                )}
                <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
                    <div>
                        <Field>
                            <Label><Trans i18nKey="memberEditDialog.comments.fields.general.label"/></Label>
                            <Textarea
                                {...register("generalComments")}
                                aria-label={t("memberEditDialog.comments.fields.general.label")}
                                aria-invalid={!!errors.generalComments}
                                placeholder={t("memberEditDialog.comments.fields.general.placeholder")}
                            />

                        </Field>
                        {errors.generalComments && (
                            <ValidationAlert
                                message={t(errors.generalComments?.message ||
                                    "memberEditDialog.comments.errors.unknown-generalComments-issue")}
                            />
                        )}
                    </div>

                    <div>
                        <Field>
                            <Label><Trans i18nKey="memberEditDialog.comments.fields.availability.label"/></Label>
                            <Textarea
                                {...register("availabilityComments")}
                                aria-label={t("memberEditDialog.comments.fields.availability.label")}
                                aria-invalid={!!errors.availabilityComments}
                                placeholder={t("memberEditDialog.comments.fields.availability.placeholder")}
                            />

                        </Field>
                        {errors.availabilityComments && (
                            <ValidationAlert
                                message={t(errors.availabilityComments?.message ||
                                    "memberEditDialog.comments.errors.unknown-availability-issue")}
                            />
                        )}
                    </div>

                    <DialogActions>
                        <Button
                            type="button"
                            plain
                            onClick={handleClosing}
                            aria-label={t("memberEditDialog.comments.buttons.cancel.ariaLabel")}
                        >
                            <Trans i18nKey="memberEditDialog.comments.buttons.cancel.text"/>
                        </Button>
                        <Button
                            type="submit"
                            aria-label={t("memberEditDialog.comments.buttons.save.ariaLabel")}
                        >
                            <Trans i18nKey="memberEditDialog.comments.buttons.save.text"/>
                        </Button>
                    </DialogActions>
                </form>
            </DialogBody>
        </Dialog>
    );
}
