import i18n from 'i18next'
import {initReactI18next} from "react-i18next";

i18n
    .use(initReactI18next)
    .init({
        debug: false,
        fallbackLng: "de",
        interpolation: {
            escapeValue: false // react escapes by default
        },
        resources: {
            de: {
                translation: {
                    "error": {
                        404: {
                            "headline": "Seite nicht gefunden",
                            "text": "Die angefragte Seite existiert nicht.",
                            "backLink": "Hier geht zurück zur Startseite",
                        },
                    },
                    "table": {
                        "no-data": "Keine Einträge",
                        "entriesPerPage": "Einträge pro Seite",
                        "pageInfo": "Einträge {{from}} bis {{to}} von {{total}}",
                        "previousPage": "Vorherige Seite",
                        "nextPage": "Nächste Seite",
                    },
                    "menu": {
                        "navbar": {
                            "home": {
                                "label": "Start"
                            },
                            "adminSection": {
                                "ariaLabel": "Administrationsbereich"
                            },
                            "membersSection": {
                                "ariaLabel": "Mitgliederverwaltung"
                            },
                            "orders": {
                                "ariaLabel": "Aufträge verwalten",
                                "label": "Aufträge"
                            },
                            "users": {
                                "label": "Benutzer verwalten"
                            },
                            "services": {
                                "label": "Service verwalten"
                            },
                            "members": {
                                "label": "Mitglieder"
                            }
                        },
                        "sidebar": {
                            "admin": {
                                "heading": "Administration",
                                "users": {
                                    "label": "Benutzer verwalten"
                                },
                                "services": {
                                    "label": "Services verwalten"
                                },
                            },
                            "members": {
                                "heading": "Mitgliederverwaltung",
                                "overview": {
                                    "label": "Mitgliederübersicht"
                                }
                            },
                            "orders": {
                                "heading": "Auftragsverwaltung",
                                "overview": {
                                    "label": "Auftragsübersicht"
                                }
                            }
                        },
                        "login": {
                            "ariaLabel": "Führt zum Anmeldebildschirm",
                            "text": "Anmelden"
                        },
                        "myAccount": {
                            "account": {
                                "dropdownLabel": "Mein Nutzerkonto",
                                "ariaLabel": "Link zur Verwaltung meines Nutzerkontos"
                            },
                            "changePassword": {
                                "dropdownLabel": "Passwort ändern",
                                "ariaLabel": "Mein Passwort ändern"
                            },
                            "logout": {
                                "dropdownLabel": "Abmelden",
                                "ariaLabel": "Aktuelle Nutzersession beenden"
                            },
                        },
                    },
                    "login": {
                        "headline": "Anmelden",
                        "form": {
                            "username": {
                                "subheading": "Nutzername",
                                "text": "Geben Sie hier den Ihnen bekannten Nutzernamen ein",
                                "ariaLabel": "Ihr Nutzername"
                            },
                            "password": {
                                "subheading": "Passwort",
                                "text": "Geben Sie hier ihr Passwort ein",
                                "ariaLabel": "Ihr Passwort"
                            },
                            "submit": {
                                "label": "Absenden",
                                "ariaLabel": "Anmeldeformular absenden"
                            }
                        },
                        "errors": {
                            "username-empty": "Nutzername darf nicht leer sein.",
                            "password-empty": "Passwort darf nicht leer sein.",
                            "login-failed": "Nutzername oder Passwort sind nicht korrekt.",
                            "unknown-username-issue": "Bitte prüfen Sie Ihre Eingabe für den Nutzernamen.",
                            "unknown-password-issue": "Bitte prüfen Sie Ihre Eingabe für das Password.",
                            "unknown-server-issue": "Login auf Serverseite fehlgeschlagen. Bitte später noch einmal probieren."
                        }
                    },
                    "userList": {
                        "mainHeader": "Benutzerübersicht",
                        "table": {
                            "headers": {
                                "name": "Name",
                                "username": "Nutzername",
                                "role": "Rolle",
                                "active": "Aktiv"
                            },
                            "roleColumn": {
                                "USER": "Nutzer",
                                "ADMIN": "Administrator"
                            },
                            "activeColumn": {
                                "true": "Ja",
                                "false": "Nein"
                            }
                        },
                        "newUserButton": {
                            "ariaLabel": "Neuen Nutzer hinzufügen",
                            "title": "Neuer Nutzer"
                        }
                    },
                    "userForm": {
                        "mainHeader": {
                            "new": "Nutzer anlegen",
                            "edit": "Nutzer editieren"
                        },
                        "fields": {
                            "name": {
                                "subheading": "Name",
                                "text": "Name der nutzenden Person",
                                "placeholder": "Alex Muster",

                            },
                            "username": {
                                "subheading": "Nutzername",
                                "text": "Name, mit dem sich die Person anmeldet",
                                "placeholder": "alex.muster"
                            },
                            "password": {
                                "subheading": "Passwort",
                                "text": "Passwort, mit dem sich die Person authentifiziert.",
                                "additionalText": "Mindestens {{ minLength }} Zeichen",
                                "placeholder": "*******"
                            },
                            "confirmPassword": {
                                "subheading": "Passwort wiederholen",
                                "text": "Bitte Passwort hier noch einmal wiederholen.",
                                "additionalText": "Mindestens {{ minLength }} Zeichen",
                                "placeholder": "*******"
                            },
                            "role": {
                                "subheading": "Benutzerrolle",
                                "text": "\"Nutzer\" dürfen das System normal nutzen. \"Administratoren\" dürfen das System auch anpassen",
                                "options": {
                                    "USER": "Nutzer",
                                    "ADMIN": "Administrator"
                                }
                            },
                            "active": {
                                "subheading": "Nutzer aktiv / inaktiv setzen",
                                "text": "Aktive Nutzer dürfen sich anmelden und das System entsprechend ihrer Rolle benutzen.",
                                "options": {
                                    "USER": "Nutzer",
                                    "ADMIN": "Administrator"
                                },
                                "checkbox": {
                                    "active": "Nutzer ist aktiv",
                                    "inactive": "Nutzer ist inaktiv"
                                }
                            },
                        },
                        "errors": {
                            "invalid-id": "Ungültige ID",
                            "invalid-role": "Unbekannte Rolle ausgewählt.",
                            "invalid-username": "Nutzernamen dürfen nur mit einem Buchstaben oder einer Ziffer beginnen.",
                            "username-already-exists": "Der Nutzername wird bereits verwendet.",
                            "password-min-length": "Das Password muss mindestens {{minLength}} Zeichen enthalten.",
                            "password-mismatch": "Passwörter müssen übereinstimmen.",
                            "missing-id": 'Fehlende ID. Bitte wenden Sie sich an den Administrator.',
                            "missing-confirm-password": "Bitte Passwort wiederholen.",
                            "missing-name": "Bitte geben Sie den Namen der benutzenden Person an.",
                            "missing-password": "Es muss ein Passwort verwendet werden.",
                            "missing-role": "Bitte wählen Sie eine Rolle aus.",
                            "missing-username": 'Nutzername darf nicht leer sein',
                            "unknown-id-issue": "Es ist ein unbekannter Fehler mit der Nutzer-ID aufgetreten.",
                            "unknown-name-issue": "Bitte Namen überprüfen.",
                            "unknown-username-issue": "Bitte Nutzernamen überprüfen.",
                            "unknown-password-issue": "Bitte Passwort überprüfen.",
                            "unknown-role-issue": "Bitte Rolle überprüfen.",
                            "unknown-active-issue": "Bitte Eingabe, ob Nutzer aktiv ist überprüfen.",
                            "unknown-server-issue": "Es ist ein unbekannter Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.",

                            "section": {
                                "ariaLabel": "Auflistung von Fehlern, die nicht direkt zu Feldern zuordenbar sind"
                            }
                        },
                        "buttons": {
                            "submit": {
                                "ariaLabel": "Formular absenden und Daten speichern",
                                "text": "Speichern"
                            },
                            "cancel": {
                                "ariaLabel": "Abbrechen und zurück zur Übersicht",
                                "text": "Zurück"
                            },
                            "delete": {
                                "ariaLabel": "Nutzer löschen",
                                "text": "Löschen"
                            },
                            "changePassword": {
                                "subheading": "Passwort ändern",
                                "description": "Passwort für Nutzer ändern",
                                "text": "Passwort ändern",
                            }
                        },
                        "dialog": {
                            "delete": {
                                "title": "Nutzer löschen",
                                "description": "Soll der Nutzer {{name}} mit dem Nutzernamen {{username}} wirklich gelöscht werden?"
                            },
                            "toast": {
                                "success": {
                                    "title": "Nutzer gelöscht",
                                    "description": "Der Nutzer wurde erfolgreich gelöscht."
                                },
                                "error": {
                                    "title": "Nutzer löschen fehlgeschlagen",
                                    "description": "Der Nutzer konnte nicht gelöscht werden."
                                },
                            }
                        }

                    },
                    "changePasswordDialog": {
                        "fields": {
                            "currentPassword": {
                                "heading": "Aktuelles Passwort",
                                "placeholder": "Ihr aktuelles Passwort",
                                "ariaLabel": "Ihr aktuelles Passwort",
                                "description": "Bitte geben Sie Ihr aktuelles Passwort ein."
                            },
                            "newPassword": {
                                "heading": "Neues Passwort",
                                "placeholder": "Neues Passwort",
                                "ariaLabel": "Das neues Passwort",
                                "description": "Bitte geben Sie das neue Passwort ein."
                            },
                            "confirmPassword": {
                                "heading": "Neues Passwort bestätigen",
                                "placeholder": "Neues Passwort bestätigen",
                                "ariaLabel": "Das neue Passwort bestätigen",
                                "description": "Bitte bestätigen Sie das neue Passwort."
                            },
                        },
                        "buttons": {
                            "openDialog": {
                                "ariaLabel": "Passwort ändern Dialog öffnen",
                                "text": "Passwort ändern",
                            },
                            "submit": {
                                "ariaLabel": "Passwort ändern",
                                "text": "Passwort ändern",
                            },
                            "cancel": {
                                "ariaLabel": "Abbrechen und Dialog schießen",
                                "text": "Abbrechen",
                            },
                        },
                        "toast": {
                            "success": {
                                "title": "Passwort geändert",
                                "description": "Das Passwort wurde erfolgreich geändert."
                            },
                            "unauthorized": {
                                "title": "Nicht erlaubt",
                                "description": "Sie können dieses Passwort nicht ändern."
                            }
                        },
                        "errors": {
                            "password-min-length": "Das Passwort muss mindestens {{minLength}} Zeichen lang sein.",
                            "missing-current-password": "Bitte geben Sie Ihr aktuelles Passwort ein",
                            "missing-new-password": "Bitte geben Sie das neue Passwort ein",
                            "missing-confirm-password": "Bitte bestätigen Sie das neue Passwort",
                            "password-mismatch": "Die neuen Passwörter stimmen nicht überein",
                            "invalid-current-password": "Das aktuelle Passwort ist nicht korrekt",
                            "invalid-id": "Unbekannte, interne Nutzer-ID. Bitte wenden Sie sich an den Administrator.",
                            "unknown-current-password-issue": "Bitte aktuelles Passwort überprüfen",
                            "unknown-new-password-issue": "Bitte neues Passwort überprüfen",
                            "unknown-confirm-password-issue": "Bitte Passwortbestätigung überprüfen",
                        }
                    },
                    "memberList": {
                        "mainHeader": "Mitgliederverwaltung",
                        "companyPrefix": "Firma",
                        "table": {
                            "headers": {
                                "memberNo": "Mitglieds-Nr.",
                                "name": "Name",
                                "phoneNumbers": "Rufnummern",
                                "address": "Adresse",
                                "status": "Aktiv / Passiv"
                            },
                            "statusColumn": {
                                "AKTIV": "Aktives Mitglied",
                                "PASSIV": "Passives Mitglied"
                            },
                        },
                        "newMemberButton": {
                            "ariaLabel": "Neuen Nutzer hinzufügen",
                            "title": "Neuer Nutzer"
                        }
                    },
                    "memberForm": {
                        "loadingMessage": "Bitte warten, verfügbare Optionen werden geladen...",
                        "mainHeader": {
                            "new": "Neues Mitglied hinzufügen",
                        },
                        "fields": {
                            "memberNo": {
                                "label": "Mitgliedsnr.",
                                "info": "Wird automatisch vergeben",
                            },
                            "firstName": {
                                "label": "Vorname",
                                "placeholder": "Alex",
                            },
                            "lastName": {
                                "label": "Nachname",
                                "placeholder": "Schulze",
                            },
                            "companyName": {
                                "label": "Firma",
                                "placeholder": "",
                            },
                            "salutation": {
                                "label": "Anrede",
                            },
                            "title": {
                                "label": "Titel",
                                "placeholder": "",
                            },
                            "email": {
                                "label": "E-Mail",
                                "placeholder": "alex.schulze@example.local"
                            },
                            "dateOfBirth": {
                                "label": "Geburtsdatum",
                            },
                            "address": {
                                "street": {
                                    "label": "Straße",
                                    "placeholder": "Beispielallee"
                                },
                                "houseNo": {
                                    "label": "Hausnr.",
                                    "placeholder": "42"
                                },
                                "postcode": {
                                    "label": "PLZ",
                                    "placeholder": "61137"
                                },
                                "city": {
                                    "label": "Ort",
                                    "placeholder": "Schöneck"
                                },
                            },
                            "membershipStart": {
                                "label": "Start der Mitgliedschaft",
                                "placeholder": ""
                            },
                            "membershipStatus": {
                                "label": "Art der Mitgliedschaft",
                            },
                            "phone": {
                                "landline": {
                                    "label": "Festnetz (Privat)",
                                    "placeholder": ""
                                },
                                "business": {
                                    "label": "Festnetz (Geschäftlich)",
                                    "placeholder": ""
                                },
                                "mobile": {
                                    "label": "Mobil",
                                    "placeholder": ""
                                },
                                "fax": {
                                    "label": "Fax",
                                    "placeholder": ""
                                },
                            },
                            "banking": {
                                "accountOwner": {
                                    "label": "Kontoinhaber (falls abweichend)",
                                    "placeholder": "",
                                    "ariaLabel": "Name des Kontoinhabers, falls abweichend vom Mitgliedsnamen"
                                },
                                "iban": {
                                    "label": "IBAN",
                                    "placeholder": "",
                                    "ariaLabel": "IBAN des Kontos des Mitglieds"
                                },
                                "bic": {
                                    "label": "BIC",
                                    "placeholder": "",
                                    "ariaLabel": "BIC der Bank des Mitglieds"
                                },
                                "bankName": {
                                    "label": "Name der Bank",
                                    "placeholder": "",
                                    "ariaLabel": "Name der Bank des Mitglieds"
                                },
                                "mandateDate": {
                                    "label": "Mandatsdatum",
                                    "placeholder": "",
                                    "ariaLabel": "Datum des Mandats"
                                }


                            },
                            "comments": {
                                "general": {
                                    "label": "Generelle Bemerkungen",
                                    "placeholder": ""
                                },
                                "availability": {
                                    "label": "Vermerke zu Einsatzzeiten bei aktiven Mitgliedern",
                                    "placeholder": ""
                                },
                            },

                        },
                        "buttons": {
                            "cancel": {
                                "ariaLabel": "Eingaben verwerfen und zurück zur Übersicht",
                                "title": "Zurück",
                            },
                            "submit": {
                                "ariaLabel": "Eingegebene Daten speichern",
                                "title": "Speichern",
                            }
                        },
                        "sections": {
                            "personalInfo": {
                                "heading": "Persönliche Informationen",
                                "text": "Die Basisinformationen des Mitglieds"
                            },
                            "addresses": {
                                "heading": "Adresse",
                                "text": "Anschrift des Mitglieds"
                            },
                            "membership": {
                                "heading": "Mitgliedschaft",
                                "text": "Informationen zur Mitgliedschaft"
                            },
                            "contact": {
                                "heading": "Kontaktinformationen",
                                "text": "Informationen, wie wir das Mitglied erreichen können."
                            },
                            "comments": {
                                "heading": "Bemerkungen",
                                "text": "Generelle Vermerke und Bemerkungen"
                            },
                            "banking": {
                                "heading": "Bankverbindung",
                                "text": "Informationen zur Bankverbindung des Mitglieds"
                            }
                        },
                        "errors": {
                            "unknown-firstName-issue": "Bitte Vornamen überprüfen",
                            "unknown-lastName-issue": "Bitte Nachnamen überprüfen",
                            "unknown-company-name-issue": "Bitte Firmennamen überprüfen",
                            "unknown-birthday-issue": "Bitte Geburtsdatum prüfen",
                            "unknown-address-details-error": "Bitte Adressdaten prüfen",
                            "unknown-address-street-error": "Bitte Straße prüfen",
                            "unknown-address-house-no-error": "Bitte Hausnummer prüfen",
                            "unknown-address-postcode-error": "Bitte Postleitzahl prüfen",
                            "unknown-address-city-error": "Bitte Ort prüfen",
                            "unknown-email-error": "Bitte E-Mail-Adresse prüfen",
                            "unknown-phone-landline-error": "Bitte Festnetz (privat) prüfen",
                            "unknown-phone-business-error": "Bitte Festnetz (geschäftlich) prüfen",
                            "unknown-phone-mobile-error": "Bitte Mobilfunknummer prüfen",
                            "unknown-phone-fax-error": "Bitte Faxnummer prüfen",
                            "unknown-membership-start-error": "Bitte Mitglieds-Startdatum prüfen",
                            "unknown-membership-status-error": "Bitte Mitgliedsstatus prüfen",
                            "unknown-banking-account-owner-error": "Bitte Kontoinhaber prüfen",
                            "unknown-banking-iban-error": "Bitte IBAN prüfen",
                            "unknown-banking-bic-error": "Bitte BIC prüfen",
                            "unknown-banking-bank-name-error": "Bitte Namen der Bank prüfen",
                            "address-street-required": "Bitte geben Sie einen Straßennamen an.",
                            "address-house-no-required": "Bitte geben Sie eine Hausnummer an.",
                            "address-postcode-required": "Bitte geben Sie eine korrekte Postleitzahl an.",
                            "address-city-required": "Bitte geben Sie einen Ort an.",
                            "address-postcode-invalid-format": "Bitte eine gültige deutsche Postleitzahl angeben",
                            "address-required": "Bitten geben Sie eine Adresse ein",
                            "address-incomplete": "Die Adressdaten sind unvollständig",
                            "invalid-landline": "Ungültige Festnetznummer (privat)",
                            "invalid-business": "Ungültige Festnetznummer (geschäftlich)",
                            "invalid-mobile": "Ungültige Mobilfunknummer",
                            "invalid-fax": "Ungültige Faxnummer",
                            "invalid-id": "Ungültige Entitäten-ID",
                            "invalid-email-address": "Ungültige E-Mail-Adresse",
                            "invalid-membership-status": "Ungültiger Mitgliedsstatus",
                            "invalid-phone-numbers": "Bitte Telefonnummern überprüfen",
                            "invalid-birthday-date-format": "Bitte das Geburtsdatum prüfen",
                            "invalid-membership-start-date-format": "Bitte das Startdatum der Mitgliedschaft prüfen",
                            "missing-id": "Entitäten-ID fehlt",
                            "first-name-required": "Bitte einen Vornamen angeben",
                            "last-name-required": "Bitte einen Nachname angeben",
                            "birthday-required": "Bitte ein Geburtsdatum angeben",
                            "membership-status-required": "Bitte einen Mitgliedsstatus auswählen",
                            "membership-start-required": "Bitte ein Startdatum der Mitgliedschaft angeben",
                            "invalid-salutation": "Bitte Anrede überprüfen",
                            "birthday-needs-to-be-before-today": "Geburtstage dürfen nicht in der Zukunft liegen",
                            "membership-must-start-after-birth": "Das Startdatum der Mitgliedschaft muss nach der Geburt liegen",
                            "at-least-one-phone-number-required": "Bitte mindestens eine Telefonnummer angeben",
                            "iban-required": "Bitte eine IBAN angeben",
                            "invalid-iban": "Bitte eine gültige IBAN angeben",
                            "invalid-bic": "Bitte eine gültige BIC angeben",
                            "mandate-date-required": "Bitte ein Mandatsdatum angeben",
                        },
                    },
                    "memberDetails": {
                        "heading": "Mitgliederinformationen",
                        "companyPrefix": "Firma",
                        "unknownMember": "Unbekanntes Mitglied",
                        "unknown-detail": "unbekannt",
                        "sections": {
                            "main": {
                                "ageQuantifier": "Jahre",
                            },
                            "contacts": {
                                "heading": "Kontaktinformationen",
                            },
                            "membership": {
                                "heading": "Mitgliedschaft",
                                "memberNo": "Mitgliedsnummer",
                                "memberSince": "Mitglied seit",
                                "status": "Status",
                                "points": "Punkte",
                            },
                            "comments": {
                                "heading": "Amerkungen",
                                "general": "Generelle Bemerkungen",
                                "availability": "Verfügbarkeitsbemerkungen",
                                "noComments": "Keine Bemerkungen vorhanden"
                            },
                            "banking": {
                                "heading": "Bankverbindung",
                                "accountOwner": "Kontoinhaber",
                                "iban": "IBAN",
                                "bic": "BIC",
                                "bankName": "Bankname",
                                "mandateReference": "Mandatsreferenz",
                                "mandateDate": "Mandatsdatum",
                                "updatedAt": "zuletzt aktualisiert",
                            }
                        },
                        "buttons": {
                            "delete": {
                                "text": "Löschen",
                                "ariaLabel": "Mitglied löschen",
                            },
                            "back": {
                                "text": "Zurück",
                                "ariaLabel": "Navigiere zurück zur Mitgliederübersicht",
                            },
                            "edit": {
                                "main": {
                                    "ariaLabel": "Namen und Geburtsdatum bearbeiten"
                                },
                                "contact-details": {
                                    "ariaLabel": "Kontaktdetails bearbeiten"
                                },
                                "comment": {
                                    "ariaLabel": "Anmerkungen bearbeiten"
                                },
                            }
                        }
                    },
                    "memberActions": {
                        "deleteConfirmationMsg": "Soll '{{memberName}}' wirklich gelöscht werden?",
                    },
                    "memberEditDialog": {
                        "names": {
                            "title": "Mitglied bearbeiten",
                            "fields": {
                                "salutation": {
                                    "label": "Anrede",
                                    "ariaLabel": "Bitte Anrede für Mitglied auswählen"
                                },
                                "title": {
                                    "label": "Titel",
                                    "placeholder": "Dr.",
                                    "ariaLabel": "Titel des Mitglieds"
                                },
                                "firstName": {
                                    "label": "Vorname",
                                    "placeholder": "Alex",
                                    "ariaLabel": "Vorname des Mitglieds"
                                },
                                "lastName": {
                                    "label": "Nachname",
                                    "placeholder": "Muster",
                                    "ariaLabel": "Nachname des Mitglieds"
                                },
                                "dateOfBirth": {
                                    "label": "Geburtsdatum",
                                    "ariaLabel": "Geburtsdatum des Mitglieds"
                                },
                            },
                            "buttons": {
                                "cancel": {
                                    "text": "Abbrechen",
                                    "ariaLabel": "Bearbeitung abbrechen und Dialog schließen"
                                },
                                "save": {
                                    "text": "Speichern",
                                    "ariaLabel": "Bearbeitung speichern"
                                }
                            },
                            "errors": {
                                "dateOfBirth-before-membershipStartDate": "Geburtsdatum darf nicht nach Beitrittsdatum liegen",
                                "invalid-salutation": "Bitte Anrede überprüfen",
                                "missing-firstName": "Bitte geben Sie den Vornamen des Mitglieds an",
                                "missing-lastName": "Bitte geben Sie den Nachnamen des Mitglieds an",
                                "missing-dateOfBirth": "Bitte geben Sie das Geburtsdatum des Mitglieds an",
                                "unknown-salutation-issue": "Bitte Anrede überprüfen",
                                "unknown-title-issues": "Bitte Titel überprüfen",
                                "unknown-firstName-issues": "Bitte Vornamen überprüfen",
                                "unknown-lastName-issues": "Bitte Nachnamen überprüfen",
                                "unknown-companyName-issue": "Bitte Firmennamen überprüfen",
                                "unknown-dateOfBirth-issue": "Bitte Geburtsdatum prüfen",
                                "unknown-issue-with-msg": "Es ist ein Fehler beim Speichern aufgetreten ({{error}})",
                            }
                        },
                        "contacts": {
                            "title": "Kontaktdetails bearbeiten",
                            "sections": {
                                "phone": {
                                    "title": "Rufnummern"
                                },
                                "address": {
                                    "title": "Anschrift"
                                }
                            },
                            "fields": {
                                "email": {
                                    "label": "E-Mail-Adresse",
                                    "ariaLabel": "E-Mailadresse des Mitglieds"
                                },
                                "phone": {
                                    "landline": {
                                        "label": "Festnetz",
                                        "ariaLabel": "Festnetznummer des Mitglieds"
                                    },
                                    "mobile": {
                                        "label": "Mobiltelefon",
                                        "ariaLabel": "Mobiltelefonnummer des Mitglieds"
                                    },
                                },
                                "address": {
                                    "street": {
                                        "label": "Straße",
                                        "ariaLabel": "Straße der Mitgliedsadresse"
                                    },
                                    "houseNumber": {
                                        "label": "Hausnummer",
                                        "ariaLabel": "Hausnummer der Mitgliedsadresse"
                                    },
                                    "postcode": {
                                        "label": "PLZ",
                                        "ariaLabel": "Postleitzahl der Mitgliedsadresse"
                                    },
                                    "city": {
                                        "label": "Ort",
                                        "ariaLabel": "Stadt der Mitgliedsadresse"
                                    },
                                },
                            },
                            "buttons": {
                                "cancel": {
                                    "text": "Abbrechen",
                                    "ariaLabel": "Bearbeitung abbrechen und Dialog schließen"
                                },
                                "save": {
                                    "text": "Speichern",
                                    "ariaLabel": "Bearbeitung speichern"
                                }
                            },
                            "errors": {
                                "address-street-required": "Bitte eine Straße angeben.",
                                "address-postcode-required": "Bitte eine PLZ angeben.",
                                "address-city-required": "Bitte einen Ort angeben.",
                                "address-postcode-invalid-format": "Bitte eine deutsche Postleitzahl angeben.",
                                "invalid-email-address": "Bitte E-Mail-Adresse überprüfen",
                                "invalid-landline": "Bitte Festnetznummer überprüfen",
                                "invalid-mobile": "Bitte Mobiltelefonnummer überprüfen",
                                "at-least-one-phone-number-required": "Bitte mindestens Festnetz- oder Mobilnummer angeben.",
                                "unknown-email-issue": "Bitte E-Mail-Adresse prüfen",
                                "unknown-street-issue": "Bitte Straßennamen prüfen",
                                "unknown-houseNumber-issue": "Bitte Hausnummer prüfen",
                                "unknown-postcode-issue": "Bitte PLZ prüfen",
                                "unknown-city-issue": "Bitte Ort prüfen",
                                "unknown-landline-issue": "Bitte Festnetznummer prüfen",
                                "unknown-mobil-issue": "Bitte Mobiltelefonnummer prüfen",
                                "unknown-phone-number-issue": "Bitte Rufnummern überprüfen",
                            }
                        },
                        "membership": {
                            "title": "Mitgliedschaft bearbeiten",
                            "fields": {
                                "membershipStart": {
                                    "label": "Mitglied seit",
                                    "ariaLabel": "Startdatum der Mitgliedschaft"
                                },
                                "status": {
                                    "label": "Status",
                                    "ariaLabel": "Mitgliedschaftsstatus auswählen"
                                },
                            },
                            "buttons": {
                                "cancel": {
                                    "text": "Abbrechen",
                                    "ariaLabel": "Dialog ohne Änderungen übernehmen schliessen"
                                },
                                "save": {
                                    "text": "Speichern",
                                    "ariaLabel": "Änderungen übernehmen"
                                }
                            },
                            "errors": {
                                "invalid-membership-start-date-format": "Bitte das Startdatum der Mitgliedschaft prüfen",
                                "membership-start-required": "Bitte das Startdatum der Mitgliedschaft angeben",
                                "invalid-membership-status": "Bitte den Mitgliedsstatus prüfen",
                                "membership-status-required": "Bitte den Mitgliedsstatus auswählen",
                                "unknown-membership-start-date-issue": "Bitte das Startdatum der Mitgliedschaft prüfen",
                                "unknown-membership-status-issue": "Bitte den Status der Mitgliedschaft prüfen",
                                "start-date-before-birthday": "Die Mitgliedschaft darf nicht vor der Geburt beginnen"
                            },
                        },
                        "bankingDetails": {
                            "title": "Bankverbindung bearbeiten",
                            "fields": {
                                "accountOwner": {
                                    "label": "Kontoinhaber",
                                    "ariaLabel": "Kontoinhaber, falls abweichend vom Mitgliedsnamen (optional)",
                                    "placeholder": "",
                                },
                                "iban": {
                                    "label": "IBAN",
                                    "ariaLabel": "IBAN des Kontos, von dem eingezogen wird",
                                    "placeholder": "",
                                },
                                "bic": {
                                    "label": "BIC",
                                    "ariaLabel": "BIC des Kontos, von dem eingezogen wird (optional)",
                                    "placeholder": "",
                                },
                                "bankName": {
                                    "label": "Name der Bank",
                                    "ariaLabel": "Name der kontoführenden Bank (optional)",
                                    "placeholder": "",
                                },
                                "mandateReference": {
                                    "label": "Mandatsreferenz",
                                    "ariaLabel": "Mandatsreferenz, die bei Einzug angegeben wird",
                                    "placeholder": "",
                                },
                                "mandateDate": {
                                    "label": "Mandatsdatum",
                                    "ariaLabel": "Datum, an dem das SEPA Mandat erteilt wurde",
                                    "placeholder": "",
                                },
                            },
                            "buttons": {
                                "editIban": {
                                    "text": "Ändern",
                                    "ariaLabel": "zum Angeben einer neuen IBAN, hier klicken"
                                },
                                "delete": {
                                    "text": "Löschen",
                                    "ariaLabel": "Kontoverbindung löschen"
                                },
                                "cancel": {
                                    "text": "Abbrechen",
                                    "ariaLabel": "Dialog ohne Änderungen übernehmen schliessen"
                                },
                                "save": {
                                    "text": "Speichern",
                                    "ariaLabel": "Änderungen übernehmen"
                                }
                            },
                            "deleteConfirmation": {
                                "message": "Soll die Bankverbindung wirklich gelöscht werden?",
                                "toast": {
                                    "title": "Bankverbindung gelöscht",
                                    "description": "Löschen der Bankverbindung war erfolgreich"
                                }
                            },
                            "errors": {
                                "iban-required": "IBAN muss angegeben werden",
                                "invalid-iban": "Bitte IBAN prüfen",
                                "invalid-bic": "Bitte BIC prüfen",
                                "invalid-mandate-date-format": "Bitte Mandatsdatum prüfen",
                                "mandate-reference-required": "Die Mandatsreferenz ist ein Pflichtfeld",
                                "unknown-accountOwner-issue": "Bitte Eingabe für Kontoinhaber überprüfen",
                                "unknown-iban-issue": "Bitte IBAN überprüfen",
                                "unknown-bic-issue": "Bitte BIC überprüfen",
                                "unknown-bankName-issue": "Bitte BIC überprüfen",
                                "unknown-mandateReference-issue": "Bitte Mandatsreferenz überprüfen",
                                "unknown-mandateDate-issue": "Bitte Mandatsdatum überprüfen",
                            },
                        },
                        "comments": {
                            "title": "Kommentare bearbeiten",
                            "fields": {
                                "general": {
                                    "label": "Generelle Bemerkungen",
                                    "placeholder": "",
                                },
                                "availability": {
                                    "label": "Vermerke zu Einsatzzeiten bei aktiven Mitgliedern",
                                    "placeholder": "",
                                },
                            },
                            "buttons": {
                                "cancel": {
                                    "text": "Abbrechen",
                                    "ariaLabel": "Bearbeitung abbrechen und Dialog schließen"
                                },
                                "save": {
                                    "text": "Speichern",
                                    "ariaLabel": "Bearbeitung speichern"
                                }
                            },
                            "errors": {

                                "unknown-generalComments-issue": "Bitte Generelle Bemerkungen prüfen",
                                "unknown-availability-issue": "Bitte Vermerke zu Einsatzzeiten prüfen",


                            }
                        }
                    },
                    "orderList": {
                        "mainHeader": "Auftragsverwaltung",
                        "companyPrefix": "Firma",
                        "table": {
                            "headers": {
                                "orderNo": "Auftragsnr.",
                                "date": "Datum",
                                "member": "Auftraggeber",
                                "helper": "Helfer",
                                "service": "Service",
                                "comments": "Bemerkungen",
                                "status": "Status"
                            },
                            "statusColumn": {
                                "open": "Offen",
                                "done": "Erledigt",
                                "canceled": "Storniert"
                            }
                        },
                        "newOrderButton": {
                            "ariaLabel": "Neuen Auftrag hinzufügen",
                            "title": "Neuer Auftrag"
                        }
                    },
                    "orderDetails": {
                        "sections": {
                            "meta": {
                                "heading": "Auftragsinformationen",
                                "comments": "Bemerkungen",
                                "noComments": "Keine Bemerkungen vorhanden",
                                "submissionChannel": "Übermittlungsweg",
                                "service": "Dienstleistung",
                                "orderDate": "beauftragt zum",
                            },
                            "items": {
                                "heading": "Tätigkeitsübersicht"
                            }
                        },
                    },
                    "servicesList": {
                        "loading": "Lade Servicegruppen",
                        "serviceGroup": {
                            "title": "Servicegruppen & Services",
                            "no-services-yet": "Keine Services in dieser Gruppe. Füge den ersten Service hinzu.",
                            "serviceCount_one": "{{ count }} Service",
                            "serviceCount_other": "{{ count }} Services",
                            "servicesTable": {
                                "header": {
                                    "name": "Name",
                                    "description": "Beschreibung",
                                    "actions": "Aktionen",
                                    "fixedPoints": "Feste Punkte",
                                    "specialAssignment": "Spezialauftrag",
                                }
                            },
                            "empty": {
                                "title": "Keine Servicegruppen vorhanden",
                                "description": "Es sind noch keine Servicegruppen angelegt. Bitte eine Servicegruppe erstellen, um Services zu organisieren.",
                                "buttonText": "Erste Servicegruppe erstellen"
                            },
                            "noResults": {
                                "title": "Keine Ergebnisse gefunden",
                                "description": "Versuche andere Suchbegriffe oder setze die Filter zurück",
                                "buttonText": "Filter zurücksetzen"
                            }

                        },
                        "ungroupedServices": {
                            "title": "Services ohne Gruppe",
                            "description": "Hier werden alle Services ohne Gruppe aufgelistet",
                        },
                        "buttons": {
                            "add-service-group": {
                                "text": "Neue Servicegruppe",
                                "ariaLabel": "Erstellen einer neuen Servicegruppe",
                            },
                            "edit-service-group": {
                                "ariaLabel": "Servicegruppe '{{groupName}}' bearbeiten",
                            },
                            "delete-service-group": {
                                "ariaLabel": "Servicegruppe '{{groupName}}' löschen",
                            },
                            "edit-service": {
                                "ariaLabel": "Service '{{serviceName}}' bearbeiten",
                            },
                            "delete-service": {
                                "ariaLabel": "Service '{{serviceName}}' löschen",
                            },
                            "add-service": {
                                "text": "Service hinzufügen",
                                "ariaLabel": "Neuen Service der Gruppe '{{groupName}}' hinzufügen",
                            }
                        },
                        "search": {
                            "placeholder": "Nach Services oder Gruppen suchen...",
                            "ariaLabel": "Nach Services oder Gruppen suchen"
                        },
                        "filter": {
                            "title": "Kennzeichen-Filter",
                            "resetAll": "Filter zurücksetzen",
                            "isFixedPoints": "Feste Punkte",
                            "isSpecialAssignment": "Sonderauftrag",
                            "toggleisFixedPoints": "Filter 'Feste Punkte' umschalten",
                            "toggleisSpecialAssignment": "Filter 'Spezialauftrag' umschalten",
                        },
                    },
                    "serviceGroupDialog": {
                        "title": {
                            "create": "Servicegruppe erstellen",
                            "edit": "Servicegruppe bearbeiten",
                        },
                        "fields": {
                            "name": {
                                "label": "Gruppenname",
                                "ariaLabel": "Name der Servicegruppe",
                                "placeholder": "",
                            },
                            "description": {
                                "label": "Beschreibung",
                                "ariaLabel": "Kurze Beschreibung der Servicegruppe",
                                "placeholder": "",
                            }
                        },
                        "buttons": {
                            "cancel": {
                                "ariaLabel": "Dialog ohne zu speichern schließen",
                                "text": "Abbrechen"
                            },
                            "submit": {
                                "ariaLabel": "Eingaben speichern",
                                "text": "Speichern"
                            },

                        },
                        "toast": {
                            "success": {
                                "title": "Servicegruppe erstellt",
                                "description": "Die Servicegruppe wurde erfolgreich erstellt und die Liste der wird neu geladen."
                            },
                        },
                        "errors": {
                            "name-required": "Bitte geben Sie einen Gruppennamen an",
                            "name-already-exists": "Eine Gruppe mit diesem Namen existiert bereits",
                            "unknown-name-issue": "Bitte Gruppennamen überprüfen",
                            "unknown-description-issue": "Bitte die Beschreibung überprüfen",
                        }
                    },
                    "serviceDialog": {
                        "title": {
                            "create": "Service erstellen",
                            "edit": "Service bearbeiten",
                        },
                        "serviceGroupPrefix": "Gruppe",
                        "serviceWithoutGroupTitleMarker": "Ohne Gruppe",
                        "flags": {
                            "legend": "Weitere Merkmale",
                            "text": "Hier können spezielle Eigenschaften des Services gesetzt werden."
                        },
                        "fields": {
                            "name": {
                                "label": "Servicename",
                                "ariaLabel": "Name des Service",
                                "placeholder": "",
                            },
                            "description": {
                                "label": "Beschreibung",
                                "ariaLabel": "Kurze Beschreibung des Services",
                                "placeholder": "",
                            },
                            "pointsPerUnit": {
                                "label": "Punkte pro Einheit",
                                "ariaLabel": "Punkte pro Einheit",
                                "placeholder": "",
                            },
                            "isFixedPoints": {
                                "label": "Fixe Punkte",
                                "description": "Wenn aktiviert, gibt es die Punkte einmalig, sonst pro Zeiteinheit",
                                "ariaLabel": "Wenn gesetzt, gibt es eine fixe Anzahl Punkte, sonst pro Zeiteinheit",
                                "placeholder": "",
                            },
                            "isSpecialAssignment": {
                                "label": "Sonderauftrag",
                                "description": "Dies markiert den Service als Sonderauftrag",
                                "ariaLabel": "Dieser Service beschreibt einen Sonderauftrag",
                                "placeholder": "",
                            },
                        },
                        "buttons": {
                            "cancel": {
                                "ariaLabel": "Dialog ohne zu speichern schließen",
                                "text": "Abbrechen"
                            },
                            "submit": {
                                "ariaLabel": "Eingaben speichern",
                                "text": "Speichern"
                            },

                        },
                        "toast": {
                            "success": {
                                "title": "Service erstellt",
                                "description": "Der Service wurde erfolgreich erstellt und die Liste der wird neu geladen."
                            },
                        },
                        "errors": {
                            "name-required": "Bitte geben Sie einen Namen für den Service an",
                            "name-already-exists": "Eine Service mit diesem Namen existiert bereits",
                            "pointsPerUnit-required": "Bitte geben Sie die Anzahl Punkte an.",
                            "unknown-name-issue": "Bitte Servicenamen überprüfen",
                            "unknown-description-issue": "Bitte die Beschreibung überprüfen",
                            "unknown-pointsPerUnit-issue": "Bitte die 'Punkte pro Einheit' überprüfen",
                            "unknown-isFixedPoints-issue": "Bitte die 'Fixe Punkte' überprüfen",
                            "unknown-isSpecialAssignment-issue": "Bitte die 'Sonderauftrag' überprüfen",
                        }
                    },
                    "deleteServiceGroupDialog": {
                        "title": "Servicegruppe löschen",
                        "description_one": "Soll die Servicegruppe \"{{groupName}}\" wirklich gelöscht werden?<br />Diese Servicegruppe enthält <strong>{{count}}</strong> Service.",
                        "description_other": "Soll die Servicegruppe \"{{groupName}}\" wirklich gelöscht werden?<br />Diese Servicegruppe enthält <strong>{{count}}</strong> Services.",
                        "confirmationField": {
                            "ariaLabel": "Geben Sie hier die Anzahl Services ein, die in dieser Gruppe enthalten sind. Erst dann kann die Gruppe gelöscht werden",
                            "placeholder": "Anzahl der Services",
                            "description": "Geben Sie bitte die Anzahl der Services (<strong>{{count}}</strong>) ein, um das Löschen zu bestätigen.",
                        },
                        "buttons": {
                            "submit": {
                                "text": "Löschen",
                                "ariaLabel": "Servicegruppe wirklich löschen. Diese Funktion wird dann aktiv, wenn die korrekte Anzahl von noch enthaltenen Services eingegeben wird.",
                            },
                            "cancel": {
                                "text": "Abbrechen",
                                "ariaLabel": "Dialog ohne zu speichern schliessen",
                            }
                        },
                        "errors": {
                            "invalid-confirmation-value": "Bitte geben Sie die korrekte Anzahl an Services ({{serviceCount}}) ein, um das Löschen zu bestätigen.",
                            "unknown-confirmation-issue": "Bitte das Eingabefeld prüfen.",
                        },
                        "toast": {
                            "success": {
                                "title": "Servicegruppe gelöscht",
                                "description_one": "Die Gruppe '{{groupName}}' und der darin enthaltene Service wurden gelöscht.",
                                "description_other": "Die Gruppe '{{groupName}}' und die darin enthaltenen {{count}} Services wurden gelöscht.",
                            },
                        },
                    },
                    "deleteServiceDialog": {
                        "message": "Soll der Service '{{serviceName}}' wirklich gelöscht werden?",
                        "toast": {
                            "success": {
                                "title": "Löschen erfolgreich",
                                "description": "Der Service '{{serviceName}}' wurde erfolgreich gelöscht."
                            },
                            "error": {
                                "title": "Löschen fehlgeschlagen",
                                "description": "Beim Löschen von '{{serviceName}}' ist ein Fehler aufgetreten."
                            },
                        },
                    },
                    "phoneNumberDisplay": {
                        "type": {
                            "MOBILE": "Mobiltelefon",
                            "LANDLINE": "Festnetz",
                            "UNKNOWN": "Keine Telefonnummer bekannt",
                        },
                        "detailedDescription": {
                            "MOBILE": "Dies ist eine Mobiltelefonnummer",
                            "LANDLINE": "Dies ist eine Festnetznummer",
                            "UNKNOWN": "Es wurde noch keine Rufnummer hinterlegt",
                        },
                        "no-number": "Keine Rufnummern hinterlegt"
                    },
                    "deleteConfirmation": {
                        "heading": "Löschen bestätigen",
                        "defaultMessage": "Möchten Sie wirklich Löschen?",

                        "buttons": {
                            "cancel": {
                                "ariaLabel": "Nicht löschen und Dialog schliessen",
                                "text": "Abbrechen"
                            },
                            "submit": {
                                "ariaLabel": "Bestätigt den Löschvorgang",
                                "text": "Löschen"
                            },
                        },
                    },
                    "serverError": {
                        "unknown-with-details": "Ein unbekannter Fehler ist aufgetreten. ( {{errorDetails}} )",
                        "unknown": "Ein unbekannter Fehler ist aufgetreten.",
                        "response-code": "Der Server hat unerwartet mit dem Status {{statusCode}} geantwortet.",
                        "response-code-with-details": "Der Server hat unerwartet mit dem Status {{statusCode}} geantwortet. ({{errorDetails}})",
                    },
                }
            }
        }

    })

export default i18n;
