import {create} from 'zustand'
import {persist} from 'zustand/middleware'

interface UserSettings {
    tableSettings: {
        pageSize: number
    },
    setPageSize: (size: number) => void
}

export const useUserSettingsStore = create<UserSettings>()(
    persist(
        (set) => ({
            tableSettings: {
                pageSize: 10,
            },
            setPageSize: (size: number) => set({tableSettings: {pageSize: size}})
        }),
        {
            name: 'user-settings',
        }
    )
)
