import {useEffect, useState} from 'react';
import {Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle} from "@/components/tailwind/dialog";
import {Input} from "@/components/tailwind/input";
import {Button} from "@/components/tailwind/button";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import * as z from "zod";
import {ServiceGroupWithServices} from "@/models/service";
import {ValidationIssue} from "@/types/problem-details.ts";
import {deleteServiceGroup} from '@/services/service-services';
import {useAuth} from "@/context/use-auth.tsx";
import {Trans, useTranslation} from "react-i18next";
import {useToast} from "@/hooks/use-toast.ts";
import ValidationAlert from "@/components/validation-alert.tsx";
import {Description, Field} from "@/components/tailwind/fieldset.tsx";

type DeleteServiceGroupDialogProps = {
    isOpen: boolean;
    onClose: () => void;
    serviceGroup: ServiceGroupWithServices | undefined;
    onConfirm: () => void;
};

type FormValues = {
    confirmation: string;
};

export function DeleteServiceGroupDialog({isOpen, onClose, serviceGroup, onConfirm}: DeleteServiceGroupDialogProps) {
    const serviceCount = serviceGroup?.services?.length || 0;

    const schema = z.object({
        confirmation: z
            .string()
            .refine((val) => val === serviceCount.toString(), {
                message: "invalid-confirmation-value",
            }),
    });

    const {
        register,
        handleSubmit,
        reset,
        setError,
        formState: {errors, isValid},
    } = useForm<FormValues>({
        resolver: zodResolver(schema),
        mode: "onChange",
    });

    const {t} = useTranslation();
    const {token} = useAuth();
    const {toast} = useToast()

    useEffect(() => {
        if (isOpen) {
            reset();
        }
    }, [isOpen, reset]);

    function showSuccessToast() {
        toast({
            title: t("deleteServiceGroupDialog.toast.success.title"),
            description: t(
                "deleteServiceGroupDialog.toast.success.description",
                {
                    count: serviceCount,
                    groupName: serviceGroup!!.name,
                }
            ),
            variant: "default",
        })

    }

    async function onSubmit(_: FormValues): Promise<void> {
        if (!Boolean(serviceGroup)) return;

        if (await doServiceGroupDeletion()) {
            onConfirm()
            showSuccessToast()
            onClose()
        }

    }

    async function doServiceGroupDeletion(): Promise<boolean> {
        if (!Boolean(serviceGroup)) {
            return false;
        }

        try {
            await deleteServiceGroup(token, serviceGroup!!.id)
            return true
        } catch (error: any) {
            console.error(`An error occurred while trying to delete service group ${serviceGroup!!.id}.`, error);

            if (Boolean(error.validationIssues)) {
                error.validationIssues.map(
                    (finding: ValidationIssue) => {
                        setError(
                            finding.field as "confirmation" | `root.${string}` | "root",
                            {
                                message: finding.message
                            }
                        )
                    }
                )
            }

            if (Boolean(error.status)) {
                setError("root", {
                    message: t("serverError.response-code-with-details", {
                        statusCode: error.status,
                        errorDetails: error.error
                    })
                })
            }

            return false;
        }
    }

    return (
        <Dialog open={isOpen} onClose={onClose}>

            <DialogTitle><Trans i18nKey="deleteServiceGroupDialog.title"/></DialogTitle>

            {errors.root && (
                <ValidationAlert message={t(errors.root.message || "serverError.unknown")} className="mt-4"/>
            )}

            <DialogDescription>
                <Trans i18nKey="deleteServiceGroupDialog.description" count={serviceCount}
                       values={{groupName: serviceGroup?.name}}
                />
            </DialogDescription>

            <DialogBody>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="space-y-4">
                        <Field>
                            <Description>
                                <Trans i18nKey="deleteServiceGroupDialog.confirmationField.description"
                                       values={{count: serviceCount}}/>
                            </Description>
                            <Input
                                {...register("confirmation")}
                                type="text"
                                placeholder={t("deleteServiceGroupDialog.confirmationField.placeholder")}
                                aria-label={t("deleteServiceGroupDialog.confirmationField.ariaLabel")}
                                invalid={!!errors.confirmation}
                            />
                            {errors.confirmation && (
                                <ValidationAlert message={
                                    t(
                                        errors.confirmation.message || "unknown-confirmation-issue",
                                        {
                                            serviceCount: serviceCount
                                        }
                                    )
                                }/>
                            )}
                        </Field>
                    </div>

                    <DialogActions>
                        <Button
                            plain
                            onClick={onClose}
                            aria-label={t("deleteServiceGroupDialog.buttons.cancel.ariaLabel")}
                        >
                            <Trans i18nKey="deleteServiceGroupDialog.buttons.cancel.text"/>
                        </Button>
                        <Button
                            type="submit"
                            color="red"
                            aria-label={t("deleteServiceGroupDialog.buttons.submit.ariaLabel")}
                            disabled={!isValid}
                        >
                            <Trans i18nKey="deleteServiceGroupDialog.buttons.submit.text"/>
                        </Button>
                    </DialogActions>
                </form>


            </DialogBody>
        </Dialog>
    );
}

export function useDeleteServiceGroupDialog() {
    const [isOpen, setIsOpen] = useState(false);
    const [serviceGroup, setServiceGroup] = useState<ServiceGroupWithServices | undefined>(undefined)

    function openDialog(serviceGroup?: ServiceGroupWithServices | undefined) {
        setServiceGroup(serviceGroup)
        setIsOpen(true)
    }

    function closeDialog() {
        setIsOpen(false)
    }

    return {
        isDeleteServiceGroupDialogOpen: isOpen,
        initialServiceDialogData: serviceGroup,
        openDeleteServiceGroupDialog: openDialog,
        closeDeleteServiceGroupDialog: closeDialog,
        serviceGroup,
        deleteServiceGroupDialogProps: {
            isOpen: isOpen,
            onClose: closeDialog,
            serviceGroup: serviceGroup,
        }
    }
}
