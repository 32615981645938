import './App.css'
import {Root} from "./components/main-layout/root";
import {UserProvider} from "./context/use-auth";
import './i18n'
import {Toaster} from "@/components/ui/toaster.tsx";

export default function App() {

    return (
        <>
            <UserProvider>
                <Root/>
            </UserProvider>
            <Toaster/>
        </>

    )
}
