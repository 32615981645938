import {Dialog, DialogActions, DialogTitle} from "@/components/tailwind/dialog.tsx";
import {useState} from "react";
import {Button} from "@/components/tailwind/button.tsx";
import {Input} from "@/components/tailwind/input.tsx";
import {UserChangePasswordDTO, UserProfile} from "@/models/user.ts";
import {useAuth} from "@/context/use-auth.tsx";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {z} from "zod";
import {Subheading} from "@/components/tailwind/heading.tsx";
import {Text} from "@/components/tailwind/text.tsx";
import ValidationAlert from "@/components/validation-alert.tsx";
import {Trans, useTranslation} from "react-i18next";
import {changeMyPassword, changePassword} from "@/services/user-services.ts";
import {useToast} from "@/hooks/use-toast.ts";
import {ProblemDetail} from "@/types/problem-details.ts";

interface ChangePasswordDialogProps {
    effectedUserId: string;
    standalone?: boolean;
    onClose?: () => void;
    isInitiallyOpen?: boolean;
    isOwnPassword?: boolean;
}

type ChangePasswordFormData = {
    id?: string,
    currentPassword?: string,
    newPassword: string,
    confirmNewPassword: string,
}

function createSchema(isCurrentUser: boolean) {
    return z.object({
        currentPassword: isCurrentUser
            ? z.string().nonempty("changePasswordDialog.errors.missing-current-password")
            : z.string().optional(),
        newPassword:
            z.string({required_error: "changePasswordDialog.errors.missing-new-password"})
                .min(8, "changePasswordDialog.errors.password-min-length"),
        confirmNewPassword:
            z.string({required_error: "changePasswordDialog.errors.missing-confirm-password"})
    }).refine((data) => data.newPassword === data.confirmNewPassword, {
        message: "changePasswordDialog.errors.password-mismatch",
        path: ["confirmNewPassword"],
    });
}

export default function ChangePasswordDialog({
                                                 effectedUserId,
                                                 standalone = false,
                                                 onClose,
                                                 isInitiallyOpen = false,
                                                 isOwnPassword = false,
                                             }: ChangePasswordDialogProps) {
    const [isOpen, setIsOpen] = useState(isInitiallyOpen)
    const {user, token} = useAuth()
    const {toast} = useToast()
    const {t} = useTranslation()

    if (user === null || neitherUserNorAdmin(user, effectedUserId)) {
        toast({
            title: t("changePasswordDialog.toast.unauthorized.title"),
            description: t("changePasswordDialog.toast.unauthorized.description"),
            duration: 15000,
        })
        return null
    }

    const changeOwnPassword = user.userId === effectedUserId

    const {
        register,
        handleSubmit,
        reset,
        setError,
        formState: {errors},
    } = useForm<ChangePasswordFormData>({resolver: zodResolver(createSchema(changeOwnPassword))});

    function showSuccessToast() {
        toast({
            title: t("changePasswordDialog.toast.success.title"),
            description: t("changePasswordDialog.toast.success.description"),
            variant: "default",
        })
    }

    function handleClose() {
        setIsOpen(false)
        reset({
            currentPassword: '',
            newPassword: '',
            confirmNewPassword: ''
        })
        onClose?.()
    }

    async function onSubmit(data: ChangePasswordFormData) {

        try {
            const changePasswordRequest = {
                oldPassword: data.currentPassword,
                newPassword: data.newPassword
            } as UserChangePasswordDTO

            let changePasswordResponse;

            if (isOwnPassword) {
                changePasswordResponse = changeMyPassword(changePasswordRequest, token!!);
            } else {
                changePasswordResponse = changePassword(changePasswordRequest, effectedUserId, token!!);
            }

            changePasswordResponse
                .then((_) => {
                    handleClose()
                    showSuccessToast()
                })
                .catch((reason: ProblemDetail) => {
                    console.warn("An error occurred while changing the password", reason)

                    if (reason.validationIssues) {
                        reason.validationIssues.map(
                            finding => {
                                setError(
                                    finding.field as keyof Omit<ChangePasswordFormData, 'id'>,
                                    {
                                        message: finding.message
                                    }
                                )
                            }
                        )
                    } else {

                        if (reason.status) {
                            setError(
                                "root.serverError",
                                {
                                    type: `${reason.status}`,
                                    message: t("serverError.response-code", {statusCode: reason.status})
                                }
                            )
                        } else {
                            setError(
                                "root.serverError",
                                {
                                    type: `serverError`,
                                    message: t("serverError.unknown")
                                }
                            )
                        }
                    }
                })

        } catch (error) {
            console.error("Error while changing password:", error);

            setError(
                "root.serverError",
                {
                    type: `serverError`,
                    message: t("serverError.unknown-with-details", {errorDetails: error})
                }
            )
        }
    }

    return (
        <>

            {!standalone && (
                <Button type="button"
                        onClick={() => setIsOpen(true)}
                        aria-label={t("changePasswordDialog.buttons.openDialog.ariaLabel")}
                >
                    <Trans i18nKey={"changePasswordDialog.buttons.openDialog.text"}/>
                </Button>
            )}

            <Dialog
                open={isOpen}
                onClose={handleClose}
            >
                <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                    <DialogTitle>Passwort ändern</DialogTitle>

                    {errors.root?.serverError && (
                        <ValidationAlert message={errors.root.serverError.message || t("serverError.unknown")}/>
                    )}
                    {errors.id && (
                        <ValidationAlert message={t(errors.id.message || "changePasswordDialog.errors.invalid-id")}/>
                    )}

                    <div className="space-y-6">
                        {changeOwnPassword && (
                            <div className="grid gap-y-6 sm:grid-cols-2">
                                <div className="space-y-1">
                                    <Subheading id="currentPasswordHeading">
                                        <Trans i18nKey={"changePasswordDialog.fields.currentPassword.heading"}/>
                                    </Subheading>
                                    <Text>
                                        <Trans i18nKey={"changePasswordDialog.fields.currentPassword.description"}/>
                                    </Text>
                                </div>
                                <div>
                                    <Input
                                        type="password"
                                        id="current-password"
                                        {...register("currentPassword")}
                                        placeholder={t("changePasswordDialog.fields.currentPassword.placeholder")}
                                        autoComplete="current-password"
                                        data-invalid={errors.currentPassword || null}
                                    />
                                    {errors.currentPassword && (
                                        <ValidationAlert message={t(errors.currentPassword.message ||
                                            "changePasswordDialog.errors.unknown-current-password-issue")}
                                        />
                                    )}
                                </div>
                            </div>
                        )}

                        <div className="grid gap-y-6 sm:grid-cols-2">
                            <div className="space-y-1">
                                <Subheading id="newPasswordHeading">
                                    <Trans i18nKey={"changePasswordDialog.fields.newPassword.heading"}/>
                                </Subheading>
                                <Text>
                                    <Trans i18nKey={"changePasswordDialog.fields.newPassword.description"}/>
                                </Text>
                            </div>
                            <div className="space-y-4">
                                <div>
                                    <Input
                                        type="password"
                                        {...register("newPassword")}
                                        placeholder={t("changePasswordDialog.fields.newPassword.placeholder")}
                                        autoComplete="new-password"
                                        data-invalid={errors.newPassword || null}
                                    />
                                    {errors.newPassword && (
                                        <ValidationAlert message={t(errors.newPassword.message ||
                                            "changePasswordDialog.errors.unknown-newPassword-issue")}
                                        />
                                    )}
                                </div>
                                <div>
                                    <Input
                                        type="password"
                                        {...register("confirmNewPassword")}
                                        placeholder={t("changePasswordDialog.fields.confirmPassword.placeholder")}
                                        autoComplete="new-password"
                                        data-invalid={errors.confirmNewPassword || null}
                                    />
                                    {errors.confirmNewPassword && (
                                        <ValidationAlert message={t(errors.confirmNewPassword.message ||
                                            "changePasswordDialog.errors.unknown-confirm-password-issue")}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <DialogActions>
                        <Button
                            type="button"
                            plain
                            onClick={handleClose}
                            aria-label={t("changePasswordDialog.buttons.cancel.ariaLabel")}
                        >
                            <Trans i18nKey={"changePasswordDialog.buttons.cancel.text"}/>
                        </Button>
                        <Button
                            type="submit"
                            aria-label={t("changePasswordDialog.buttons.submit.ariaLabel")}
                        >
                            <Trans i18nKey={"changePasswordDialog.buttons.submit.text"}/>
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    )
}

function neitherUserNorAdmin(user: UserProfile, effectedUserId: string) {
    return user.userId !== effectedUserId && user.role !== "ADMIN"
}
