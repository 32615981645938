'use client'

import {StackedLayout} from "../tailwind/stacked-layout.tsx";
import {Navbar, NavbarDivider, NavbarItem, NavbarSection, NavbarSpacer} from "../tailwind/navbar.tsx";
import {
    Sidebar,
    SidebarBody,
    SidebarFooter,
    SidebarHeading,
    SidebarItem,
    SidebarLabel,
    SidebarSection
} from "../tailwind/sidebar.tsx";
import {
    Dropdown,
    DropdownButton,
    DropdownDivider,
    DropdownItem,
    DropdownLabel,
    DropdownMenu
} from "../tailwind/dropdown.tsx";
import {
    ArrowRightStartOnRectangleIcon,
    ChevronUpIcon,
    CogIcon,
    HomeIcon,
    KeyIcon,
    ListBulletIcon,
    UserCircleIcon,
    UsersIcon,
} from '@heroicons/react/16/solid'
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {Trans} from "react-i18next";
import {useAuth} from "../../context/use-auth.tsx";
import {t} from "i18next";
import {SyntheticEvent} from "react";

function AccountDropdownMenu({anchor}: { anchor: 'top start' | 'bottom end' }) {
    const {isLoggedIn, logout} = useAuth()
    const navigate = useNavigate()

    function doLogout() {
        if (isLoggedIn()) {
            logout()
        }

        navigate(`${import.meta.env.BASE_URL}`)
    }

    return (
        <DropdownMenu className="min-w-64" anchor={anchor}>
            <DropdownItem href="#">
                <UserCircleIcon/>
                <DropdownLabel aria-label="menu.myAccount.account.ariaLabel"><Trans
                    i18nKey="menu.myAccount.account.dropdownLabel"/></DropdownLabel>
            </DropdownItem>

            <ChangePasswordMenuItem/>

            <DropdownDivider/>
            <DropdownItem onClick={doLogout}>
                <ArrowRightStartOnRectangleIcon/>
                <DropdownLabel aria-label="menu.myAccount.logout.ariaLabel"><Trans
                    i18nKey="menu.myAccount.logout.dropdownLabel"/></DropdownLabel>
            </DropdownItem>
        </DropdownMenu>
    )
}


function ChangePasswordMenuItem() {
    const {user, openChangePasswordDialog} = useAuth()

    if (!user) {
        return null
    }

    return (
        <>
            <DropdownItem onClick={(e: SyntheticEvent) => {
                e.preventDefault();
                e.stopPropagation();
                openChangePasswordDialog()
            }}>
                <KeyIcon/>
                <DropdownLabel aria-label="menu.myAccount.changePassword.ariaLabel">
                    <Trans i18nKey="menu.myAccount.changePassword.dropdownLabel"/>
                </DropdownLabel>
            </DropdownItem>

        </>
    )
}


export function Root() {

    const {pathname} = useLocation()
    const {user, isLoggedIn, isAdmin} = useAuth()

    return (
        <StackedLayout
            // for larger screen
            navbar={
                <Navbar>
                    <NavbarSection className="max-lg:hidden">
                        <NavbarItem href="/" current={pathname === '/'}><HomeIcon/><Trans
                            i18nKey="menu.navbar.home.label"/></NavbarItem>
                    </NavbarSection>

                    {/* User routes */}
                    {isLoggedIn() && (
                        <>

                            <NavbarDivider className="max-lg:hidden"/>

                            <NavbarSection className="max-lg:hidden"
                                           aria-label={t("menu.navbar.membersSection.ariaLabel")}>
                                <NavbarItem href={`${import.meta.env.BASE_URL}members`}
                                            current={pathname.startsWith(`${import.meta.env.BASE_URL}members`)}>
                                    <UsersIcon/><Trans i18nKey="menu.navbar.members.label"/>
                                </NavbarItem>
                            </NavbarSection>

                            <NavbarSection className="max-lg:hidden" aria-label={t("menu.navbar.orders.ariaLabel")}>
                                <NavbarItem href={`${import.meta.env.BASE_URL}orders`}
                                            current={pathname.startsWith(`${import.meta.env.BASE_URL}orders`)}>
                                    <ListBulletIcon/><Trans i18nKey="menu.navbar.orders.label"/>
                                </NavbarItem>
                            </NavbarSection>
                        </>
                    )}

                    {/* Admin routes */}
                    {isAdmin() && (
                        <>
                            <NavbarDivider/>
                            <NavbarSection className="max-lg:hidden"
                                           aria-label={t("menu.navbar.adminSection.ariaLabel")}>
                                <NavbarItem href={`${import.meta.env.BASE_URL}admin/users`}
                                            current={pathname.startsWith(`${import.meta.env.BASE_URL}admin/users`)}>
                                    <UsersIcon/><Trans i18nKey="menu.navbar.users.label"/>
                                </NavbarItem>
                                <NavbarItem href={`${import.meta.env.BASE_URL}admin/services`}
                                            current={pathname.startsWith(`${import.meta.env.BASE_URL}admin/services`)}>
                                    <CogIcon/><Trans i18nKey="menu.navbar.services.label"/>
                                </NavbarItem>
                            </NavbarSection>
                        </>

                    )}

                    <NavbarSpacer/>

                    <NavbarSection>
                        {isLoggedIn() && (

                            <Dropdown>
                                <DropdownButton as={NavbarItem}>
                                    <UserCircleIcon/> {user?.userName}
                                </DropdownButton>
                                <AccountDropdownMenu anchor={"bottom end"}/>
                            </Dropdown>
                        )}

                        {!isLoggedIn() && (
                            <NavbarItem href={`${import.meta.env.BASE_URL}login`}
                                        current={pathname.startsWith(`${import.meta.env.BASE_URL}login`)}
                                        aria-label={t("menu.login.ariaLabel")}><Trans
                                i18nKey="menu.login.text"/></NavbarItem>
                        )}
                    </NavbarSection>
                </Navbar>
            }
            // for mobile / smaller screens
            sidebar={
                <Sidebar>
                    <SidebarBody>
                        <SidebarSection>
                            <SidebarItem href={`${import.meta.env.BASE_URL}`}
                                         current={pathname === `${import.meta.env.BASE_URL}`}>
                                <HomeIcon/>
                                <SidebarLabel><Trans i18nKey="menu.navbar.home.label"/></SidebarLabel>
                            </SidebarItem>
                        </SidebarSection>

                        {isLoggedIn() && (
                            <>
                                <SidebarSection>
                                    <SidebarHeading><Trans i18nKey="menu.sidebar.members.heading"/></SidebarHeading>
                                    <SidebarItem href={`${import.meta.env.BASE_URL}members`}
                                                 current={pathname.startsWith(`${import.meta.env.BASE_URL}members`)}>
                                        <UsersIcon/>
                                        <SidebarLabel>
                                            <Trans i18nKey="menu.sidebar.members.overview.label"/>
                                        </SidebarLabel>
                                    </SidebarItem>
                                </SidebarSection>
                                <SidebarSection>
                                    <SidebarHeading><Trans i18nKey="menu.sidebar.orders.heading"/></SidebarHeading>
                                    <SidebarItem href={`${import.meta.env.BASE_URL}orders`}
                                                 current={pathname.startsWith(`${import.meta.env.BASE_URL}orders`)}>
                                        <ListBulletIcon/>
                                        <SidebarLabel>
                                            <Trans i18nKey="menu.sidebar.orders.overview.label"/>
                                        </SidebarLabel>
                                    </SidebarItem>
                                </SidebarSection>
                            </>
                        )}

                        {isAdmin() && (
                            <SidebarSection aria-label={t("menu.adminSection.ariaLabel")}>
                                <SidebarHeading><Trans i18nKey="menu.sidebar.admin.heading"/></SidebarHeading>
                                <SidebarItem
                                    href={`${import.meta.env.BASE_URL}admin/users`}
                                    current={pathname.startsWith(`${import.meta.env.BASE_URL}admin/users`)}
                                >
                                    <UsersIcon/>
                                    <SidebarLabel><Trans i18nKey="menu.sidebar.admin.users.label"/></SidebarLabel>
                                </SidebarItem>
                                <SidebarItem
                                    href={`${import.meta.env.BASE_URL}admin/services`}
                                    current={pathname.startsWith(`${import.meta.env.BASE_URL}admin/services`)}
                                >
                                    <CogIcon/>
                                    <SidebarLabel><Trans i18nKey="menu.sidebar.admin.services.label"/></SidebarLabel>
                                </SidebarItem>
                            </SidebarSection>
                        )}

                    </SidebarBody>

                    <SidebarFooter className="max-lg:hidden">
                        <Dropdown>
                            <DropdownButton as={SidebarItem}>
                                <ChevronUpIcon/>
                            </DropdownButton>
                            <AccountDropdownMenu anchor="top start"/>
                        </Dropdown>
                    </SidebarFooter>
                </Sidebar>
            }
        >
            <Outlet/>
        </StackedLayout>
    )
}
