import {Text} from "@/components/tailwind/text"
import {Trans, useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {useAuth} from "@/context/use-auth.tsx";
import {
    CurrentPointsDTO,
    deleteMemberData,
    generateCompleteMemberName,
    getMemberData,
    getMemberFormOptions
} from "@/services/member-services.ts";
import {Member, MemberFormOptions} from "@/models/member.ts";
import {isDefined} from "@/helpers/sanitizer.ts";
import {Button} from "@/components/tailwind/button.tsx";
import {Divider} from "@/components/tailwind/divider.tsx";
import {Card} from "@/components/ui/card";
import {Avatar, AvatarFallback} from "@/components/ui/avatar";
import {
    Activity,
    Banknote,
    Building,
    Cake,
    CalendarDays,
    Clock,
    Coins,
    CreditCard,
    FileSignature,
    Hash,
    Mail,
    MapPin,
    MessageSquare,
    Pencil,
    User,
    Users
} from 'lucide-react';
import {Heading, Subheading} from "@/components/tailwind/heading.tsx";
import {Link} from "@/components/tailwind/link.tsx";
import {differenceInYears, format, parseISO} from "date-fns";
import MemberActionsButton from "@/components/member-actions-button.tsx";
import MemberEditNamesDialog from "@/components/member-edit-names-dialog.tsx";
import MemberEditCommentsDialog from "@/components/member-edit-comments-dialog.tsx";
import PhoneNumberDisplay from "@/components/phone-number-display.tsx";
import MemberEditContactDetailsDialog from "@/components/member-edit-contact-details-dialog.tsx";
import MemberEditMembershipDialog from "@/components/member-edit-membership-dialog.tsx";
import MemberEditBankingDetailsDialog from "@/components/member-edit-bankdetails-dialog.tsx";

export default function MemberDetailsPage() {
    const {t} = useTranslation()
    const {memberId} = useParams()
    const {token} = useAuth()
    const navigate = useNavigate()

    const [isEditNamesDialogOpen, setIsEditNamesDialogOpen] = useState(false);
    const [isEditCommentsDialogOpen, setIsEditCommentsDialogOpen] = useState(false);
    const [isEditContactDetailsDialogOpen, setIsEditContactDetailsDialogOpen] = useState(false);
    const [isEditMembershipDialogOpen, setIsEditMembershipDialogOpen] = useState(false);
    const [isEditBankingDetailsDialogOpen, setIsEditBankingDetailsDialogOpen] = useState(false);
    const [memberData, setMemberData] = useState<Member>()
    const [memberDataRetrievalError, setMemberDataRetrievalError] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [memberFormOptions, setMemberFormOptions] = useState<MemberFormOptions>()

    const handleDeleteMember = () => {
        const deleteMember = deleteMemberData(token, memberId)

        deleteMember
            .then(() => {
                navigate("/members")
            })
            .catch((errorDetails) => {
                console.warn("Unable to delete member data. Error details: ", errorDetails)
            })
    };

    const handleAddPoints = (pointsAdded: CurrentPointsDTO) => {
        setMemberData(prevMemberData => {
            if (!prevMemberData) return prevMemberData;

            return {
                ...prevMemberData,
                memberPoints: {
                    ...prevMemberData.memberPoints,
                    points: pointsAdded.points,
                    lastUpdated: pointsAdded.statusFrom
                }
            };
        });
    };

    async function loadMemberData() {

        try {
            const data = await getMemberData(token, memberId)
            if (isDefined<Member>(data)) {
                setMemberData(data)
            } else {
                setMemberDataRetrievalError(true)
            }
        } catch (error) {
            console.warn("Error loading member details:", error)
            setMemberDataRetrievalError(true)
        }
    }

    async function loadFormOptions() {
        try {
            const options = await getMemberFormOptions(token!!)
            if (options !== undefined) {
                setMemberFormOptions(options)
            }
        } catch (error) {
            console.warn("Error loading form options:", error)
        }
    }

    useEffect(() => {
        function initialLoad() {
            setIsLoading(true)

            Promise.all([loadMemberData(), loadFormOptions()])
                .finally(() => {
                    setIsLoading(false)
                });
        }

        initialLoad();
    }, [token, memberId]);

    if (isLoading) {
        return (
            <Text>Lade Information</Text>
        )
    }

    if (memberDataRetrievalError) {
        return (
            <Text>Konnte Daten nicht abrufen</Text>
        )
    }

    function formatIBAN(iban: string | undefined) {
        if (isDefined(iban)) {
            const sanitizedIBAN = iban!!.replace(/[^A-Za-z0-9*]/g, "");
            return sanitizedIBAN.replace(/(.{4})/g, "$1 ").trim();
        } else {
            return iban
        }
    }

    function accountOwner(givenAccountOwner: string | undefined): string {
        if (givenAccountOwner !== null) {
            return givenAccountOwner!!
        }

        return memberData?.firstName + " " + memberData?.lastName;
    }


    if (memberData) {
        let companyDisplayName: string = ''
        if (memberData.companyName) {
            companyDisplayName = t("memberDetails.companyPrefix") + ` ${memberData.companyName}`
        }
        let memberDisplayName = generateCompleteMemberName(memberData)


        let memberAddress = ''
        if (memberData.address) {
            memberAddress = `${memberData.address.street} ${memberData.address.houseNumber}, ` +
                `${memberData.address.postcode} ${memberData.address.city}`
        }

        function formatDate(dateString: string) {
            return format(parseISO(dateString), 'dd.MM.yyyy')
        }

        function formatDateTime(dateTimeString: string) {
            return format(parseISO(dateTimeString), 'dd.MM.yyyy HH:mm')
        }

        function calculateAge(birthDate: string | undefined) {
            if (!birthDate) return null;
            const date = parseISO(birthDate);
            return differenceInYears(new Date(), date);
        }

        const age = calculateAge(memberData.dateOfBirth)

        return (

            <>
                <div className="container mx-auto py-6 max-w-4xl space-y-6">
                    <div className={"w-full text-right"}>
                        <MemberActionsButton onAddPoints={handleAddPoints}
                                             onDelete={handleDeleteMember}
                                             memberData={memberData}

                        />

                    </div>
                    <Card className="w-full">
                        <div className="p-6">
                            <div className="relative">
                                <div className="flex flex-col md:flex-row md:items-center gap-6">
                                    <Avatar className="h-24 w-24">
                                        <AvatarFallback>
                                            <User size={48}/>
                                        </AvatarFallback>
                                    </Avatar>
                                    <div className="space-y-2">
                                        <div>
                                            <Heading>{memberDisplayName}</Heading>
                                        </div>
                                        <div className="flex items-center gap-2 text-gray-500 dark:text-gray-400">

                                            <div className="flex items-center gap-1">
                                                <Cake className="h-4 w-4"/>
                                                {memberData.dateOfBirth ?
                                                    <Text>
                                                        {formatDate(memberData.dateOfBirth)}
                                                    </Text> :
                                                    <Text
                                                        className={"italic"}>{t("memberDetails.unknown-detail")}</Text>
                                                }
                                                {age && (
                                                    <>
                                                        <span className="text-gray-300 dark:text-gray-600 mx-1">•</span>
                                                        <Text>{age} <Trans
                                                            i18nKey={"memberDetails.sections.main.ageQuantifier"}/>
                                                        </Text>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button
                                    onClick={() => setIsEditNamesDialogOpen(true)}
                                    className="absolute top-0 right-0 p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full transition-colors"
                                    aria-label={t("memberDetails.buttons.edit.main.ariaLabel")}
                                >
                                    <Pencil className="h-4 w-4 text-gray-500 dark:text-gray-400"/>
                                </button>
                            </div>
                        </div>
                    </Card>

                    <Card>
                        <div className="p-6">
                            <div className="relative mb-6">
                                <div className="text-2xl font-semibold leading-none tracking-tight" role="heading">
                                    <Trans i18nKey={"memberDetails.sections.contacts.heading"}/>
                                </div>
                                <button
                                    onClick={() => setIsEditContactDetailsDialogOpen(true)}
                                    className="absolute top-0 right-0 p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full transition-colors"
                                    aria-label={t("memberDetails.buttons.edit.contact-details")}
                                >
                                    <Pencil className="h-4 w-4 text-gray-500 dark:text-gray-400"/>
                                </button>
                            </div>

                            <div className="space-y-6">
                                <div className="flex items-center gap-3">
                                    <Mail className="h-4 w-4 text-gray-500"/>
                                    {memberData.email ?
                                        <Link type={"email"} href={`mailto:${memberData.email}`}>
                                            <Text>{memberData.email}</Text>
                                        </Link> :
                                        <Text className={"italic"}>{t("memberDetails.unknown-detail")}</Text>
                                    }
                                </div>
                                {memberData.phoneNumbers && memberData.phoneNumbers.length > 0 ?
                                    memberData.phoneNumbers.map((phoneNumber) => (
                                        <PhoneNumberDisplay
                                            key={phoneNumber.id}
                                            id={phoneNumber.id}
                                            type={phoneNumber.type}
                                            number={phoneNumber.number}
                                        />
                                    )) : (
                                        <PhoneNumberDisplay type="UNKNOWN"/>
                                    )
                                }
                                <div className="flex items-center gap-3">
                                    <MapPin className="h-4 w-4 text-gray-500"/>
                                    {memberAddress ?
                                        <Text>{memberAddress}</Text> :
                                        <Text className={"italic"}>{t("memberDetails.unknown-detail")}</Text>
                                    }
                                </div>
                                {companyDisplayName && (
                                    <div className="flex items-center gap-3">
                                        <Building className="h-4 w-4 text-gray-500"/>
                                        <Text>{companyDisplayName}</Text>
                                    </div>
                                )}
                            </div>
                        </div>
                    </Card>

                    <Card>
                        <div className="p-6">
                            <div className="relative mb-6">
                                <div className="text-2xl font-semibold leading-none tracking-tight" role="heading">
                                    <Trans i18nKey={"memberDetails.sections.membership.heading"}/>
                                </div>
                                <button
                                    onClick={() => setIsEditMembershipDialogOpen(true)}
                                    className="absolute top-0 right-0 p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full transition-colors"
                                    aria-label="Anmerkungen bearbeiten"
                                >
                                    <Pencil className="h-4 w-4 text-gray-500 dark:text-gray-400"/>
                                </button>
                            </div>

                            <div className="space-y-6">
                                <div className="flex items-center gap-3">
                                    <Hash className="h-4 w-4 text-gray-500 dark:text-gray-400"/>
                                    <Text><Trans i18nKey={"memberDetails.sections.membership.memberNo"}/>:&nbsp;
                                        {memberData.memberNo}
                                    </Text>
                                </div>
                                <div className="flex items-center gap-3">
                                    <Users className="h-4 w-4 text-gray-500"/>
                                    <Text><Trans i18nKey={"memberDetails.sections.membership.memberSince"}/>: {
                                        memberData.membershipStartDate ?
                                            format(parseISO(memberData.membershipStartDate), 'dd.MM.yyyy') :
                                            <i><Trans i18nKey={"memberDetails.unknown-detail"}/></i>
                                    }</Text>
                                </div>
                                <div className="flex items-center gap-3">
                                    <Activity className="h-4 w-4 text-gray-500 dark:text-gray-400"/>
                                    <Text><Trans i18nKey={"memberDetails.sections.membership.status"}/>:&nbsp;
                                        {memberData.membershipStatus.value}
                                    </Text>
                                </div>
                                {(memberData.memberPoints || true) && (
                                    <div className="flex items-center gap-3">
                                        <Coins className="h-4 w-4 text-gray-500 dark:text-gray-400"/>
                                        <Text><Trans i18nKey={"memberDetails.sections.membership.points"}/>:&nbsp;
                                            {memberData.memberPoints?.points ?? '0'}
                                        </Text>
                                    </div>
                                )}
                            </div>
                        </div>
                    </Card>

                    <Card>
                        <div className="p-6">
                            <div className="relative mb-6">
                                <div className="text-2xl font-semibold leading-none tracking-tight" role="heading">
                                    <Trans i18nKey={"memberDetails.sections.banking.heading"}/>
                                </div>
                                <button
                                    onClick={() => setIsEditBankingDetailsDialogOpen(true)}
                                    className="absolute top-0 right-0 p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full transition-colors"
                                    aria-label={t("memberDetails.buttons.edit.banking.ariaLabel")}
                                >
                                    <Pencil className="h-4 w-4 text-gray-500 dark:text-gray-400"/>
                                </button>
                            </div>

                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                <div className="flex items-center gap-3">
                                    <User className="h-4 w-4 text-gray-500 dark:text-gray-400"/>
                                    <Text><Trans i18nKey={"memberDetails.sections.banking.accountOwner"}/>:&#32;
                                        {accountOwner(memberData.bankingDetails?.accountOwner)}
                                    </Text>
                                </div>
                                <div className="flex items-center gap-3">
                                    <CreditCard className="h-4 w-4 text-gray-500 dark:text-gray-400"/>
                                    <Text><Trans i18nKey={"memberDetails.sections.banking.iban"}/>:&#32;
                                        {formatIBAN(memberData.bankingDetails?.iban)}
                                    </Text>
                                </div>
                                <div className="flex items-center gap-3">
                                    <Banknote className="h-4 w-4 text-gray-500"/>
                                    <Text>
                                        <Trans i18nKey={"memberDetails.sections.banking.bic"}/>:&#32;
                                        {memberData.bankingDetails?.bic ?? t("memberDetails.unknown-detail")}
                                    </Text>
                                </div>
                                <div className="flex items-center gap-3">
                                    <Building className="h-4 w-4 text-gray-500"/>
                                    <Text>
                                        <Trans i18nKey={"memberDetails.sections.banking.bankName"}/>:&#32;
                                        {memberData.bankingDetails?.bankName ?? t("memberDetails.unknown-detail")}
                                    </Text>
                                </div>
                                <div className="flex items-center gap-3">
                                    <FileSignature className="h-4 w-4 text-gray-500 dark:text-gray-400 flex-shrink-0"/>
                                    <Text><Trans i18nKey={"memberDetails.sections.banking.mandateReference"}/>:&#32;
                                        {memberData.bankingDetails?.mandateReference}
                                    </Text>
                                </div>
                                <div className="flex items-center gap-3">
                                    <CalendarDays className="h-4 w-4 text-gray-500 dark:text-gray-400"/>
                                    <Text><Trans i18nKey={"memberDetails.sections.banking.mandateDate"}/>:&#32;
                                        {
                                            memberData.bankingDetails?.mandateDate ?? t("memberDetails.unknown-detail")
                                        }
                                    </Text>
                                </div>
                                <div className="flex items-center gap-3">
                                    <Clock className="h-4 w-4 text-gray-500 dark:text-gray-400"/>
                                    <Text><Trans i18nKey={"memberDetails.sections.banking.updatedAt"}/>:&#32;
                                        {
                                            memberData.bankingDetails?.updatedAt ?
                                                formatDateTime(memberData.bankingDetails?.updatedAt) :
                                                t("memberDetails.unknown-detail")
                                        }
                                    </Text>
                                </div>
                            </div>
                        </div>
                    </Card>

                    <Card>
                        <div className="p-6">
                            <div className="relative mb-6">
                                <div className="text-2xl font-semibold leading-none tracking-tight" role="heading">
                                    <Trans i18nKey={"memberDetails.sections.comments.heading"}/>
                                </div>
                                <button
                                    onClick={() => setIsEditCommentsDialogOpen(true)}
                                    className="absolute top-0 right-0 p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full transition-colors"
                                    aria-label={t("memberDetails.buttons.edit.comments.ariaLabel")}
                                >
                                    <Pencil className="h-4 w-4 text-gray-500 dark:text-gray-400"/>
                                </button>
                            </div>

                            <div className="space-y-6">
                                <div className="space-y-2">
                                    <div className="flex items-center gap-2 text-sm font-medium ">
                                        <MessageSquare className="h-4 w-4"/>
                                        <Subheading level={3}><Trans
                                            i18nKey={"memberDetails.sections.comments.general"}/></Subheading>
                                    </div>
                                    {memberData.generalComments ? (
                                        <Text className="pl-6 whitespace-pre-wrap">
                                            {memberData.generalComments}
                                        </Text>
                                    ) : (
                                        <Text className="italic pl-6">
                                            <Trans i18nKey={"memberDetails.sections.comments.noComments"}/>
                                        </Text>
                                    )}
                                </div>

                                <div className="space-y-2">
                                    <div className="flex items-center gap-2 text-sm font-medium">
                                        <Clock className="h-4 w-4 flex-shrink-0"/>
                                        <Subheading level={3}><Trans
                                            i18nKey={"memberDetails.sections.comments.availability"}/></Subheading>
                                    </div>
                                    {memberData.availabilityComments ? (
                                        <Text className="pl-6 whitespace-pre-wrap">
                                            {memberData.availabilityComments}
                                        </Text>
                                    ) : (
                                        <Text className="italic pl-6">
                                            <Trans i18nKey={"memberDetails.sections.comments.noComments"}/>
                                        </Text>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Card>

                </div>

                <Divider className="my-10" soft/>

                <section className="flex justify-end gap-4">
                    <Button aria-label={t("memberDetails.buttons.back.ariaLabel")} href="/members"
                            role="navigation"><Trans i18nKey="memberDetails.buttons.back.text"/></Button>
                </section>


                {
                    memberData && (
                        <>
                            <MemberEditNamesDialog
                                isOpen={isEditNamesDialogOpen}
                                onSave={setMemberData}
                                onClose={() => setIsEditNamesDialogOpen(false)}
                                memberData={memberData}
                                salutations={memberFormOptions?.salutations}
                            />

                            <MemberEditCommentsDialog
                                isOpen={isEditCommentsDialogOpen}
                                onSave={setMemberData}
                                onClose={() => setIsEditCommentsDialogOpen(false)}
                                memberData={memberData}
                            />

                            <MemberEditContactDetailsDialog
                                isOpen={isEditContactDetailsDialogOpen}
                                onSave={setMemberData}
                                onClose={() => setIsEditContactDetailsDialogOpen(false)}
                                memberData={memberData}
                            />

                            <MemberEditMembershipDialog
                                isOpen={isEditMembershipDialogOpen}
                                onSave={setMemberData}
                                onClose={() => setIsEditMembershipDialogOpen(false)}
                                memberData={memberData}
                                availableStatus={memberFormOptions?.supportedStatus || []}
                            />

                            <MemberEditBankingDetailsDialog
                                isOpen={isEditBankingDetailsDialogOpen}
                                onSave={setMemberData}
                                onClose={() => setIsEditBankingDetailsDialogOpen(false)}
                                onDelete={() => loadMemberData()}
                                memberData={memberData}
                            />
                        </>
                    )
                }

            </>
        )
    }
}
