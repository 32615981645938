import {Trans, useTranslation} from "react-i18next";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {Heading, Subheading} from "@/components/tailwind/heading.tsx";
import {Text} from '@/components/tailwind/text'
import {Input} from "@/components/tailwind/input.tsx";
import {v4 as uuid} from "uuid";
import {useEffect, useState} from "react";
import {
    CreateMemberDTO,
    MemberAddress,
    MemberFormOptions,
    MemberSalutation,
    MembershipStatus,
    PhoneNumber
} from "@/models/member.ts";
import {createNewMember, getMemberFormOptions} from "@/services/member-services.ts";
import {useAuth} from "@/context/use-auth.tsx";
import {Member} from '@/models/member'
import ValidationAlert from "@/components/validation-alert.tsx";
import {useNavigate} from "react-router-dom";
import {ProblemDetail} from "@/types/problem-details.ts";
import {NullableTextInput} from "@/components/nullable-text-input.tsx";
import {sanitizeText} from "@/helpers/sanitizer.ts";
import {validationSchema} from "./member-form/validation-schema.ts";
import {Select} from "@/components/tailwind/select.tsx";
import {Textarea} from "@/components/tailwind/textarea.tsx";

type NewMemberFormInput = {
    id: string,
    firstName: string,
    lastName: string,
    companyName?: string,
    salutation?: MemberSalutation,
    title?: string,
    email?: string,
    dateOfBirth: string,
    address: {
        street: string,
        houseNo?: string,
        postcode: string,
        city: string,
    },
    phoneNumbers: {
        landline?: string,
        mobile?: string,
    }
    membershipStartDate: string,
    membershipStatus: MembershipStatus,
    generalComments?: string,
    availabilityComments?: string,

    bankingDetails?: {
        accountOwner?: string,
        iban?: string,
        bic?: string,
        bankName?: string,
        mandateDate?: string
    }

    // Hacks ahead, for custom validation "fields"
    memberNo?: never,
    memberPoints?: never,
}

// TODO REFACTOR! (https://github.com/steinhauer-software/die-nachbarschaftshilfe-app/issues/66)
function mapPhoneTypeToEnum(ident: string): "MOBILE" | "LANDLINE" {
    switch (ident.toLowerCase()) {
        case "mobile":
            return "MOBILE"
        default:
            return "LANDLINE"
    }
}

export default function NewMemberPage() {

    const {t} = useTranslation()
    const {token} = useAuth()
    const navigate = useNavigate()

    const todaysDateString = new Date().toISOString().split('T')[0]
    const {
        register,
        handleSubmit,
        setError,
        control,
        formState: {errors}
    } = useForm<NewMemberFormInput>({
        resolver: yupResolver(validationSchema()),
        defaultValues: {
            "id": uuid(),
            "membershipStartDate": todaysDateString,
            "bankingDetails": {
                "mandateDate": todaysDateString
            }
        }
    })

    const [isLoading, setIsLoading] = useState<Boolean>(true)

    const [memberFormOptions, setMemberFormOptions] = useState<MemberFormOptions>()

    function mapPhoneNumbers(phoneNumbers: NewMemberFormInput["phoneNumbers"]): PhoneNumber[] {
        const phoneTypes = ["landline", "mobile"] as const;
        return phoneTypes
            .filter(type => phoneNumbers?.[type])
            .map(type => ({
                id: uuid(),
                type: mapPhoneTypeToEnum(type),
                number: phoneNumbers![type]!
            }));
    }

    function mapAddress(address: NewMemberFormInput["address"]): MemberAddress | undefined {
        if (address && Object.keys(address).length === 4 && Object.values(address).every(Boolean)) {
            return {
                id: uuid(),
                street: address.street!,
                houseNumber: address.houseNo!,
                postcode: address.postcode!,
                city: address.city!
            };
        }
        return undefined;
    }

    function mapBankingDetails(formValue: NewMemberFormInput) {
        if (!formValue.bankingDetails || !formValue.bankingDetails?.iban) {
            return undefined
        }

        return {
            accountOwner: formValue.bankingDetails?.accountOwner,
            iban: formValue.bankingDetails?.iban!!,
            bic: formValue.bankingDetails?.bic,
            bankName: formValue.bankingDetails?.bankName,
            mandateDate: formValue.bankingDetails?.mandateDate!!
        };
    }

    function handleSubmission(formValue: NewMemberFormInput) {
        const memberAddr = mapAddress(formValue.address);
        const phoneNumbers = mapPhoneNumbers(formValue.phoneNumbers);

        const memberDto: CreateMemberDTO = {
            id: formValue.id,
            companyName: formValue.companyName,
            firstName: formValue.firstName,
            lastName: formValue.lastName,
            salutationId: formValue.salutation?.id,
            title: formValue.title,
            address: memberAddr,
            dateOfBirth: formValue.dateOfBirth,
            membershipStartDate: formValue.membershipStartDate,
            phoneNumbers: phoneNumbers,
            membershipStatusId: formValue.membershipStatus.id,
            generalComments: sanitizeText(formValue.generalComments),
            availabilityComments: sanitizeText(formValue.availabilityComments),
            email: formValue.email,
            bankingDetails: mapBankingDetails(formValue)
        };

        const response = createNewMember(token!, memberDto);

        response
            .then((_) => {
                navigate("/members");
            })
            .catch((reason: ProblemDetail) => {
                console.warn("An error occurred while performing create new member request", reason);

                if (reason.validationIssues) {
                    reason.validationIssues.map(
                        finding => {
                            setError(
                                finding.field as keyof Member,
                                {
                                    message: finding.message
                                }
                            );
                        }
                    );
                }

                setError(
                    "root.serverError",
                    {
                        type: `${reason.status}`,
                        message: t("serverError.response-code", {statusCode: reason.status})
                    }
                );
            });
    }

    useEffect(() => {
        const fetchOptions = getMemberFormOptions(token!!)

        fetchOptions
            .then((options) => {
                if (options !== undefined) {
                    setMemberFormOptions(options)
                    setIsLoading(false)
                }

            })
            .catch((error) => {
                    console.error("Couldn't load options from the backend. Here is what I know: ", error)
                }
            )

    }, [token])

    if (isLoading) {
        return (
            <div className="flex items-center justify-center h-screen">
                <Heading><Trans i18nKey={"memberForm.loadingMessage"}/></Heading>
            </div>
        )
    }

    return (
        <form onSubmit={handleSubmit(handleSubmission)}>
            <Input
                id="id"
                type="hidden"
                {...register("id")}
            />

            <Heading><Trans i18nKey={"memberForm.mainHeader.new"}/></Heading>

            {errors?.root?.serverError && (
                <ValidationAlert
                    message={errors?.root?.serverError?.message || t("memberForm.errors.unknown-server-error")}/>
            )}

            <div className="mt-10 ml-5 space-y-12">

                <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                    <div>
                        <Subheading className="text-base/7 font-semibold "><Trans
                            i18nKey={"memberForm.sections.personalInfo.heading"}/></Subheading>
                        <Text className="mt-1 text-sm/6">
                            <Trans i18nKey={"memberForm.sections.personalInfo.text"}/>
                        </Text>
                    </div>

                    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">

                        <div className={"sm:col-span-4 grid"}>
                            <div className={"flex items-center space-x-4"}>
                                <Text className={"text-sm/6 font-medium"}><Trans
                                    i18nKey={"memberForm.fields.memberNo.label"}/>:</Text>
                                <Text className={"text-sm/6 font-normal"}><Trans
                                    i18nKey={"memberForm.fields.memberNo.info"}/></Text>
                            </div>
                        </div>

                        <div className={"sm:col-span-3"}>
                            <label htmlFor={"salutation"} className={"block text-sm/6 font-medium text-gray-900"}>
                                <Text><Trans i18nKey={"memberForm.fields.salutation.label"}/></Text>
                            </label>
                            <div className={"mt-2"}>
                                <Controller
                                    name="salutation"
                                    control={control}
                                    render={({field: {onChange, value, ...field}}) => (
                                        <Select
                                            id="salutation"
                                            {...field}
                                            value={value?.id ?? "__NULL__"}
                                            onChange={(e) => {
                                                const selectedId = e.target.value;
                                                onChange(
                                                    selectedId === "__NULL__"
                                                        ? null
                                                        : memberFormOptions?.salutations?.find(s => s.id === selectedId)
                                                );
                                            }}
                                        >
                                            <option value="__NULL__">--</option>
                                            {memberFormOptions?.salutations?.map((s) => (
                                                <option key={s.id} value={s.id}>
                                                    <Trans i18nKey={s.value}/>
                                                </option>
                                            ))}
                                        </Select>
                                    )}
                                />
                                {errors.salutation && (
                                    <ValidationAlert
                                        message={t(errors.salutation?.message || "memberForm.errors.unknown-salutation-issue")}/>
                                )}
                            </div>
                        </div>

                        <div className={"sm:col-span-3"}>
                            <label htmlFor={"title"} className={"block text-sm/6 font-medium text-gray-900"}>
                                <Text><Trans i18nKey={"memberForm.fields.title.label"}/></Text>
                            </label>
                            <div className={"mt-2"}>
                                <NullableTextInput
                                    id={"title"}
                                    control={control}
                                    {...register("title")}
                                    autoComplete="honorific-prefix"
                                    aria-label={t("memberForm.fields.title.text")}
                                    data-invalid={!!errors.title || null}
                                    placeholder={t("memberForm.fields.title.placeholder")}
                                />

                                {errors.title && (
                                    <ValidationAlert
                                        message={t(errors.title?.message || "memberForm.errors.unknown-title-issue")}/>
                                )}
                            </div>
                        </div>

                        <div className="sm:col-span-3">
                            <label htmlFor="first-name" className="block text-sm/6 font-medium text-gray-900">
                                <Text><Trans i18nKey={"memberForm.fields.firstName.label"}/></Text>
                            </label>
                            <div className="mt-2">
                                <Input
                                    id="first-name"
                                    {...register("firstName")}
                                    autoComplete="given-name"
                                    type="text"
                                    aria-label={t("memberForm.fields.firstName.text")}
                                    data-invalid={!!errors.firstName || null}
                                    placeholder={t("memberForm.fields.firstName.placeholder")}
                                />
                                {errors.firstName && (
                                    <ValidationAlert
                                        message={t(errors.firstName?.message || "memberForm.errors.unknown-first-name-issue")}/>
                                )}

                            </div>
                        </div>

                        <div className="sm:col-span-3">
                            <label htmlFor="last-name" className="block text-sm/6 font-medium text-gray-900">
                                <Text><Trans i18nKey={"memberForm.fields.lastName.label"}/> </Text>
                            </label>
                            <div className="mt-2">
                                <Input
                                    id="last-name"
                                    {...register("lastName")}
                                    type="text"
                                    autoComplete="family-name"
                                    aria-label={t("memberForm.fields.lastName.text")}
                                    data-invalid={!!errors.lastName || null}
                                    placeholder={t("memberForm.fields.lastName.placeholder")}
                                />
                                {errors.lastName && (
                                    <ValidationAlert
                                        message={t(errors.lastName?.message || "memberForm.errors.unknown-last-name-issue")}/>
                                )}

                            </div>
                        </div>

                        <div className="sm:col-span-full">
                            <label htmlFor="company-name" className="block text-sm/6 font-medium text-gray-900">
                                <Text><Trans i18nKey={"memberForm.fields.companyName.label"}/> </Text>
                            </label>
                            <div className="mt-2">
                                <Input
                                    id="company-name"
                                    {...register("companyName")}
                                    type="text"
                                    autoComplete="organization"
                                    aria-label={t("memberForm.fields.companyName.text")}
                                    data-invalid={!!errors.companyName || null}
                                    placeholder={t("memberForm.fields.companyName.placeholder")}
                                />
                                {errors.companyName && (
                                    <ValidationAlert
                                        message={t(errors.companyName?.message || "memberForm.errors.unknown-company-name-issue")}/>
                                )}
                            </div>
                        </div>

                        <div className="sm:col-span-4">
                            <label htmlFor="date-of-birth" className="block text-sm/6 font-medium text-gray-900">
                                <Text><Trans i18nKey={"memberForm.fields.dateOfBirth.label"}/></Text>
                            </label>
                            <div className="mt-2">
                                <Controller
                                    name="dateOfBirth"
                                    control={control}
                                    render={({field}) => (
                                        <Input
                                            id="date-of-birth"
                                            type="date"
                                            autoComplete="bday"
                                            max={new Date().toISOString().split('T')[0]}
                                            onChange={(e) => {
                                                const value = e.target.value || null;
                                                field.onChange(value);
                                            }}
                                            aria-label={t("memberForm.fields.dateOfBirth.label")}
                                            data-invalid={!!errors.dateOfBirth || null}
                                        />
                                    )}
                                />

                                {errors.dateOfBirth && (
                                    <ValidationAlert
                                        message={t(errors.dateOfBirth?.message || "memberForm.errors.unknown-birthday-issue")}/>
                                )}

                            </div>
                        </div>

                    </div>
                </div>

                {/* Adresse */}
                <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                    <div>
                        <Subheading className="text-base/7 font-semibold ">
                            <Trans i18nKey={"memberForm.sections.addresses.heading"}/>
                        </Subheading>
                        <Text className="mt-1 text-sm/6">
                            <Trans i18nKey={"memberForm.sections.addresses.text"}/>
                        </Text>

                        {errors.address &&
                            <ValidationAlert
                                message={t(errors.address?.root?.message ||
                                    "memberForm.errors.unknown-address-details-error")}/>
                        }
                    </div>

                    <div data-invalid={!!errors.address}
                         className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2"
                    >
                        {errors.address && (
                            <div className="sm:col-span-5 sm:col-start-1">
                                {[
                                    errors.address?.street,
                                    errors.address?.houseNo,
                                    errors.address?.postcode,
                                    errors.address?.city,
                                ].map((error, index) => (
                                    <div key={index} className="min-h-[40px]">
                                        {error && (
                                            <ValidationAlert
                                                message={t(
                                                    error?.message ||
                                                    "memberForm.errors.unknown-address-error"
                                                )}
                                            />
                                        )}
                                    </div>
                                ))}
                            </div>
                        )}

                        <div className="sm:col-span-4 sm:col-start-1">
                            <label htmlFor="street" className="block text-sm/6 font-medium text-gray-900">
                                <Text><Trans i18nKey={"memberForm.fields.address.street.label"}/></Text>
                            </label>
                            <div className="mt-2">
                                <Input
                                    id="street"
                                    {...register("address.street")}
                                    type="text"
                                    aria-label={t("memberForm.fields.address.street.label")}
                                    data-invalid={!!errors.address?.street || null}
                                    placeholder={t("memberForm.fields.address.street.placeholder")}
                                    autoComplete="street-address"
                                />
                            </div>
                        </div>

                        <div className="col-span-1">
                            <label htmlFor="house-no" className="block text-sm/6 font-medium text-gray-900">
                                <Text><Trans i18nKey={"memberForm.fields.address.houseNo.label"}/></Text>
                            </label>
                            <div className="mt-2">
                                <Input
                                    id="house-no"
                                    {...register("address.houseNo")}
                                    type="text"
                                    aria-label={t("memberForm.fields.address.houseNo.label")}
                                    data-invalid={!!errors.address?.houseNo || null}
                                    placeholder={t("memberForm.fields.address.houseNo.placeholder")}
                                />

                            </div>
                        </div>

                        <div className="sm:col-span-1 sm:col-start-1">
                            <label htmlFor="postcode" className="block text-sm/6 font-medium text-gray-900">
                                <Text><Trans i18nKey={"memberForm.fields.address.postcode.label"}/></Text>
                            </label>
                            <div className="mt-2">
                                <Input
                                    id="postcode"
                                    {...register("address.postcode")}
                                    type="text"
                                    aria-label={t("memberForm.fields.address.postcode.label")}
                                    data-invalid={!!errors.address?.postcode || null}
                                    placeholder={t("memberForm.fields.address.postcode.placeholder")}
                                    autoComplete="postal-code"
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-3">
                            <label htmlFor="city" className="block text-sm/6 font-medium text-gray-900">
                                <Text><Trans i18nKey={"memberForm.fields.address.city.label"}/></Text>
                            </label>
                            <div className="mt-2">
                                <Input
                                    id="city"
                                    {...register("address.city")}
                                    type="text"
                                    aria-label={t("memberForm.fields.address.city.label")}
                                    data-invalid={!!errors.address?.city || null}
                                    placeholder={t("memberForm.fields.address.city.placeholder")}
                                    autoComplete="locality"
                                />
                            </div>
                        </div>
                    </div>
                </div>


                <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                    <div>
                        <Subheading className="text-base/7 font-semibold ">
                            <Trans i18nKey={"memberForm.sections.contact.heading"}/>
                        </Subheading>
                        <Text className="mt-1 text-sm/6">
                            <Trans i18nKey={"memberForm.sections.contact.text"}/>
                        </Text>

                        {errors?.phoneNumbers && (
                            <ValidationAlert
                                message={t(errors?.phoneNumbers?.message || errors?.phoneNumbers?.root?.message ||
                                    "memberForm.errors.invalid-phone-numbers")}/>
                        )}

                    </div>

                    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">

                        <div className="sm:col-span-4">
                            <label htmlFor="email" className="block text-sm/6 font-medium text-gray-900">
                                <Text><Trans i18nKey={"memberForm.fields.email.label"}/></Text>
                            </label>
                            <div className="mt-2">
                                <Input
                                    id="email"
                                    {...register("email")}
                                    type="email"
                                    autoComplete="email"
                                    aria-label={t("memberForm.fields.email.label")}
                                    data-invalid={!!errors.email || null}
                                    placeholder={t("memberForm.fields.email.placeholder")}
                                />
                                {errors.email &&
                                    <ValidationAlert
                                        message={t(errors.email?.message || "memberForm.errors.unknown-email-error")}/>}

                            </div>
                        </div>

                        <div className="sm:col-span-4 sm:col-start-1">
                            <label htmlFor="phone-landline" className="block text-sm/6 font-medium text-gray-900">
                                <Text><Trans i18nKey={"memberForm.fields.phone.landline.label"}/></Text>
                            </label>
                            <div className="mt-2">
                                <Input
                                    id="phone-landline"
                                    {...register("phoneNumbers.landline")}
                                    type="tel"
                                    aria-label={t("memberForm.fields.phone.landline.label")}
                                    data-invalid={!!errors.phoneNumbers?.landline || !!errors?.phoneNumbers || null}
                                    placeholder={t("memberForm.fields.phone.landline.placeholder")}
                                    autoComplete="home tel"
                                />
                                {errors.phoneNumbers?.landline &&
                                    <ValidationAlert
                                        message={t(errors.phoneNumbers?.landline?.message ||
                                            "memberForm.errors.unknown-phone-landline-error")}/>}

                            </div>
                        </div>

{/*
                        <div className="sm:col-span-4 sm:col-start-1">
                            <label htmlFor="phone-business" className="block text-sm/6 font-medium text-gray-900">
                                <Text><Trans i18nKey={"memberForm.fields.phone.business.label"}/></Text>
                            </label>
                            <div className="mt-2">
                                <Input
                                    id="phone-business"
                                    {...register("phoneNumbers.business")}
                                    type="tel"
                                    aria-label={t("memberForm.fields.phone.business.label")}
                                    data-invalid={!!errors.phoneNumbers?.business || !!errors?.phoneNumbers || null}
                                    placeholder={t("memberForm.fields.phone.business.placeholder")}
                                    autoComplete="work tel"
                                />
                                {errors.phoneNumbers?.business &&
                                    <ValidationAlert
                                        message={t(errors.phoneNumbers?.business?.message ||
                                            "memberForm.errors.unknown-phone-business-error")}/>}

                            </div>
                        </div>
*/}
                        <div className="sm:col-span-4 sm:col-start-1">
                            <label htmlFor="phone-mobile" className="block text-sm/6 font-medium text-gray-900">
                                <Text><Trans i18nKey={"memberForm.fields.phone.mobile.label"}/></Text>
                            </label>
                            <div className="mt-2">
                                <Input
                                    id="phone-mobile"
                                    {...register("phoneNumbers.mobile")}
                                    type="tel"
                                    aria-label={t("memberForm.fields.phone.mobile.label")}
                                    data-invalid={!!errors.phoneNumbers?.mobile || !!errors?.phoneNumbers || null}
                                    placeholder={t("memberForm.fields.phone.mobile.placeholder")}
                                    autoComplete="home tel"
                                />
                                {errors.phoneNumbers?.mobile &&
                                    <ValidationAlert
                                        message={t(errors.phoneNumbers?.mobile?.message ||
                                            "memberForm.errors.unknown-phone-mobile-error")}/>}

                            </div>
                        </div>
{/*
                        <div className="sm:col-span-4 sm:col-start-1">
                            <label htmlFor="phone-fax" className="block text-sm/6 font-medium text-gray-900">
                                <Text><Trans i18nKey={"memberForm.fields.phone.fax.label"}/></Text>
                            </label>
                            <div className="mt-2">
                                <Input
                                    id="phone-fax"
                                    {...register("phoneNumbers.fax")}
                                    type="tel"
                                    aria-label={t("memberForm.fields.phone.fax.label")}
                                    data-invalid={!!errors.phoneNumbers?.fax || null}
                                    placeholder={t("memberForm.fields.phone.fax.placeholder")}
                                    autoComplete="fax"
                                />
                            </div>
                            {errors.phoneNumbers?.fax &&
                                <ValidationAlert
                                    message={t(errors.phoneNumbers?.fax?.message ||
                                        "memberForm.errors.unknown-phone-fax-error")}/>}
                        </div>
*/}

                    </div>
                </div>

                <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                    <div>
                        <Subheading className="text-base/7 font-semibold text-gray-900">
                            <Trans i18nKey={"memberForm.sections.membership.heading"}/>
                        </Subheading>
                        <p className="mt-1 text-sm/6 text-gray-600">
                            <Trans i18nKey={"memberForm.sections.membership.text"}/>
                        </p>
                    </div>

                    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">

                        <div className="sm:col-span-4">
                            <label htmlFor="membership-start-date"
                                   className="block text-sm/6 font-medium text-gray-900">
                                <Text><Trans i18nKey={"memberForm.fields.membershipStart.label"}/></Text>
                            </label>
                            <div className="mt-2">

                                <Controller
                                    name="membershipStartDate"
                                    control={control}
                                    render={({ field }) => (
                                        <Input
                                            id="membership-start-date"
                                            type="date"
                                            value={field.value || ''}
                                            onChange={(e) => {
                                                const value = e.target.value || '';
                                                field.onChange(value);
                                            }}
                                            aria-label={t("memberForm.fields.membershipStart.label")}
                                            data-invalid={!!errors.membershipStartDate || null}
                                        />
                                    )}
                                />

                                {errors.membershipStartDate &&
                                    <ValidationAlert
                                        message={t(errors.membershipStartDate?.message ||
                                            "memberForm.errors.unknown-membership-start-error")}/>
                                }

                            </div>
                            <div className="mt-2">
                                <label htmlFor="membershop-status"
                                       className="block text-sm/6 font-medium text-gray-900">
                                    <Text><Trans i18nKey={"memberForm.fields.membershipStatus.label"}/></Text>
                                </label>
                                <div className={"mt-2"}>
                                    <Controller
                                        name="membershipStatus"
                                        control={control}
                                        rules={{required: true}}
                                        defaultValue={memberFormOptions?.supportedStatus?.[0]}
                                        render={({field}) => (
                                            <Select
                                                id="membership-status"
                                                value={field.value?.id}
                                                onChange={e => {
                                                    const id = e.target.value;
                                                    field.onChange(memberFormOptions?.supportedStatus?.find(s => s.id === id));
                                                }}
                                            >
                                                {memberFormOptions?.supportedStatus?.map(item => (
                                                    <option key={item.id} value={item.id}>
                                                        {item.value}
                                                    </option>
                                                ))}
                                            </Select>
                                        )}
                                    />
                                    {errors.membershipStatus &&
                                        <ValidationAlert
                                            message={t(errors.membershipStatus?.message || "memberForm.errors.unknown-membership-status-error")}/>}

                                </div>
                            </div>
                        </div>
                    </div>

                </div>


                <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                    <div>
                        <Subheading className="text-base/7 font-semibold ">
                            <Trans i18nKey={"memberForm.sections.banking.heading"}/>
                        </Subheading>
                        <Text className="mt-1 text-sm/6">
                            <Trans i18nKey={"memberForm.sections.banking.text"}/>
                        </Text>
                    </div>


                    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">

                        <div className="sm:col-span-4">
                            <label htmlFor="account-owner" className="block text-sm/6 font-medium text-gray-900">
                                <Text><Trans i18nKey={"memberForm.fields.banking.accountOwner.label"}/></Text>
                            </label>
                            <div className="mt-2">
                                <Input
                                    id="account-owner"
                                    {...register("bankingDetails.accountOwner")}
                                    type="text"
                                    autoComplete="name"
                                    aria-label={t("memberForm.fields.banking.accountOwner.ariaLabel")}
                                    data-invalid={!!errors.bankingDetails?.accountOwner || null}
                                    placeholder={t("memberForm.fields.banking.accountOwner.placeholder")}
                                />
                                {errors.bankingDetails?.accountOwner &&
                                    <ValidationAlert
                                        message={t(errors.bankingDetails?.accountOwner?.message ||
                                            "memberForm.errors.unknown-banking-account-owner-error")}
                                    />
                                }

                            </div>
                        </div>

                        <div className="sm:col-span-4">
                            <label htmlFor="iban" className="block text-sm/6 font-medium text-gray-900">
                                <Text><Trans i18nKey={"memberForm.fields.banking.iban.label"}/></Text>
                            </label>
                            <div className="mt-2">
                                <Input
                                    id="iban"
                                    {...register("bankingDetails.iban")}
                                    type="text"
                                    autoComplete="iban"
                                    aria-label={t("memberForm.fields.banking.iban.ariaLabel")}
                                    data-invalid={!!errors.bankingDetails?.iban || !!errors.bankingDetails || null}
                                    placeholder={t("memberForm.fields.banking.iban.placeholder")}
                                />
                                {errors.bankingDetails?.iban &&
                                    <ValidationAlert
                                        message={t(errors.bankingDetails?.iban?.message ||
                                            "memberForm.errors.unknown-banking-iban-error")}
                                    />
                                }

                            </div>
                        </div>

                        <div className="sm:col-span-4">
                            <label htmlFor="bic" className="block text-sm/6 font-medium text-gray-900">
                                <Text><Trans i18nKey={"memberForm.fields.banking.bic.label"}/></Text>
                            </label>
                            <div className="mt-2">
                                <Input
                                    id="bic"
                                    {...register("bankingDetails.bic")}
                                    type="text"
                                    autoComplete="bic"
                                    aria-label={t("memberForm.fields.banking.bic.ariaLabel")}
                                    data-invalid={!!errors.bankingDetails?.bic || null}
                                    placeholder={t("memberForm.fields.banking.bic.placeholder")}
                                />
                                {errors.bankingDetails?.bic &&
                                    <ValidationAlert
                                        message={t(errors.bankingDetails?.bic?.message ||
                                            errors.bankingDetails?.root?.message ||
                                            "memberForm.errors.unknown-banking-bic-error")}
                                    />
                                }

                            </div>
                        </div>

                        <div className="sm:col-span-4">
                            <label htmlFor="bank-name" className="block text-sm/6 font-medium text-gray-900">
                                <Text><Trans i18nKey={"memberForm.fields.banking.bankName.label"}/></Text>
                            </label>
                            <div className="mt-2">
                                <Input
                                    id="bank-name"
                                    {...register("bankingDetails.bankName")}
                                    type="text"
                                    aria-label={t("memberForm.fields.banking.bankName.ariaLabel")}
                                    data-invalid={!!errors.bankingDetails?.bankName || null}
                                    placeholder={t("memberForm.fields.banking.bankName.placeholder")}
                                />
                                {errors.bankingDetails?.bankName &&
                                    <ValidationAlert
                                        message={t(errors.bankingDetails?.bankName?.message ||
                                            errors.bankingDetails?.root?.message ||
                                            "memberForm.errors.unknown-banking-bank-name-error")}
                                    />
                                }

                            </div>
                        </div>

                        <div className="sm:col-span-4">
                            <label htmlFor="mandate-date"
                                   className="block text-sm/6 font-medium text-gray-900">
                                <Text><Trans i18nKey={"memberForm.fields.banking.mandateDate.label"}/></Text>
                            </label>
                            <div className="mt-2">

                                <Controller
                                    name="bankingDetails.mandateDate"
                                    control={control}
                                    render={({field}) => (
                                        <Input
                                            id="mandate-date"
                                            type="date"
                                            value={field.value || ''}
                                            onChange={(e) => {
                                                const value =
                                                    e.target.value || null;
                                                field.onChange(value);
                                            }}
                                            aria-label={t("memberForm.fields.banking.mandateDate.label")}
                                            data-invalid={!!errors.bankingDetails?.mandateDate || null}
                                        />
                                    )}
                                />

                                {errors.bankingDetails?.mandateDate &&
                                    <ValidationAlert
                                        message={t(errors.bankingDetails?.mandateDate?.message ||
                                            "memberForm.errors.unknown-banking-mandate-date-error")}/>
                                }

                            </div>
                        </div>

                    </div>

                </div>

                <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                    <div>
                        <Subheading className="text-base/7 font-semibold ">
                            <Trans i18nKey={"memberForm.sections.comments.heading"}/>
                        </Subheading>
                        <Text className="mt-1 text-sm/6">
                            <Trans i18nKey={"memberForm.sections.comments.text"}/>
                        </Text>
                    </div>

                    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">

                        <div className="sm:col-span-full">
                            <label htmlFor="comments-general" className="block text-sm/6 font-medium text-gray-900">
                                <Text><Trans i18nKey={"memberForm.fields.comments.general.label"}/></Text>
                            </label>
                            <div className="mt-2">
                                <Textarea
                                    id="comments-general"
                                    {...register("generalComments")}
                                    aria-label={t("memberForm.fields.comments.general.label")}
                                    aria-invalid={!!errors.generalComments}
                                    placeholder={t("memberForm.fields.comments.general.placeholder")}
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-full">
                            <label htmlFor="ncomments-availability"
                                   className="block text-sm/6 font-medium text-gray-900">
                                <Text><Trans i18nKey={"memberForm.fields.comments.availability.label"}/></Text>
                            </label>
                            <div className="mt-2">
                                <Textarea
                                    id="comments-availability"
                                    {...register("availabilityComments")}
                                    aria-label={t("memberForm.fields.comments.availability.label")}
                                    aria-invalid={!!errors.availabilityComments}
                                    placeholder={t("memberForm.fields.comments.availability.placeholder")}
                                />
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className="mt-6 flex items-center justify-end gap-x-6">
                <button type="button"
                        className="text-sm/6 font-semibold text-gray-900"
                        aria-label={t("memberForm.buttons.cancel.ariaLabel")}
                >
                    <Trans i18nKey={"memberForm.buttons.cancel.title"}/>
                </button>
                <button
                    type="submit"
                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    aria-label={t("memberForm.buttons.submit.ariaLabel")}
                >
                    <Trans i18nKey={"memberForm.buttons.submit.title"}/>
                </button>
            </div>
        </form>
    )
}
