import {Controller, useForm} from 'react-hook-form';
import {z} from 'zod';
import {zodResolver} from '@hookform/resolvers/zod';
import {Trans, useTranslation} from 'react-i18next';
import {Dialog, DialogActions, DialogBody, DialogTitle} from '@/components/tailwind/dialog';
import {Button} from '@/components/tailwind/button';
import {Input} from '@/components/tailwind/input';
import {Select} from '@/components/tailwind/select';
import {Member, MemberSalutation, MemberUpdateBasicInfoRequest} from "@/models/member.ts";
import ValidationAlert from "@/components/validation-alert.tsx";
import {Field, Label} from "@/components/tailwind/fieldset.tsx";
import {updateBasicMemberInformation} from "@/services/member-services.ts";
import {useAuth} from "@/context/use-auth.tsx";
import {useEffect, useMemo} from "react";

interface MemberEditNamesDialogProps {
    isOpen: boolean;
    onSave: (data: Member) => void;
    onClose: () => void;
    salutations?: MemberSalutation[];
    memberData: Member;
}

const memberEditNameSchema = z.object({
    salutation: z.object({
        id: z.string().uuid(),
        value: z.string()
    })
        .nullable()
        .optional(),
    firstName: z.string()
        .trim()
        .min(1, 'memberEditDialog.names.errors.missing-firstName'),
    lastName: z.string()
        .trim()
        .min(1, 'memberEditDialog.names.errors.lastName.required'),
    title: z.string()
        .trim()
        .nullable()
        .transform(val => (!val ? undefined : val))
        .optional(),
    dateOfBirth: z.string()
        .min(1, 'memberEditDialog.names.errors.dateOfBirth.required')
        .refine((value): value is string => {
            const date = new Date(value);
            return !isNaN(date.getTime());
        }, 'memberEditDialog.names.errors.dateOfBirth.invalid')
        .refine(
            (date): boolean => new Date(date) < new Date(),
            'memberEditDialog.names.errors.dateOfBirth.future'
        )
});

export type MemberEditNamesFormData = z.infer<typeof memberEditNameSchema>;

export default function MemberEditNamesDialog({
                                                  isOpen,
                                                  onSave,
                                                  onClose,
                                                  salutations,
                                                  memberData,
                                              }: MemberEditNamesDialogProps) {
    const {t} = useTranslation();
    const defaultValues = useMemo(() => ({
        firstName: memberData.firstName,
        lastName: memberData.lastName,
        salutation: memberData.salutation,
        title: memberData.title,
        dateOfBirth: memberData.dateOfBirth
    }), [
        memberData.firstName,
        memberData.lastName,
        memberData.salutation,
        memberData.title,
        memberData.dateOfBirth
    ]);

    const {
        register,
        handleSubmit,
        control,
        setError,
        formState: {errors},
        reset
    } = useForm<MemberEditNamesFormData>({
        resolver: zodResolver(memberEditNameSchema),
        defaultValues
    });

    useEffect(() => {
        if (isOpen && memberData) {
            reset(defaultValues);
        }
    }, [isOpen, memberData, reset]);

    const {token} = useAuth();

    async function saveData(data: MemberEditNamesFormData): Promise<boolean> {
        if (memberData.membershipStartDate) {
            const dateOfBirth = new Date(data.dateOfBirth);
            const membershipStartDate = new Date(memberData.membershipStartDate)

            if (membershipStartDate <= dateOfBirth) {
                setError(
                    "dateOfBirth",
                    {
                        type: "validate",
                        message: "memberEditDialog.names.errors.dateOfBirth-before-membershipStartDate",
                    }
                )

                return false
            }
        }

        const basicData: MemberUpdateBasicInfoRequest = {
            memberId: memberData.id,
            firstName: data.firstName,
            lastName: data.lastName,
            title: data.title,
            dateOfBirth: data.dateOfBirth,
            salutationId: data.salutation?.id
        }


        try {
            const response = await updateBasicMemberInformation(token, basicData);
            if (response) {
                onSave(response)
            }
            return true
        } catch (error: any) {
            console.error("Error updating member: ", error);
            if (Boolean(error.status)) {
                setError("root", {
                    message: t("serverError.response-code-with-details", {
                        statusCode: error.status,
                        errorDetails: error.error
                    })
                });
            } else {
                setError("root", {message: t("serverError.unknown-with-details", {errorDetails: error.message})});
            }

            return false;
        }

    }

    async function onSubmit(data: MemberEditNamesFormData) {
        if (await saveData(data)) {
            handleClosing();
        }
    }

    function handleClosing() {
        reset();
        onClose();
    }

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle>
                <Trans i18nKey="memberEditDialog.names.title"/>
            </DialogTitle>

            <DialogBody>
                {errors.root && (
                    <ValidationAlert message={errors.root?.message ||
                        t("memberEditDialog.names.errors.unknown-server-issue")}/>
                )}
                <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
                    <div>
                        <Field>
                            <Label><Trans i18nKey="memberEditDialog.names.fields.salutation.label"/></Label>
                            <Controller
                                name="salutation"
                                control={control}
                                render={({field: {onChange, value, ...field}}) => (
                                    <Select
                                        {...field}
                                        value={value?.id ?? "__NULL__"}
                                        onChange={(e) => {
                                            const selectedId = e.target.value;
                                            onChange(
                                                selectedId === "__NULL__"
                                                    ? null
                                                    : salutations?.find(s => s.id === selectedId)
                                            );
                                        }}
                                        invalid={Boolean(errors.salutation)}
                                        aria-label={t("memberEditDialog.names.fields.salutation.ariaLabel")}
                                    >
                                        <option value="__NULL__">--</option>
                                        {salutations?.map((s) => (
                                            <option key={s.id} value={s.id}>
                                                <Trans i18nKey={s.value}/>
                                            </option>
                                        ))}
                                    </Select>
                                )}
                            />
                        </Field>
                        {errors.salutation && (
                            <ValidationAlert
                                message={t(errors.salutation?.message ||
                                    "memberEditDialog.names.errors.unknown-salutation-issue")}
                            />
                        )}
                    </div>

                    <div>
                        <Field>
                            <Label><Trans i18nKey="memberEditDialog.names.fields.title.label"/></Label>
                            <Input
                                type="text"
                                id="title"
                                autoComplete={"honorific-prefix"}
                                {...register('title')}
                                invalid={Boolean(errors.title)}
                                aria-label={t("memberEditDialog.names.fields.title.ariaLabel")}
                            />

                            {errors.title && (
                                <ValidationAlert message={t(errors.title?.message ||
                                    "memberEditDialog.names.errors.unknown-title-issues")}/>
                            )}
                        </Field>
                    </div>

                    <div>
                        <Field>
                            <Label>
                                <Trans i18nKey="memberEditDialog.names.fields.firstName.label"/>
                            </Label>
                            <Input
                                type="text"
                                id="firstName"
                                autoComplete={"given-name"}
                                {...register('firstName')}
                                invalid={Boolean(errors.firstName)}
                                aria-label={t("memberEditDialog.names.fields.firstName.ariaLabel")}
                            />
                            {errors.firstName && (
                                <ValidationAlert
                                    message={t(errors.firstName?.message ||
                                        "memberEditDialog.names.errors.unknown-firstName-issues")}
                                />
                            )}
                        </Field>
                    </div>

                    <div>
                        <Field>
                            <Label>
                                <Trans i18nKey="memberEditDialog.names.fields.lastName.label"/>
                            </Label>
                            <Input
                                type="text"
                                id="lastName"
                                {...register('lastName')}
                                invalid={Boolean(errors.lastName)}
                                aria-label={t("memberEditDialog.names.fields.lastName.ariaLabel")}
                            />
                            {errors.lastName && (
                                <ValidationAlert
                                    message={t(errors.lastName?.message ||
                                        "memberEditDialog.names.errors.unknown-lastName-issue")}
                                />
                            )}
                        </Field>
                    </div>

                    <div>
                        <Field>
                            <Label>
                                <Trans i18nKey="memberEditDialog.names.fields.dateOfBirth.label"/>
                            </Label>

                            <Controller
                                name="dateOfBirth"
                                control={control}
                                render={({field}) => (
                                    <Input
                                        type="date"
                                        autoComplete="bday"
                                        value={field.value || ''}
                                        max={new Date().toISOString().split('T')[0]}
                                        onChange={(e) => {
                                            const value = e.target.value || null;
                                            field.onChange(value);
                                        }}
                                        aria-label={t("memberEditDialog.names.fields.dateOfBirth.label")}
                                        invalid={Boolean(errors.dateOfBirth)}
                                    />
                                )}
                            />
                            {errors.dateOfBirth && (
                                <ValidationAlert
                                    message={t(errors.dateOfBirth?.message ||
                                        "memberEditDialog.names.errors.unknown-dateOfBirth-issue")}
                                />
                            )}
                        </Field>
                    </div>

                    <DialogActions>
                        <Button
                            type="button"
                            plain
                            onClick={handleClosing}
                            aria-label={t("memberEditDialog.names.buttons.cancel.ariaLabel")}
                        >
                            <Trans i18nKey="memberEditDialog.names.buttons.cancel.text"/>
                        </Button>
                        <Button
                            type="submit"
                            aria-label={t("memberEditDialog.names.buttons.save.ariaLabel")}
                        >
                            <Trans i18nKey="memberEditDialog.names.buttons.save.text"/>
                        </Button>
                    </DialogActions>
                </form>
            </DialogBody>
        </Dialog>
    );
}
