import {Phone, Smartphone} from 'lucide-react';
import {Text} from '@/components/tailwind/text';
import {Trans, useTranslation} from "react-i18next";
import {Link} from "@/components/tailwind/link.tsx";
import {memo} from 'react';

interface PhoneNumberProps {
    id?: string;
    type: "MOBILE" | "LANDLINE" | "UNKNOWN";
    number?: string;
}

function PhoneNumberDisplay({type, number}: PhoneNumberProps) {
    const {t} = useTranslation();

    function getTypeText(type: "MOBILE" | "LANDLINE" | "UNKNOWN"): string {
        return t(`phoneNumberDisplay.type.${type}`);
    }

    function phoneIconForType(type: "MOBILE" | "LANDLINE" | "UNKNOWN") {
        const iconProps = {
            className: "h-4 w-4 text-gray-500",
            "aria-hidden": true,
        };

        switch (type) {
            case "MOBILE":
                return <Smartphone {...iconProps} />;
            default:
                return <Phone {...iconProps} />;
        }
    }

    function getDetailedDescription(type: "MOBILE" | "LANDLINE" | "UNKNOWN") {
        return t(`phoneNumberDisplay.detailedDescription.${type}`);
    }

    return (
        <div
            className="flex items-center gap-3"
            role="group"
            aria-label={number ? `${getTypeText(type)}: ${number}` : getTypeText(type)}
        >
            <div
                className="relative"
                role="img"
                aria-label={getTypeText(type)}
            >
                {phoneIconForType(type)}
                <span className="sr-only">{getTypeText(type)}</span>
            </div>

            {number && (
                <Link href={`tel:${number}`}><Text>{number}</Text></Link>
            )}

            {!number && (
                <Text className="italic">
                    <Trans i18nKey="phoneNumberDisplay.no-number" />
                </Text>
            )}

            <div className="sr-only">
                {getDetailedDescription(type)}
            </div>
        </div>
    );
}

// DisplayName für Debug-Zwecke setzen
PhoneNumberDisplay.displayName = 'PhoneNumberDisplay';

// Komponente mit memo wrappen und exportieren
export default memo(PhoneNumberDisplay);
