import {memberColumns} from "./member-columns.tsx"
import {Heading} from "@/components/tailwind/heading.tsx";
import {Trans} from "react-i18next";
import {useAuth} from "@/context/use-auth.tsx";
import {useEffect, useState} from "react";
import {MemberListItem} from "@/models/member.ts"
import {getMembers} from "@/services/member-services.ts";
import {Divider} from "@/components/tailwind/divider.tsx";
import {Button} from "@/components/tailwind/button.tsx";
import {t} from "i18next";
import {MemberDataTable} from "@/pages/members/member-data-table.tsx";

export default function MemberListPage() {
    const {token} = useAuth()
    const [members, setMembers] = useState<MemberListItem[]>([])

    useEffect(() => {
        const membersFromServer = getMembers(token!!)

        membersFromServer.then((members) => setMembers(members as MemberListItem[]))
    }, [])

    return (
        <>
            <Heading level={2}><Trans i18nKey="memberList.mainHeader"/></Heading>
            <div className="container mx-auto py-10">
                <MemberDataTable columns={memberColumns} data={members}/>
            </div>


            <Divider className="my-5" soft/>

            <section className="flex justify-end gap-4">
                <Button aria-label={t("memberList.newMemberButton.ariaLabel")} href="/members/new">
                    <Trans i18nKey="memberList.newMemberButton.title"/>
                </Button>
            </section>
        </>
    )
}
