import {Fragment} from "react";
import {Input, InputGroup} from "@/components/tailwind/input.tsx";
import {FunnelIcon, MagnifyingGlassIcon, XMarkIcon} from "@heroicons/react/16/solid";
import {Trans, useTranslation} from "react-i18next";
import {Check} from "lucide-react";
import {Menu, MenuButton, MenuItem, MenuItems, Transition} from "@headlessui/react";
import {Button} from "@/components/tailwind/button.tsx";

export interface ServiceFilters {
    isFixedPoints?: boolean;
    isSpecialAssignment?: boolean;
}

function ServiceSearchFilter({
                                 searchTerm,
                                 filters,
                                 onSearchChange,
                                 onFilterChange,
                             }: {
    searchTerm: string;
    filters: ServiceFilters;
    onSearchChange: (searchTerm: string) => void;
    onFilterChange: (filters: ServiceFilters) => void;
}) {
    const {t} = useTranslation();

    const activeFilterCount = Object.values(filters).filter(Boolean).length;
    const hasActiveFiltersOrSearch = activeFilterCount > 0 || searchTerm.trim() !== '';

    function handleFilterToggle(key: keyof ServiceFilters) {
        const newFilters = {...filters};

        if (newFilters[key]) {
            delete newFilters[key];
        } else {
            newFilters[key] = true;
        }

        onFilterChange(newFilters);
    }

    function clearAllFilters() {
        onFilterChange({});
        onSearchChange('');
    }

    const filterOptions = [
        {key: 'isFixedPoints', label: t("servicesList.filter.isFixedPoints")},
        {key: 'isSpecialAssignment', label: t("servicesList.filter.isSpecialAssignment")},
    ];

    return (
        <div className="w-full relative mb-6 flex justify-end">
            <div className="flex gap-2 max-w-md items-center">
                {hasActiveFiltersOrSearch && (
                    <Button
                        plain
                        onClick={clearAllFilters}
                        className="hover:text-red-600 dark:hover:text-red-400 flex items-center gap-1 text-xs font-normal whitespace-nowrap"
                    >
                        <XMarkIcon className="h-3.5 w-3.5"/>
                        <Trans i18nKey="servicesList.filter.resetAll"/>
                    </Button>
                )}

                <InputGroup className="flex-grow">
                    <MagnifyingGlassIcon/>
                    <Input
                        type="text"
                        value={searchTerm}
                        onChange={(e) => onSearchChange(e.target.value)}
                        placeholder={t("servicesList.search.placeholder")}
                        aria-label={t("servicesList.search.ariaLabel")}
                    />
                </InputGroup>

                <Menu as="div" className="relative inline-block text-left">
                    {({open}) => (
                        <>
                            <div className="relative">
                                <MenuButton
                                    className={`inline-flex justify-center items-center px-3 py-2 text-sm font-medium rounded-md border shadow-sm 
                                    focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 
                                    transition-colors duration-200 ease-in-out
                                    ${activeFilterCount > 0
                                        ? "bg-blue-50 text-blue-700 border-blue-300 hover:bg-blue-100 dark:bg-blue-900/30 dark:text-blue-300 dark:border-blue-700/70 dark:hover:bg-blue-800/40"
                                        : "bg-white text-gray-700 border-gray-300 hover:bg-gray-50 dark:bg-gray-800 dark:text-gray-200 dark:border-gray-600 dark:hover:bg-gray-700"
                                    }`}
                                >
                                    <FunnelIcon className="h-4 w-4"/>
                                </MenuButton>

                                {activeFilterCount > 0 && (
                                    <span
                                        className="absolute -top-2 -right-2 flex items-center justify-center w-5 h-5 text-xs font-bold text-white bg-blue-500 dark:bg-blue-600 rounded-full ring-2 ring-white dark:ring-gray-800"
                                        aria-label={t("servicesList.filter.activeFilterCount", {count: activeFilterCount})}
                                    >
                                        {activeFilterCount}
                                    </span>
                                )}
                            </div>

                            <Transition
                                show={open}
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <MenuItems
                                    className="absolute right-0 mt-2 w-56 origin-top-right rounded-md bg-white dark:bg-gray-800 shadow-lg ring-1 ring-black/5 dark:ring-white/10 focus:outline-none z-10 overflow-hidden"
                                >
                                    <div
                                        className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-200 border-b border-gray-200 dark:border-gray-700">
                                        <Trans i18nKey="servicesList.filter.title"/>
                                    </div>
                                    <div className="py-1">
                                        {filterOptions.map((option) => (
                                            <MenuItem key={option.key}>
                                                {({focus}) => {
                                                    const isSelected = !!filters[option.key as keyof ServiceFilters];
                                                    return (
                                                        <button
                                                            onClick={() => handleFilterToggle(option.key as keyof ServiceFilters)}
                                                            className={`
                                                            group flex items-center justify-between w-full px-4 py-3 text-sm
                                                            transition-colors duration-150 ease-in-out
                                                            ${focus
                                                                ? 'bg-gray-100 dark:bg-gray-700'
                                                                : 'hover:bg-gray-50 dark:hover:bg-gray-700/70'
                                                            }
                                                            ${isSelected
                                                                ? 'text-blue-600 font-medium dark:text-blue-300'
                                                                : 'text-gray-700 dark:text-gray-200'
                                                            }
                                                            `}
                                                        >
                                                            <span>{option.label}</span>
                                                            {isSelected && (
                                                                <Check
                                                                    className="h-4 w-4 text-blue-500 dark:text-blue-400"/>
                                                            )}
                                                        </button>
                                                    );
                                                }}
                                            </MenuItem>
                                        ))}
                                    </div>
                                </MenuItems>
                            </Transition>
                        </>
                    )}
                </Menu>
            </div>
        </div>
    );
}

export default ServiceSearchFilter;
