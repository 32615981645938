import {handleResponse} from "@/services/service-helpers.ts";
import {ServiceDetailDTO, ServiceDTO, ServiceGroup, ServiceGroupWithServices} from "@/models/service.ts";

const NBH_SERVICES_GROUPS_BASE_URL = `${import.meta.env.VITE_NBH_URL_ROOT}/api/admin/service-groups`
const NBH_SERVICES_BASE_URL = `${import.meta.env.VITE_NBH_URL_ROOT}/api/admin/services`

export async function getAllServiceGroupsWithServices(token?: string | null) {
    if (!Boolean(token)) {
        console.error("No token provided for service group data retrieval.")
        return Promise.reject("No token provided")
    }

    const response = await fetch(NBH_SERVICES_GROUPS_BASE_URL, {
        method: 'GET',
        headers: new Headers({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        })
    });

    return handleResponse<ServiceGroupWithServices[]>(response);
}

export async function createServiceGroup(token: string | null, serviceGroup: ServiceGroup): Promise<void | ServiceGroup> {
    if (!Boolean(token)) {
        console.error("No token provided for service group data creation.")
        return Promise.reject("No token provided")
    }

    const response = await fetch(NBH_SERVICES_GROUPS_BASE_URL, {
        method: "POST",
        headers: new Headers({
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
        }),
        body: JSON.stringify(serviceGroup)
    });

    return handleResponse<ServiceGroup>(response);
}

export async function createService(token: string | null, service: ServiceDTO): Promise<void | ServiceDetailDTO> {
    if (!Boolean(token)) {
        console.error("No token provided for service group data creation.")
        return Promise.reject("No token provided")
    }

    const {serviceGroupId, ...servicePayload} = service

    let endpoint: string
    if (serviceGroupId) {
        endpoint = `${NBH_SERVICES_GROUPS_BASE_URL}/${serviceGroupId}/services`
    } else {
        endpoint = NBH_SERVICES_BASE_URL
    }

    const response = await fetch(endpoint, {
        method: "POST",
        headers: new Headers({
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
        }),
        body: JSON.stringify(servicePayload)
    });

    return handleResponse<ServiceDetailDTO>(response);
}

export async function updateServiceGroup(token: string | null, serviceGroup: ServiceGroup): Promise<void | ServiceGroup> {
    if (!Boolean(token)) {
        console.error("No token provided for service group data creation.")
        return Promise.reject("No token provided")
    }

    const response = await fetch(`${NBH_SERVICES_GROUPS_BASE_URL}/${serviceGroup.id}`, {
        method: "PUT",
        headers: new Headers({
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
        }),
        body: JSON.stringify(serviceGroup)
    });

    return handleResponse<ServiceGroup>(response);
}

export async function updateService(token: string | null, service: ServiceDTO): Promise<void | ServiceDetailDTO> {
    if (!Boolean(token)) {
        console.error("No token provided for service group data creation.")
        return Promise.reject("No token provided")
    }

    const {serviceGroupId, id,...servicePayload} = service

    let endpoint: string
    if (serviceGroupId) {
        endpoint = `${NBH_SERVICES_GROUPS_BASE_URL}/${serviceGroupId}/services/${id}`
    } else {
        endpoint = `${NBH_SERVICES_BASE_URL}/${id}`
    }

    const response = await fetch(endpoint, {
        method: "PUT",
        headers: new Headers({
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
        }),
        body: JSON.stringify(servicePayload)
    });

    return handleResponse<ServiceDetailDTO>(response);
}

export async function deleteServiceGroup(token: string | null, serviceGroupId: string): Promise<void> {
    if (!Boolean(token)) {
        console.error("No token provided for service group data creation.")
        return Promise.reject("No token provided")
    }

    const response = await fetch(`${NBH_SERVICES_GROUPS_BASE_URL}/${serviceGroupId}`, {
        method: "DELETE",
        headers: new Headers({
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
        })
    });

    return handleResponse<void>(response);
}

export async function deleteService(token: string | null, serviceId: string): Promise<void> {
    if (!Boolean(token)) {
        console.error("No token provided for service deletion.")
        return Promise.reject("No token provided")
    }

    const response = await fetch(`${NBH_SERVICES_BASE_URL}/${serviceId}`, {
        method: "DELETE",
        headers: new Headers({
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
        })
    })

    return handleResponse<void>(response);
}
