import {UserBaseDTO, UserChangePasswordDTO, UserDTO} from "../models/user.ts";
import {UserAccount} from "@/pages/admin/users/users-columns.tsx";
import {handleResponse} from "@/services/service-helpers.ts";

const NBH_ADMIN_USER_BASE_URL = `${import.meta.env.VITE_NBH_URL_ROOT}/api/admin/users`
const NBH_MY_ACCOUNT_BASE_URL = `${import.meta.env.VITE_NBH_URL_ROOT}/api/my-account`

export async function getUsers(token: string) {
    const response = await fetch(NBH_ADMIN_USER_BASE_URL, {
        method: 'GET',
        headers: new Headers({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        })
    });

    return handleResponse<UserAccount[]>(response)
}

export async function createNewUser(userData: UserDTO, token: string) {
    const response = await fetch(NBH_ADMIN_USER_BASE_URL, {
        method: "POST",
        headers:
            new Headers({
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            }),
        body: JSON.stringify(userData)
    })

    return handleResponse<UserDTO>(response)
}

export async function editUser(userData: UserBaseDTO, token: string) {
    const response = await fetch(`${NBH_ADMIN_USER_BASE_URL}/${userData.id}`, {
            method: "PUT",
            headers:
                new Headers({
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }),
            body: JSON.stringify(userData)
        }
    )

    return handleResponse(response)
}

export async function getUserData(userId: string, token: string) {
    const response = await fetch(`${NBH_ADMIN_USER_BASE_URL}/${userId}`,
        {
            method: 'GET',
            headers:
                new Headers({
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                })
        })

    return handleResponse<UserDTO>(response)
}

export async function deleteUser(userId: string, token: string) {
    const response = await fetch(`${NBH_ADMIN_USER_BASE_URL}/${userId}`,
        {
            method: 'DELETE',
            headers:
                new Headers({
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                })

        })

    return handleResponse<string>(response)

}

export async function changePassword(newPasswordDetails: UserChangePasswordDTO, userId: string, token: string) {
    return doPasswordChange(newPasswordDetails, `${NBH_ADMIN_USER_BASE_URL}/${userId}/password`, token)
}

export async function changeMyPassword(newPasswordDetails: UserChangePasswordDTO, token: string) {
    return doPasswordChange(newPasswordDetails, `${NBH_MY_ACCOUNT_BASE_URL}/password`, token)
}

async function doPasswordChange(newPasswordDetails: UserChangePasswordDTO, url: string, token: string) {
    const response = await fetch(url, {
        method: "PUT",
        headers:
            new Headers({
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            }),
        body: JSON.stringify(newPasswordDetails)
    })

    return handleResponse(response)
}
