import {Plus} from 'lucide-react';
import {Text} from "@/components/tailwind/text.tsx";
import {Button} from "@/components/tailwind/button.tsx";
import {t} from "i18next"
import {ServiceGroup} from "@/models/service.ts";

interface EmptyServiceStateProps {
    serviceGroup?: ServiceGroup;
    description?: string;
    buttonText?: string;
    onAddService: (serviceGroup: ServiceGroup | undefined, serviceId: string | undefined) => void;
    className?: string;
}

function EmptyServiceState({
                               serviceGroup,
                               description = t("servicesList.serviceGroup.no-services-yet"),
                               buttonText = t("servicesList.buttons.add-service.text"),
                               onAddService,
                               className = "",
                           }: EmptyServiceStateProps) {
    return (
        <div className={`text-center py-6 px-4 bg-white dark:bg-gray-900 rounded-lg ${className}`}>
            <div className="mb-4">
                <div className="inline-flex items-center justify-center h-12 w-12 rounded-full bg-gray-100 dark:bg-gray-800 mb-4">
                    <Plus size={20} className="text-gray-500 dark:text-gray-400" />
                </div>
                <Text className="text-gray-500 dark:text-gray-400 mb-4">
                    {description}
                </Text>
            </div>
            <Button
                onClick={() => onAddService(serviceGroup, undefined)}
                className="flex items-center gap-2 mx-auto"
            >
                <Plus size={16} />
                <span>{buttonText}</span>
            </Button>
        </div>
    );
}

export default EmptyServiceState;
