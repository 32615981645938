import * as Yup from 'yup';
import {optionalGermanPhoneNumberString, optionalString} from "@/helpers/yup-helpers.ts";
import {isValidIBAN} from "ibantools-germany"
import {isValidBIC} from "bankdata-germany";
import {isDefined} from "@/helpers/sanitizer.ts";

export function validationSchema() {
    return Yup.object().shape({
        id:
            Yup.string()
                .uuid("memberForm.errors.invalid-id")
                .required("memberForm.errors.missing-id"),
        title: optionalString(),
        firstName: Yup.string()
            .required("memberForm.errors.first-name-required"),
        lastName: Yup.string()
            .required("memberForm.errors.last-name-required"),
        companyName: optionalString(),
        email: optionalString()
            .email("memberForm.errors.invalid-email-address"),
        address: Yup.object({
            street: Yup.string()
                .required("memberForm.errors.address-street-required"),
            houseNo: optionalString(),
            postcode: Yup.string()
                .matches(/^\d{5}$/, "memberForm.errors.address-postcode-invalid-format")
                .required("memberForm.errors.address-postcode-required"),
            city: Yup.string()
                .required("memberForm.errors.address-city-required"),
        }).required("memberForm.errors.address-required"),
        membershipStartDate: Yup.string()
            .test(
                "is-date",
                "memberForm.errors.invalid-membership-start-date-format",
                (value) => {
                    if (value) {
                        return !isNaN(Date.parse(value))
                    } else {
                        return true;
                    }
                })
            .required("memberForm.errors.membership-start-required"),
        membershipStatus: Yup.object()
            .shape({
                id: Yup.string()
                    .uuid("memberForm.errors.invalid-membership-status")
                    .required(),
                value: Yup.string()
                    .required()
            })
            .required("memberForm.errors.membership-status-required"),
        dateOfBirth: Yup.string()
            .test(
                "is-date",
                "invalid-birthday-date-format",
                (value) => {
                    if (value) {
                        return !isNaN(Date.parse(value));
                    } else {
                        return true;
                    }
                }
            )
            .test(
                "is-before-today",
                "memberForm.errors.birthday-needs-to-be-before-today",
                (value) => {
                    if (value) {
                        console.log("Checking if birthday is before today: ", value, new Date().toISOString().split('T')[0]);
                        return value < new Date().toISOString().split('T')[0];
                    } else {
                        return true;
                    }
                }
            )
            .required("memberForm.errors.birthday-required"),
        phoneNumbers: Yup.object()
            .shape({
                landline: optionalGermanPhoneNumberString("memberForm.errors.invalid-landline"),
                mobile: optionalGermanPhoneNumberString("memberForm.errors.invalid-mobile"),
            })
            .test("at-least-one-phone-number", "memberForm.errors.at-least-one-phone-number",
                function (value) {
                    if (!(value.landline || value.mobile)) {
                        return this.createError({
                            path: "phoneNumbers",
                            message: "memberForm.errors.at-least-one-phone-number-required"
                        });
                    }

                    return true;
                })
            .required("memberForm.errors.at-least-one-phone-number-required"),
        bankingDetails: Yup.object()
            .shape({
                accountOwner: optionalString(),
                iban: optionalString()
                    .test("iban", "memberForm.errors.invalid-iban", (value) => {
                        return !isDefined(value) || isValidIBAN(value?.replace(/\s+/g, ''))
                    }),
                bic: optionalString()
                    .test("bic", "memberForm.errors.invalid-bic", (value) => {
                        return !isDefined(value) || isValidBIC(value)
                    }),
                bankName: optionalString(),
                mandateDate: Yup.string()
                    .when("iban", {
                        is: (iban: string) => isDefined(iban) && iban.trim() !== "",
                        then: (schema) =>
                            schema
                                .test("is-date",
                                    "memberForm.errors.invalid-mandate-date-format",
                                    (value) => {
                                        console.log("Checking mandate date: ", value);
                                        if (value) {
                                            return !isNaN(Date.parse(value))
                                        } else {
                                            return true;
                                        }
                                    })
                                .nonNullable("memberForm.errors.mandate-date-required")
                                .required("memberForm.errors.mandate-date-required"),
                        otherwise: (schema) =>
                            schema
                                .nullable()
                                .optional()
                    })
            })
            .optional(),
        comments: Yup.object()
            .shape({
                general: optionalString(),
                availability: optionalString()
            })
            .defined()
            .optional(),
    }).test(
        "membership-must-start-after-birth",
        "membership-must-start-after-birth",
        function (value) {
            if (value.dateOfBirth === undefined || value.membershipStartDate === undefined) return true;
            if (value.membershipStartDate < value.dateOfBirth) {
                return this.createError({
                    path: "membershipStart",
                    message: "memberForm.errors.membership-must-start-after-birth"
                });
            }
            return true;
        });
}
