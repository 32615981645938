import {ColumnDef} from "@tanstack/react-table";
import {sortableColumnHeader} from "@/components/data-table.tsx";
import {t} from "i18next";
import {Trans} from "react-i18next";
import {Link} from "@/components/tailwind/link.tsx";
import {Text} from "@/components/tailwind/text"
import {SquareUserRoundIcon} from "lucide-react";
import {MemberListItem, PhoneNumber, SimplePhoneNumber} from "@/models/member.ts";
import PhoneNumberDisplay from "@/components/phone-number-display.tsx";

function generateNameColumnContent(row: MemberListItem) {
    if (!Boolean(row.companyName)) {
        return (
            <Text>{[row.salutation, row.title, row.firstName, row.lastName].filter(Boolean).join(" ")}</Text>
        )
    }

    return (
        <span>
            <Text>{[row.salutation, row.title, row.firstName, row.lastName].filter(Boolean).join(" ")}</Text>
            {row.companyName && (
                <Text><Trans i18nKey={'memberList.companyPrefix'}/> {row.companyName}</Text>
            )}
        </span>
    )
}


function generatePhoneColumnContent(phoneNumbers?: PhoneNumber[] | SimplePhoneNumber[]) {
    if (!Array.isArray(phoneNumbers) || !phoneNumbers?.length) {
        return (<></>)
    }

    return (
        <section role="list">
            {phoneNumbers.map((entry, index) => (
                    <PhoneNumberDisplay type={entry.type} number={entry.number} key={index}/>
                )
            )}
        </section>
    )
}

function constructSortableNameValue(row: MemberListItem) {
    return `${row.lastName || ''}, ${row.firstName || ''} ${row.title || ''} ${row.salutation || ''} ` +
        `${row.companyName || ''}`.trim();
}

export const memberColumns: ColumnDef<MemberListItem>[] = [
    {
        accessorKey: "memberNo",
        header: ({column}) =>
            sortableColumnHeader(column, "memberList.table.headers.memberNo")
    },
    {
        id: "name",
        accessorFn: constructSortableNameValue,
        header: ({column}) =>
            sortableColumnHeader(column, "memberList.table.headers.name"),
        cell: ({row}) => generateNameColumnContent(row.original),

    },
    {
        accessorKey: "address",
        header: t("memberList.table.headers.address"),
        cell: ({row}) => {
            return (
                <address>
                    <Text>{row.original.street} {row.original.houseNumber}</Text>
                    <Text>{row.original.postcode} {row.original.city}</Text>
                </address>
            )
        }

    },
    {
        id: "phoneNumbers",
        header: t("memberList.table.headers.phoneNumbers"),
        cell: ({row}) => generatePhoneColumnContent(row.original.phoneNumbers)
    },
    {
        accessorKey: "membershipStatus",
        accessorFn: (row) => `${row.status}`.trim(),
        header:
            ({column}) =>
                sortableColumnHeader(column, "memberList.table.headers.status"),
        cell:
            ({row}) => {
                return <Trans
                    i18nKey={"memberList.table.statusColumn." + (row.original.status.toUpperCase())}/>
            }
    },
    {
        id: "actions",
        cell: ({row}) => {
            const member = row.original

            return (
                <Link href={`/members/${member.id}`}><SquareUserRoundIcon/></Link>

            )
        }

    }
]
