import {useCallback, useEffect, useState} from 'react';
import {ChevronDown, ChevronRight, Edit, Plus, Search, SearchX, Trash2} from 'lucide-react';
import {Heading} from "@/components/tailwind/heading.tsx";
import {Text} from "@/components/tailwind/text.tsx";
import {Button} from "@/components/tailwind/button.tsx"
import {Card} from "@/components/ui/card.tsx";
import {useAuth} from "@/context/use-auth.tsx";
import {Service, ServiceGroupWithServices} from "@/models/service.ts";
import {deleteService, getAllServiceGroupsWithServices} from "@/services/service-services.ts";
import EmptyState from "@/components/empty-state.tsx";
import EmptyServiceState from "@/components/empty-service-state.tsx";
import {Trans, useTranslation} from "react-i18next";
import {ServiceGroupDialog, useServiceGroupDialog} from "@/components/service-group-dialog.tsx";
import {ServiceDialog, useServiceDialog} from "@/components/service-dialog.tsx";
import ServiceSearchFilter, {ServiceFilters} from '@/components/service-search-filter.tsx';
import {DeleteServiceGroupDialog, useDeleteServiceGroupDialog} from "@/components/delete-service-group-dialog.tsx";
import DeleteConfirmation, {useDeleteConfirmation} from "@/components/generic-delete-confirmation.tsx";
import {useToast} from "@/hooks/use-toast.ts";

function HighlightText({text, searchTerm}: { text: string, searchTerm: string }) {
    if (!searchTerm.trim() || !text) {
        return <>{text}</>;
    }

    const parts = text.split(new RegExp(`(${searchTerm})`, 'gi'));

    return (
        <>
            {parts.map((part, i) =>
                part.toLowerCase() === searchTerm.toLowerCase()
                    ? <mark key={i} className="bg-yellow-200 dark:bg-yellow-700 rounded px-0.5">{part}</mark>
                    : part
            )}
        </>
    );
}

export default function ServiceListPage() {
    const {t} = useTranslation();
    const {token} = useAuth()
    const [expandedGroups, setExpandedGroups] = useState<string[]>([]);
    const [serviceGroupsData, setServiceGroupsData] = useState<ServiceGroupWithServices[]>([]);
    const [filteredServiceGroupsData, setFilteredServiceGroupsData] = useState<ServiceGroupWithServices[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");
    const [serviceFilters, setServiceFilters] = useState<ServiceFilters>({});
    const {serviceGroupDialogProps, openServiceGroupDialog} = useServiceGroupDialog()
    const {serviceDialogProps, openServiceDialog} = useServiceDialog()
    const {deleteServiceGroupDialogProps, openDeleteServiceGroupDialog} = useDeleteServiceGroupDialog()
    const {deleteConfirmationDialogProps, openDeleteConfirmationDialog} = useDeleteConfirmation()
    const {toast} = useToast()

    const UNGROUPED_ID = "ungrouped";

    async function fetchServiceGroups() {
        setIsLoading(true);
        try {
            const data = await getAllServiceGroupsWithServices(token);
            const groupData = data as ServiceGroupWithServices[];

            const processedGroups: ServiceGroupWithServices[] = [];
            let ungroupedServices: Service[] = [];

            groupData.forEach(group => {
                if (group.id === null) {
                    ungroupedServices = group.services || [];
                } else {
                    processedGroups.push(group);
                }
            });

            processedGroups.sort((a, b) => a.name.localeCompare(b.name));

            const finalGroups: ServiceGroupWithServices[] = [];

            if (ungroupedServices.length > 0) {
                finalGroups.push({
                    id: UNGROUPED_ID,
                    name: t("servicesList.ungroupedServices.title"),
                    description: t("servicesList.ungroupedServices.description"),
                    services: ungroupedServices
                });
            }

            finalGroups.push(...processedGroups);

            setServiceGroupsData(finalGroups);

            if (finalGroups.length > 0 && expandedGroups.length === 0) {
                setExpandedGroups([finalGroups[0].id]);
            }
        } catch (error) {
            console.error("Error loading service group data:", error);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        fetchServiceGroups();
    }, [token]);

    const filterData = useCallback(() => {
        if (!serviceGroupsData.length) return;

        const hasActiveFilters = Object.keys(serviceFilters).length > 0;
        const hasSearchTerm = searchTerm.trim() !== '';

        const filterGroups = serviceGroupsData.map(group => {
            const groupMatchesSearch =
                !hasSearchTerm ||
                group.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                (group.description && group.description.toLowerCase().includes(searchTerm.toLowerCase()));

            const filteredServices = group.services.filter(service => {
                if (groupMatchesSearch && hasSearchTerm) {
                    let matchesFilters = true;

                    for (const [key, value] of Object.entries(serviceFilters)) {
                        if (value !== undefined) {
                            const serviceValue = service[key as keyof Service];
                            if (serviceValue !== value) {
                                matchesFilters = false;
                                break;
                            }
                        }
                    }

                    return matchesFilters;
                }

                const serviceMatchesSearch =
                    !hasSearchTerm ||
                    service.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    (service.description && service.description.toLowerCase().includes(searchTerm.toLowerCase()));

                let matchesFilters = true;

                for (const [key, value] of Object.entries(serviceFilters)) {
                    if (value !== undefined) {
                        const serviceValue = service[key as keyof Service];
                        if (serviceValue !== value) {
                            matchesFilters = false;
                            break;
                        }
                    }
                }

                return serviceMatchesSearch && matchesFilters;
            });

            if (hasActiveFilters && !hasSearchTerm && filteredServices.length === 0) {
                return null;
            }

            if (groupMatchesSearch || filteredServices.length > 0) {
                return {
                    ...group,
                    services: filteredServices,
                    groupMatchesSearch: groupMatchesSearch && hasSearchTerm
                };
            }

            return null;
        }).filter(Boolean) as (ServiceGroupWithServices & { groupMatchesSearch?: boolean })[];

        setFilteredServiceGroupsData(filterGroups);

        if (hasSearchTerm || hasActiveFilters) {
            setExpandedGroups(filterGroups.map(group => group.id));
        }
    }, [serviceGroupsData, searchTerm, serviceFilters]);

    useEffect(() => {
        filterData();
    }, [filterData, serviceGroupsData, searchTerm, serviceFilters]);

    function toggleGroup(groupId: string) {
        setExpandedGroups(prev => {
            if (prev.includes(groupId)) {
                return prev.filter(id => id !== groupId);
            } else {
                return [...prev, groupId];
            }
        });
    }

    async function handleSaveServiceGroup() {
        await fetchServiceGroups();
    }

    async function handleDeleteServiceGroup() {
        await fetchServiceGroups()
    }

    async function handleAddService() {
        await fetchServiceGroups();
    }

    function findServiceById(serviceId: string): Service | undefined {
        const flattenedServices = filteredServiceGroupsData.flatMap(group =>
            group.services.map(service => service)
        );

        return flattenedServices.find(service => service.id === serviceId);
    }

    async function handleDeleteService(serviceId?: string) {
        if (!serviceId) {
            console.log("Unable to delete service without ID");
            return
        }

        const serviceForDeletion = findServiceById(serviceId)

        deleteService(token, serviceId)
            .then(() => {
                toast({
                    title: t("deleteServiceDialog.toast.success.title"),
                    description: t(
                        "deleteServiceDialog.toast.success.description",
                        {
                            serviceName: serviceForDeletion?.name,
                        }
                    ),
                    variant: "default",
                })

                fetchServiceGroups()
            })
            .catch((error: any) => {
                console.log(`Unable to delete service with ID ${serviceId}. Received error: `, error);

                toast({
                    title: t("deleteServiceDialog.toast.error.title"),
                    description: t(
                        "deleteServiceDialog.toast.error.description",
                        {
                            serviceName: serviceForDeletion?.name,
                            error: error,
                        }
                    ),
                    variant: "destructive",
                })
            })

        await fetchServiceGroups();
    }


    return (
        <div className="container mx-auto py-6 px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between items-center mb-6">
                <Heading level={1} className="text-xl sm:text-2xl">
                    <Trans i18nKey={"servicesList.serviceGroup.title"}/>
                </Heading>
                <div className="space-x-2">
                    <Button
                        onClick={() => openServiceDialog()}
                        className="flex items-center gap-2 p-2 sm:p-3"
                        aria-label={t("servicesList.buttons.add-service-group.ariaLabel")}
                    >
                        <Plus size={16}/>
                        <span className="hidden sm:inline"><Trans
                            i18nKey={"servicesList.buttons.add-service.text"}/></span>
                    </Button>
                    <Button
                        onClick={() => openServiceGroupDialog()}
                        className="flex items-center gap-2 p-2 sm:p-3"
                        aria-label={t("servicesList.buttons.add-service-group.ariaLabel")}
                    >
                        <Plus size={16}/>
                        <span className="hidden sm:inline"><Trans
                            i18nKey={"servicesList.buttons.add-service-group.text"}/></span>
                    </Button>
                </div>

            </div>

            {!isLoading && serviceGroupsData.length > 0 && (
                <ServiceSearchFilter
                    searchTerm={searchTerm}
                    filters={serviceFilters}
                    onSearchChange={setSearchTerm}
                    onFilterChange={setServiceFilters}
                />
            )}
            {isLoading ? (
                <div className="flex justify-center items-center h-64">
                    <Text className="text-gray-500"><Trans i18nKey={"servicesList.loading"}/> </Text>
                </div>
            ) : filteredServiceGroupsData.length > 0 ? (
                <div className="grid gap-6 mb-8">
                    {filteredServiceGroupsData.map((group) => (
                        <Card key={group.id} className="overflow-hidden">
                            <div
                                className="p-4 flex justify-between items-center border-b cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-800"
                                onClick={() => toggleGroup(group.id)}
                            >
                                <div className="flex items-center gap-2">
                                    {expandedGroups.includes(group.id) ? (
                                        <ChevronDown className="text-gray-500 dark:text-gray-400" size={20}/>
                                    ) : (
                                        <ChevronRight className="text-gray-500 dark:text-gray-400" size={20}/>
                                    )}
                                    <div>
                                        <Text className="font-medium">
                                            <HighlightText text={group.name} searchTerm={searchTerm}/>
                                        </Text>
                                        <Text className="text-gray-500 dark:text-gray-400">
                                            <Trans i18nKey={"servicesList.serviceGroup.serviceCount"}
                                                   values={{count: group.services.length}}/>
                                        </Text>
                                    </div>
                                </div>

                                <div className="flex items-center gap-2">
                                    {group.id !== UNGROUPED_ID && (
                                        <>
                                            <button
                                                className="p-1.5 rounded-full hover:bg-gray-200 dark:hover:bg-gray-700"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    openServiceGroupDialog(group);
                                                }}
                                                aria-label={t("servicesList.buttons.edit-service-group.ariaLabel",
                                                    {groupName: group.name})}
                                            >
                                                <Edit size={16} className="text-blue-600 dark:text-blue-400"/>
                                            </button>
                                            <button
                                                className="p-1.5 rounded-full hover:bg-gray-200 dark:hover:bg-gray-700"
                                                onClick={() => openDeleteServiceGroupDialog(group)}
                                                aria-label={t("servicesList.buttons.delete-service-group.ariaLabel",
                                                    {groupName: group.name})}
                                            >
                                                <Trash2 size={16} className="text-red-600 dark:text-red-400"/>
                                            </button>
                                        </>
                                    )}
                                </div>
                            </div>

                            {expandedGroups.includes(group.id) && (
                                <div className="p-4 bg-gray-50 dark:bg-gray-800">
                                    <div className="mb-4 flex justify-between items-center">
                                        <Text className="text-gray-600 dark:text-gray-300">
                                            <HighlightText
                                                text={group.description || ''}
                                                searchTerm={searchTerm}
                                            />
                                        </Text>
                                        <Button
                                            outline
                                            className="flex items-center gap-1 btn-sm"
                                            onClick={() => {
                                                if (group.id === UNGROUPED_ID) {
                                                    openServiceDialog();
                                                } else {
                                                    openServiceDialog(group);
                                                }
                                            }}
                                            aria-label={t("servicesList.buttons.add-service.ariaLabel",
                                                {groupName: group.name})}
                                        >
                                            <Plus size={14}/>
                                            <span className="hidden sm:inline">
                                                <Trans i18nKey={"servicesList.buttons.add-service.text"}/>
                                            </span>
                                        </Button>
                                    </div>

                                    {group.services.length > 0 ? (
                                        <div className="bg-white dark:bg-gray-900 rounded-lg overflow-hidden shadow-sm">
                                            <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                                                <thead className="bg-gray-100 dark:bg-gray-800">
                                                <tr>
                                                    <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                                                        <Trans
                                                            i18nKey={"servicesList.serviceGroup.servicesTable.header.name"}/>
                                                    </th>
                                                    <th className="hidden md:table-cell px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                                                        <Trans
                                                            i18nKey={"servicesList.serviceGroup.servicesTable.header.description"}/>
                                                    </th>
                                                    <th className="hidden md:table-cell px-4 py-3 text-center text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                                                        <Trans
                                                            i18nKey={"servicesList.serviceGroup.servicesTable.header.fixedPoints"}/>
                                                    </th>
                                                    <th className="hidden md:table-cell px-4 py-3 text-center text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                                                        <Trans
                                                            i18nKey={"servicesList.serviceGroup.servicesTable.header.specialAssignment"}/>
                                                    </th>
                                                    <th className="px-4 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                                                        <Trans
                                                            i18nKey={"servicesList.serviceGroup.servicesTable.header.actions"}/>
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                                                {group.services.map((service) => (
                                                    <tr key={service.id}
                                                        className="hover:bg-gray-50 dark:hover:bg-gray-800">
                                                        <td className="px-4 py-3 text-sm font-medium text-gray-900 dark:text-gray-100">
                                                            <HighlightText text={service.name} searchTerm={searchTerm}/>
                                                        </td>
                                                        <td className="hidden md:table-cell px-4 py-3 text-sm text-gray-500 dark:text-gray-400">
                                                            <HighlightText text={service.description || ''}
                                                                           searchTerm={searchTerm}/>
                                                        </td>
                                                        <td className="hidden md:table-cell px-4 py-3 text-sm text-center text-gray-500 dark:text-gray-400">
                                                            {service.isFixedPoints ? '✓' : '✗'}
                                                        </td>
                                                        <td className="hidden md:table-cell px-4 py-3 text-sm text-center text-gray-500 dark:text-gray-400">
                                                            {service.isSpecialAssignment ? '✓' : '✗'}
                                                        </td>
                                                        <td className="px-4 py-3 text-sm text-right">
                                                            <div className="flex justify-end gap-2">
                                                                <button
                                                                    className="p-1.5 rounded-full hover:bg-gray-200 dark:hover:bg-gray-700"
                                                                    onClick={() => {
                                                                        if (group.id === UNGROUPED_ID) {
                                                                            openServiceDialog(undefined, service.id);
                                                                        } else {
                                                                            openServiceDialog(group, service.id);
                                                                        }
                                                                    }}
                                                                    aria-label={t("servicesList.buttons.edit-service.ariaLabel", {serviceName: service.name})}
                                                                >
                                                                    <Edit size={14}
                                                                          className="text-blue-600 dark:text-blue-400"/>
                                                                </button>
                                                                <button
                                                                    className="p-1.5 rounded-full hover:bg-gray-200 dark:hover:bg-gray-700"
                                                                    aria-label={t("servicesList.buttons.delete-service.ariaLabel", {serviceName: service.name})}
                                                                    onClick={() => {
                                                                        openDeleteConfirmationDialog(t("deleteServiceDialog.message", {
                                                                            serviceName: service.name
                                                                        }), service!.id);
                                                                    }}
                                                                >
                                                                    <Trash2 size={14}
                                                                            className="text-red-600 dark:text-red-400"/>
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    ) : (
                                        <EmptyServiceState
                                            serviceGroup={group.id !== UNGROUPED_ID ? group : undefined}
                                            onAddService={group.id !== UNGROUPED_ID ?
                                                openServiceDialog :
                                                () => openServiceDialog()}
                                        />
                                    )}
                                </div>
                            )}
                        </Card>
                    ))}
                </div>
            ) : (
                <EmptyState
                    title={searchTerm
                        ? t("servicesList.serviceGroup.noResults.title")
                        : t("servicesList.serviceGroup.empty.title")}
                    description={searchTerm
                        ? t("servicesList.serviceGroup.noResults.description")
                        : t("servicesList.serviceGroup.empty.description")}
                    buttonText={searchTerm
                        ? t("servicesList.serviceGroup.noResults.buttonText")
                        : t("servicesList.serviceGroup.empty.buttonText")}
                    onAction={searchTerm
                        ? () => {
                            setSearchTerm("");
                            setServiceFilters({});
                        }
                        : openServiceGroupDialog}
                    {...(searchTerm
                        ? {
                            headerIcon: <SearchX size={24}/>,
                            buttonIcon: <Search size={16}/>
                        } : {})}
                />
            )}

            <ServiceGroupDialog
                {...serviceGroupDialogProps}
                onSave={handleSaveServiceGroup}
            />

            <ServiceDialog
                {...serviceDialogProps}
                onSave={handleAddService}
            />

            <DeleteServiceGroupDialog
                {...deleteServiceGroupDialogProps}
                onConfirm={handleDeleteServiceGroup}
            />

            <DeleteConfirmation
                {...deleteConfirmationDialogProps}
                onConfirm={handleDeleteService}
            />
        </div>
    );
}
