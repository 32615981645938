import {MemberListItem} from "@/models/member"
import {ColumnDef} from "@tanstack/react-table"
import {useEffect, useState} from "react"
import {useDebounce} from "@/hooks/use-debounce"
import {DataTable} from "@/components/data-table.tsx";
import {Input, InputGroup} from "@/components/tailwind/input.tsx";
import {useTranslation} from "react-i18next";
import {MagnifyingGlassIcon} from "@heroicons/react/16/solid";

interface MemberDataTableProps {
    columns: ColumnDef<MemberListItem, any>[]
    data: MemberListItem[]
}

export function MemberDataTable({columns, data}: MemberDataTableProps) {
    const {t} = useTranslation()
    const [searchTerm, setSearchTerm] = useState("")
    const [filteredData, setFilteredData] = useState<MemberListItem[]>(data)

    const debouncedSearchTerm = useDebounce(searchTerm, 300)

    useEffect(() => {
            if (!debouncedSearchTerm.trim()) {
                setFilteredData(data)
                return
            }

            const filtered = data.filter(member => {
                const searchLower = debouncedSearchTerm.toLowerCase()

                const memberNoMatch = member.memberNo.toString().includes(debouncedSearchTerm)
                const nameMatch = `${member.firstName} ${member.lastName}`
                    .toLowerCase()
                    .includes(searchLower)
                const addressMatch = `${member.street} ${member.houseNumber} ${member.postcode} ${member.city}`
                    .toLowerCase()
                    .includes(searchLower)
                const companyNameMatch = member.companyName
                    ?.toLowerCase()
                    .includes(searchLower)

                return memberNoMatch || nameMatch || addressMatch || companyNameMatch
            })

            setFilteredData(filtered)
        }, [debouncedSearchTerm, data]
    )

    return (
        <div className="space-y-4">
            {/* Suchleiste */}
            <div className="relative">
                <InputGroup>
                    <MagnifyingGlassIcon/>
                    <Input
                        type="text"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        placeholder="Mitglied suchen..."
                        aria-label={t("memberList.search.ariaLabel")}
                    />
                </InputGroup>
            </div>

            {/* DataTable */}
            <DataTable
                columns={columns}
                data={filteredData}
            />
        </div>
    )
}
