import {useForm} from 'react-hook-form';
import {z} from 'zod';
import {zodResolver} from '@hookform/resolvers/zod';
import {Trans, useTranslation} from 'react-i18next';
import {Dialog, DialogActions, DialogBody, DialogTitle} from '@/components/tailwind/dialog';
import {Button} from '@/components/tailwind/button';
import {Input} from '@/components/tailwind/input';
import {Member, MemberFormPhoneNumber, MemberUpdateContactDetailsRequest, PhoneNumber} from "@/models/member.ts";
import ValidationAlert from "@/components/validation-alert.tsx";
import {Field, Label} from "@/components/tailwind/fieldset.tsx";
import {updateMemberContactDetails} from "@/services/member-services.ts";
import {useAuth} from "@/context/use-auth.tsx";
import {useEffect, useMemo} from "react";
import {v4 as uuid} from 'uuid'

interface MemberEditContactDetailsDialogProps {
    isOpen: boolean;
    onSave: (data: Member) => void;
    onClose: () => void;
    memberData: Member;
}

function isGermanPhoneNumber(value: string): boolean {
    return /^(\+49|0)[1-9]\d{1,14}$/.test(value);
}

function germanPhoneNumberSchema(errorKey: string = "errors.invalid-phone-number") {
    return z.string()
        .transform(val => val === '' ? undefined : val.replace(/\s+/g, ''))
        .pipe(
            z.string()
                .refine(isGermanPhoneNumber, errorKey)
                .optional()
        );
}

function isGermanPostcode(value: string): boolean {
    return /^\d{5}$/.test(value);
}


const memberEditContactDetailsSchema = z.object({
    email: z.union([
        z.literal(''),
        z.string().email("memberEditDialog.contacts.errors.invalid-email-address")
    ])
        .optional()
        .transform(val => val === '' ? undefined : val),
    phoneNumbers: z.object({
        landline: germanPhoneNumberSchema("memberEditDialog.contacts.errors.invalid-landline"),
        mobile: germanPhoneNumberSchema("memberEditDialog.contacts.errors.invalid-mobile"),
    }).refine(
        (data) => data.landline || data.mobile,
        {
            message: "memberEditDialog.contacts.errors.at-least-one-phone-number-required",
            path: this
        }
    ),
    address: z.object({
        street: z.string({
            required_error: "memberEditDialog.contacts.errors.address-street-required"
        }).min(1, "memberEditDialog.contacts.errors.address-street-required"),

        houseNumber: z.string()
            .nullable()
            .transform(val => (!val ? undefined : val))
            .optional(),

        postcode: z.string({
            required_error: "memberEditDialog.contacts.errors.address-postcode-required"
        })
            .min(1, "memberEditDialog.contacts.errors.address-postcode-required")
            .refine(isGermanPostcode, {
                message: "memberEditDialog.contacts.errors.address-postcode-invalid-format"
            }),

        city: z.string({
            required_error: "memberEditDialog.contacts.errors.address-city-required"
        }).min(1, "memberEditDialog.contacts.errors.address-city-required"),
    }),
});


function mapPhoneNumbers(phones?: PhoneNumber[]): MemberFormPhoneNumber {
    const defaultResult: MemberFormPhoneNumber = {
        landline: '',
        mobile: '',
    };

    if (!phones || phones.length === 0) {
        return defaultResult;
    }

    return phones.reduce(function (result, current) {
        return {
            ...result,
            [current.type === 'LANDLINE' ? 'landline' : 'mobile']: current.number
        };
    }, defaultResult);
}

export type MemberEditContactDetailsFormData = z.infer<typeof memberEditContactDetailsSchema>;

type PhoneNumbersData = {
    landline?: string;
    mobile?: string;
};

export default function MemberEditContactDetailsDialog({
                                                           isOpen,
                                                           onSave,
                                                           onClose,
                                                           memberData,
                                                       }: MemberEditContactDetailsDialogProps) {
    const {t} = useTranslation();

    const defaultValues = useMemo(() => ({
        phoneNumbers: mapPhoneNumbers(memberData.phoneNumbers),
        address: memberData.address,
        email: memberData.email
    }), [
        memberData.phoneNumbers,
        memberData.address,
        memberData.email
    ]);

    const {
        register,
        handleSubmit,
        setError,
        formState: {errors},
        reset
    } = useForm<MemberEditContactDetailsFormData>({
        resolver: zodResolver(memberEditContactDetailsSchema),
        defaultValues
    });

    useEffect(() => {
        if (isOpen && memberData) {
            reset(defaultValues);
        }
    }, [isOpen, memberData, reset]);

    const {token} = useAuth();

    function extractPhoneNumbers(data: MemberEditContactDetailsFormData): PhoneNumber[] {
        const PHONE_TYPES = {
            LANDLINE: {
                type: "LANDLINE" as const,
                getValue: (data: PhoneNumbersData) => data.landline
            },
            MOBILE: {
                type: "MOBILE" as const,
                getValue: (data: PhoneNumbersData) => data.mobile
            }
        } as const;

        return Object.values(PHONE_TYPES)
            .map(config => ({
                current: memberData.phoneNumbers?.find(entry => entry.type === config.type),
                new: config.getValue(data.phoneNumbers),
                type: config.type
            }))
            .filter(entry => entry.new)
            .map(entry => entry.current
                ? {...entry.current, number: entry.new!}
                : {id: uuid(), type: entry.type, number: entry.new!}
            );
    }

    async function saveData(data: MemberEditContactDetailsFormData): Promise<boolean> {
        const contactDetailsData: MemberUpdateContactDetailsRequest = {
            memberId: memberData.id,
            address: {
                id: memberData.address!!.id,
                ...data.address,

            },
            phoneNumbers: extractPhoneNumbers(data),
            email: data.email || undefined,
        }

        try {
            const response = await updateMemberContactDetails(token, contactDetailsData);
            if (response) {
                onSave(response)
            }
            return true
        } catch (error: any) {
            console.error("Error updating member: ", error);
            if (Boolean(error.status)) {
                setError("root", {
                    message: t("serverError.response-code-with-details", {
                        statusCode: error.status,
                        errorDetails: error.error
                    })
                });
            } else {
                setError("root", {message: t("serverError.unknown-with-details", {errorDetails: error.message})});
            }

            return false;
        }

    }

    async function onSubmit(data: MemberEditContactDetailsFormData) {
        if (await saveData(data)) {
            handleClosing();
        }
    }

    function handleClosing() {
        reset();
        onClose();
    }

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle>
                <Trans i18nKey="memberEditDialog.contacts.title"/>
            </DialogTitle>

            <DialogBody>
                {errors?.root && (
                    <ValidationAlert
                        message={errors.root?.message || t("sererError.unknown")}
                    />
                )}

                <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                    <div>
                        <Field>
                            <Label>
                                <Trans i18nKey="memberEditDialog.contacts.fields.email.label"/>
                            </Label>
                            <Input
                                type="email"
                                id="email"
                                autoComplete="home email"
                                {...register('email')}
                                invalid={Boolean(errors.email)}
                                aria-label={t("memberEditDialog.contacts.fields.email.ariaLabel")}
                            />
                            {errors.email && (
                                <ValidationAlert
                                    message={t(errors.email?.message ||
                                        "memberEditDialog.contacts.errors.unknown-email-issue")}
                                />
                            )}
                        </Field>
                    </div>

                    <div className="space-y-2">
                        <h3 className="text-base font-medium text-zinc-900 dark:text-zinc-100">
                            <Trans i18nKey="memberEditDialog.contacts.sections.phone.title"/>
                        </h3>
                        <div className="space-y-4">
                            {errors.phoneNumbers?.type == "custom" && (
                                <ValidationAlert message={t(errors.phoneNumbers?.message ||
                                    "memberEditDialog.contacts.errors.unknown-phone-number-issue")}/>
                            )}

                            <Field>
                                <Label>
                                    <Trans i18nKey="memberEditDialog.contacts.fields.phone.landline.label"/>
                                </Label>
                                <Input
                                    type="text"
                                    id="landline"
                                    autoComplete="home tel"
                                    {...register('phoneNumbers.landline')}
                                    invalid={Boolean(errors.phoneNumbers?.landline || errors.phoneNumbers?.type == "custom")}
                                    aria-label={t("memberEditDialog.contacts.fields.phone.landline.ariaLabel")}
                                />
                                {errors.phoneNumbers?.landline && (
                                    <ValidationAlert
                                        message={t(errors.phoneNumbers?.landline?.message ||
                                            "memberEditDialog.contacts.errors.unknown-landline-issue")}
                                    />
                                )}
                            </Field>
                            <Field>
                                <Label>
                                    <Trans i18nKey="memberEditDialog.contacts.fields.phone.mobile.label"/>
                                </Label>
                                <Input
                                    type="text"
                                    id="mobile"
                                    autoComplete="mobile tel"
                                    {...register('phoneNumbers.mobile')}
                                    invalid={Boolean(errors.phoneNumbers?.mobile || errors.phoneNumbers?.type == "custom")}
                                    aria-label={t("memberEditDialog.contacts.fields.phone.mobile.ariaLabel")}
                                />
                                {errors.phoneNumbers?.mobile && (
                                    <ValidationAlert
                                        message={t(errors.phoneNumbers?.mobile?.message ||
                                            "memberEditDialog.contacts.errors.unknown-mobile-issue")}
                                    />
                                )}
                            </Field>
                        </div>
                    </div>

                    <div className="space-y-2">
                        <h3 className="text-base font-medium text-zinc-900 dark:text-zinc-100">
                            <Trans i18nKey="memberEditDialog.contacts.sections.address.title"/>
                        </h3>
                        <div className="space-y-4">
                            <div className="grid grid-cols-4 gap-4">
                                <div className="col-span-3">
                                    <Field>
                                        <Label>
                                            <Trans i18nKey="memberEditDialog.contacts.fields.address.street.label"/>
                                        </Label>
                                        <Input
                                            type="text"
                                            id="street"
                                            {...register('address.street')}
                                            invalid={Boolean(errors.address?.street)}
                                            aria-label={t("memberEditDialog.contacts.fields.address.street.ariaLabel")}
                                        />
                                    </Field>
                                </div>
                                <div className="col-span-1">
                                    <Field>
                                        <Label>
                                            <Trans
                                                i18nKey="memberEditDialog.contacts.fields.address.houseNumber.label"/>
                                        </Label>
                                        <Input
                                            type="text"
                                            id="houseNo"
                                            {...register('address.houseNumber')}
                                            invalid={Boolean(errors.address?.houseNumber)}
                                            aria-label={t("memberEditDialog.contacts.fields.address.houseNumber.ariaLabel")}
                                        />
                                    </Field>
                                </div>
                                {(errors.address?.street || errors.address?.houseNumber) && (
                                    <div className="col-span-4">
                                        {errors.address?.street && (
                                            <ValidationAlert message={
                                                t(errors.address?.street?.message ||
                                                    "memberEditDialog.contacts.errors.unknown-street-issue")}
                                            />

                                        )}
                                        {errors.address?.houseNumber && (
                                            <ValidationAlert message={
                                                t(errors.address?.houseNumber?.message ||
                                                    "memberEditDialog.contacts.errors.unknown-houseNumber-issue")}
                                            />

                                        )}
                                    </div>
                                )}
                            </div>

                            <div className="grid grid-cols-4 gap-4">
                                <div className="col-span-1">
                                    <Field>
                                        <Label>
                                            <Trans i18nKey="memberEditDialog.contacts.fields.address.postcode.label"/>
                                        </Label>
                                        <Input
                                            type="text"
                                            id="postcode"
                                            autoComplete="postal-code"
                                            {...register('address.postcode')}
                                            invalid={Boolean(errors.address?.postcode)}
                                            aria-label={t("memberEditDialog.contacts.fields.address.postcode.ariaLabel")}
                                        />
                                    </Field>
                                </div>
                                <div className="col-span-3">
                                    <Field>
                                        <Label>
                                            <Trans i18nKey="memberEditDialog.contacts.fields.address.city.label"/>
                                        </Label>
                                        <Input
                                            type="text"
                                            id="city"
                                            autoComplete="locality"
                                            {...register('address.city')}
                                            invalid={Boolean(errors.address?.city)}
                                            aria-label={t("memberEditDialog.contacts.fields.address.city.ariaLabel")}
                                        />

                                    </Field>
                                </div>
                                {(errors.address?.postcode || errors.address?.city) && (
                                    <div className="col-span-4">

                                        {errors.address?.postcode && (
                                            <ValidationAlert message={
                                                t(errors.address?.postcode?.message ||
                                                    "memberEditDialog.contacts.errors.unknown-postcode-issue")}
                                            />

                                        )}
                                        {errors.address?.city && (
                                            <ValidationAlert message={
                                                t(errors.address?.city?.message ||
                                                    "memberEditDialog.contacts.errors.unknown-city-issue")}
                                            />

                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <DialogActions>
                        <Button
                            type="button"
                            plain
                            onClick={handleClosing}
                            aria-label={t("memberEditDialog.contacts.buttons.cancel.ariaLabel")}
                        >
                            <Trans i18nKey="memberEditDialog.contacts.buttons.cancel.text"/>
                        </Button>
                        <Button
                            type="submit"
                            aria-label={t("memberEditDialog.contacts.buttons.save.ariaLabel")}
                        >
                            <Trans i18nKey="memberEditDialog.contacts.buttons.save.text"/>
                        </Button>
                    </DialogActions>
                </form>
            </DialogBody>
        </Dialog>
    );
}
