'use client'

import React, {useState} from "react";
import {Button} from "./tailwind/button.tsx";
import {Dialog, DialogActions, DialogDescription, DialogTitle} from "./tailwind/dialog.tsx";
import {Trans, useTranslation} from "react-i18next";
import {deleteUser} from "../services/user-services.ts";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../context/use-auth.tsx";
import {useToast} from "@/hooks/use-toast.ts";


export default function DeleteConfirmation({userId, name, username, ...props}: {
    userId: string,
    name: string,
    username: string
} & React.ComponentPropsWithoutRef<typeof Button>) {
    const [isOpen, setIsOpen] = useState(false)
    const navigate = useNavigate()
    const {toast} = useToast()
    const {t} = useTranslation()

    // TODO check token and react accordingly
    const {token} = useAuth()

    function performConfirmedDelete() {
        deleteUser(userId, token!!)
            .then(() => {
                console.log("User deleted successfully.")
                setIsOpen(false)
                navigate("/admin/users")
                toast({
                    title: t("userForm.dialog.toast.success.title"),
                    description: t("userForm.dialog.toast.success.description"),
                    duration: 10000,
                })


            })
            .catch((reason) => {
                console.log("Something went REALLY wrong while deleting the user", reason)
                setIsOpen(false)
                toast({
                    title: t("userForm.dialog.toast.error.title"),
                    description: t("userForm.dialog.toast.error.description"),
                    variant: "destructive",
                    duration: 10000,
                })
            })
    }

    return (
        <>
            <Button type="button" onClick={() => setIsOpen(true)} {...props} />
            <Dialog open={isOpen} onClose={setIsOpen}>
                <DialogTitle><Trans i18nKey="userForm.dialog.delete.title"/></DialogTitle>
                <DialogDescription>
                    <Trans i18nKey="userForm.dialog.delete.description" values={{
                        "name": name,
                        "username": username
                    }}/>
                </DialogDescription>
                <DialogActions>
                    <Button plain onClick={() => setIsOpen(false)}>Abbrechen</Button>
                    <Button onClick={performConfirmedDelete}>Löschen</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
