import {Controller, useForm} from 'react-hook-form';
import {z} from 'zod';
import {zodResolver} from '@hookform/resolvers/zod';
import {Trans, useTranslation} from 'react-i18next';
import {Dialog, DialogActions, DialogBody, DialogTitle} from '@/components/tailwind/dialog';
import {Button} from '@/components/tailwind/button';
import {Input} from '@/components/tailwind/input';
import {Member, MemberDeleteBankingDetailsRequest, MemberUpdateBankingDetailsRequest} from "@/models/member.ts";
import ValidationAlert from "@/components/validation-alert.tsx";
import {Field, Label} from "@/components/tailwind/fieldset.tsx";
import {useAuth} from "@/context/use-auth.tsx";
import {useEffect, useMemo, useState} from "react";
import {isDefined} from "@/helpers/sanitizer.ts";
import {isValidIBAN} from "ibantools-germany";
import {isValidBIC} from "bankdata-germany";
import {deleteBankingDetails, updateBankingDetails} from "@/services/member-services.ts";
import {useToast} from "@/hooks/use-toast.ts";
import DeleteConfirmation from "@/components/generic-delete-confirmation.tsx";
import {v4 as uuid} from "uuid";

interface MemberEditBankDetailsDialogProps {
    isOpen: boolean;
    onSave: (data: Member) => void;
    onClose: () => void;
    onDelete: () => void;
    memberData: Member;
}


export default function MemberEditBankingDetailsDialog({
                                                           isOpen,
                                                           onSave,
                                                           onClose,
                                                           onDelete,
                                                           memberData,
                                                       }: MemberEditBankDetailsDialogProps) {
    const {t} = useTranslation();
    const [isIbanEditable, setIsIbanEditable] = useState(false);
    const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
    const {toast} = useToast();
    const isNewEntry = !isDefined(memberData.bankingDetails?.id)

    const memberEditBankDetailsSchema = z.object({
        accountOwner: z.string()
            .trim()
            .nullable()
            .transform(val => (!val ? undefined : val))
            .optional(),
        mandateReference: z.string()
            .trim()
            .nullable()
            .transform(val => (!val ? undefined : val))
            .optional(),
        iban: z.string()
            .trim()
            .nullable()
            .transform(val => (!val ? undefined : val))
            .optional(),

        bic: z.string()
            .trim()
            .nullable()
            .transform(val => (!val ? undefined : val))
            .optional()
            .refine(
                (value) => !isDefined(value) || isValidBIC(value),
                {message: "memberEditDialog.bankingDetails.errors.invalid-bic"}
            ),
        bankName: z.string()
            .trim()
            .nullable()
            .transform(val => (!val ? undefined : val))
            .optional(),
        mandateDate: z.union([
            z.string().refine(
                (value) => !isNaN(Date.parse(value)),
                {message: "memberEditDialog.bankingDetails.errors.invalid-mandate-date-format"}
            ),
            z.string()
                .trim()
                .nullable()
                .transform(val => (!val ? undefined : val))
                .optional(),
        ])
    }).refine(
        (data) => {
            if (isNewEntry || (isDefined(data.iban) && isIbanEditable)) {
                return isValidIBAN(data.iban?.replace(/\s+/g, ''));
            }
            return true;
        },
        {
            message: "memberEditDialog.bankingDetails.errors.invalid-iban",
            path: ["iban"]
        }
    ).refine(
        (data) => {
            // Mandatsdatum ist nur erforderlich, wenn IBAN eingegeben wurde
            if (isNewEntry || isDefined(data.iban)) {
                return isDefined(data.mandateDate);
            }
            return true;
        },
        {
            message: "memberEditDialog.bankingDetails.errors.mandate-date-required",
            path: ["mandateDate"]
        }
    ).refine(
        (data) => {
            // Mandatsreferenz ist nur erforderlich, wenn IBAN eingegeben wurde
            if (isNewEntry || isDefined(data.iban)) {
                return isDefined(data.mandateReference);
            }
            return true;
        },
        {
            message: "memberEditDialog.bankingDetails.errors.mandate-reference-required",
            path: ["mandateReference"]
        }
    );

    type MemberEditBankDetailsFormData = z.infer<typeof memberEditBankDetailsSchema>;

    const defaultValues = useMemo(() => ({
        accountOwner: memberData.bankingDetails?.accountOwner,
        iban: memberData.bankingDetails?.iban,
        bic: memberData.bankingDetails?.bic,
        bankName: memberData.bankingDetails?.bankName,
        mandateReference: memberData.bankingDetails?.mandateReference ||
            String(memberData.memberNo),
        mandateDate: memberData.bankingDetails?.mandateDate ||
            (isNewEntry ? new Date().toISOString().split('T')[0] : undefined),
    }), [
        memberData.memberNo,
        memberData.bankingDetails?.accountOwner,
        memberData.bankingDetails?.iban,
        memberData.bankingDetails?.bic,
        memberData.bankingDetails?.mandateReference,
        memberData.bankingDetails?.mandateDate,
        memberData.bankingDetails?.bankName
    ]);

    const {
        register,
        handleSubmit,
        control,
        setValue,
        watch,
        setError,
        formState: {errors},
        reset
    } = useForm<MemberEditBankDetailsFormData>({
        resolver: zodResolver(memberEditBankDetailsSchema),
        defaultValues
    });

    const ibanValue = watch('iban');

    useEffect(() => {
        if (isOpen && memberData) {
            reset(defaultValues);
            setIsIbanEditable(!isDefined(memberData.bankingDetails?.iban));
        }
    }, [isOpen, memberData, reset, defaultValues]);

    const {token} = useAuth();

    function handleEditIban() {
        setIsIbanEditable(true);
        setValue('iban', '');
    }

    async function saveData(data: MemberEditBankDetailsFormData): Promise<boolean> {
        // Mandate reference and date MUST BE set here - otherwise
        const bankDetailsData: MemberUpdateBankingDetailsRequest = {
            id: memberData.bankingDetails?.id || uuid(),
            memberId: memberData.id,
            accountOwner: data.accountOwner,
            bankName: data.bankName,
            bic: data.bic,
            mandateDate: data.mandateDate!!,
            mandateReference: data.mandateReference!!,
            isNew: isNewEntry,
            ibanEdited: isIbanEditable,
            iban: isIbanEditable ? data.iban : undefined
        };

        try {
            const response = await updateBankingDetails(token, bankDetailsData);
            if (response) {
                onSave(response);
            }
            return true;
        } catch (error: any) {
            console.error("Error updating member bank details: ", error);
            if (Boolean(error.status)) {
                setError("root", {
                    message: t("serverError.response-code-with-details", {
                        statusCode: error.status,
                        errorDetails: error.error
                    })
                });
            } else {
                setError("root", {message: t("serverError.unknown-with-details", {errorDetails: error.message})});
            }
            return false;
        }
    }

    async function onSubmit(data: MemberEditBankDetailsFormData) {
        if (await saveData(data)) {
            handleClosing();
        }
    }

    async function deleteBankingDetailsData(): Promise<boolean> {
        const deleteDetailDTO: MemberDeleteBankingDetailsRequest = {
            memberId: memberData.id,
            bankAccountId: memberData.bankingDetails!!.id,
        }

        try {
            await deleteBankingDetails(token, deleteDetailDTO);
            return true;
        } catch (error: any) {
            console.error("Error updating member bank details: ", error);
            if (Boolean(error.status)) {
                setError("root", {
                    message: t("serverError.response-code-with-details", {
                        statusCode: error.status,
                        errorDetails: error.error
                    })
                });
            } else {
                setError("root", {message: t("serverError.unknown-with-details", {errorDetails: error.message})});
            }
            return false;
        }

    }

    async function onDeleteDetails() {
        setIsDeleteConfirmationOpen(false);
        if (await deleteBankingDetailsData()) {
            toast({
                title: t("memberEditDialog.bankingDetails.deleteConfirmation.toast.title"),
                description: t("memberEditDialog.bankingDetails.deleteConfirmation.toast.description"),
                duration: 15000,
            });
            onDelete();
            handleClosing();
        }
    }

    function handleClosing() {
        reset();
        setIsIbanEditable(false);
        onClose();
    }

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle>
                <Trans i18nKey="memberEditDialog.bankingDetails.title"/>
            </DialogTitle>

            <DialogBody>
                {errors.root && (
                    <ValidationAlert message={errors.root?.message ||
                        t("serverError.unknown")}/>
                )}
                <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
                    <div>
                        <Field>
                            <Label><Trans i18nKey="memberEditDialog.bankingDetails.fields.accountOwner.label"/></Label>
                            <Input
                                {...register("accountOwner")}
                                type="text"
                                autoComplete="name"
                                invalid={Boolean(errors.accountOwner)}
                                placeholder={t("memberEditDialog.bankingDetails.fields.accountOwner.placeholder")}
                                aria-label={t("memberEditDialog.bankingDetails.fields.accountOwner.ariaLabel")}
                            />
                        </Field>
                        {errors.accountOwner && (
                            <ValidationAlert
                                message={t(errors.accountOwner?.message ||
                                    "memberEditDialog.bankingDetails.errors.unknown-accountOwner-issue")}
                            />
                        )}
                    </div>

                    <div>
                        <Field>
                            <Label>
                                <Trans i18nKey="memberEditDialog.bankingDetails.fields.iban.label"/>
                            </Label>
                            <div className="flex items-center space-x-2">
                                <Input
                                    {...register("iban")}
                                    type="text"
                                    autoComplete="iban"
                                    disabled={!isIbanEditable}
                                    invalid={Boolean(errors.iban)}
                                    placeholder={isIbanEditable ?
                                        t("memberEditDialog.bankingDetails.fields.iban.placeholder") :
                                        t("memberEditDialog.bankingDetails.fields.iban.obfuscated")}
                                    aria-label={t("memberEditDialog.bankingDetails.fields.iban.ariaLabel")}
                                />
                                {!isIbanEditable && isDefined(ibanValue) && (
                                    <Button
                                        type="button"
                                        plain
                                        onClick={handleEditIban}
                                        //className="text-blue-600 hover:text-blue-800"
                                        aria-label={t("memberEditDialog.bankingDetails.buttons.editIban.ariaLabel")}
                                    >
                                        <Trans i18nKey="memberEditDialog.bankingDetails.buttons.editIban.text"/>
                                    </Button>
                                )}
                            </div>
                        </Field>
                        {errors.iban && (
                            <ValidationAlert
                                message={t(errors.iban?.message ||
                                    "memberEditDialog.bankingDetails.errors.unknown-iban-issue")}
                            />
                        )}
                    </div>


                    <div>
                        <Field>
                            <Label><Trans i18nKey="memberEditDialog.bankingDetails.fields.bic.label"/></Label>
                            <Input
                                {...register("bic")}
                                type="text"
                                autoComplete="bic"
                                aria-label={t("memberEditDialog.bankingDetails.fields.bic.ariaLabel")}
                                invalid={Boolean(errors.bic)}
                                placeholder={t("memberEditDialog.bankingDetails.fields.bic.placeholder")}
                            />

                            {errors.bic && (
                                <ValidationAlert message={t(errors.bic?.message ||
                                    "memberEditDialog.bankingDetails.errors.unknown-title-issues")}/>
                            )}
                        </Field>
                    </div>

                    <div>
                        <Field>
                            <Label>
                                <Trans i18nKey="memberEditDialog.bankingDetails.fields.bankName.label"/>
                            </Label>
                            <Input
                                {...register("bankName")}
                                type="text"
                                invalid={Boolean(errors.bankName)}
                                aria-label={t("memberEditDialog.bankingDetails.fields.bankName.ariaLabel")}
                                placeholder={t("memberEditDialog.bankingDetails.fields.bankName.placeholder")}
                            />
                            {errors.bankName && (
                                <ValidationAlert
                                    message={t(errors.bankName?.message ||
                                        "memberEditDialog.bankingDetails.errors.unknown-bankName-issues")}
                                />
                            )}
                        </Field>
                    </div>

                    <div>
                        <Field>
                            <Label>
                                <Trans i18nKey="memberEditDialog.bankingDetails.fields.mandateReference.label"/>
                            </Label>
                            <Input
                                type="text"
                                {...register('mandateReference')}
                                invalid={Boolean(errors.mandateReference)}
                                aria-label={t("memberEditDialog.bankingDetails.fields.mandateReference.ariaLabel")}
                                placeholder={t("memberEditDialog.bankingDetails.fields.mandateReference.placeholder")}
                            />
                            {errors.mandateReference && (
                                <ValidationAlert
                                    message={t(errors.mandateReference?.message ||
                                        "memberEditDialog.bankingDetails.errors.unknown-mandateReference-issue")}
                                />
                            )}
                        </Field>
                    </div>

                    <div>
                        <Field>
                            <Label>
                                <Trans i18nKey="memberEditDialog.bankingDetails.fields.mandateDate.label"/>
                            </Label>

                            <Controller
                                name="mandateDate"
                                control={control}
                                render={({field}) => (
                                    <Input
                                        type="date"
                                        value={field.value || ''}
                                        onChange={(e) => {
                                            const value =
                                                e.target.value || null;
                                            field.onChange(value);
                                        }}
                                        aria-label={t("memberEditDialog.bankingDetails.fields.mandateDate.label")}
                                        invalid={Boolean(errors.mandateDate)}
                                    />
                                )}
                            />

                            {errors.mandateDate && (
                                <ValidationAlert
                                    message={t(errors.mandateDate?.message ||
                                        "memberEditDialog.bankingDetails.errors.unknown-mandateDate-issue")}
                                />
                            )}
                        </Field>
                    </div>

                    <DialogActions className="flex flex-col sm:flex-row w-full gap-2">
                        {Boolean(memberData.bankingDetails?.id) && (
                            <>
                                <div className="order-2 sm:order-1 sm:flex-grow text-left">
                                    <Button
                                        type="button"
                                        color={"red"}
                                        onClick={() => setIsDeleteConfirmationOpen(true)}
                                        aria-label={t("memberEditDialog.bankingDetails.buttons.delete.ariaLabel")}
                                        className="w-full sm:w-auto"
                                    >
                                        <Trans i18nKey="memberEditDialog.bankingDetails.buttons.delete.text"/>
                                    </Button>
                                </div>

                                <DeleteConfirmation isOpen={isDeleteConfirmationOpen}
                                                    onConfirm={onDeleteDetails}
                                                    onCancel={() => setIsDeleteConfirmationOpen(false)}
                                                    confirmationMsg={t("memberEditDialog.bankingDetails.deleteConfirmation.message")}
                                />
                            </>
                        )}

                        <div className="order-1 sm:order-2 flex flex-col sm:flex-row w-full sm:w-auto gap-2">
                            <Button
                                type="button"
                                plain
                                onClick={handleClosing}
                                aria-label={t("memberEditDialog.bankingDetails.buttons.cancel.ariaLabel")}
                                className="w-full sm:w-auto"
                            >
                                <Trans i18nKey="memberEditDialog.bankingDetails.buttons.cancel.text"/>
                            </Button>
                            <Button
                                type="submit"
                                aria-label={t("memberEditDialog.bankingDetails.buttons.save.ariaLabel")}
                                className="w-full sm:w-auto"
                            >
                                <Trans i18nKey="memberEditDialog.bankingDetails.buttons.save.text"/>
                            </Button>
                        </div>
                    </DialogActions>
                </form>
            </DialogBody>
        </Dialog>
    );
}
