import React, {useState} from 'react';
import {DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger} from '@/components/ui/dropdown-menu';
import {Button} from '@/components/ui/button';
import {AlignJustify, Plus, Trash2} from 'lucide-react';
import {Text} from "@/components/tailwind/text.tsx";
import {Member} from "@/models/member.ts";
import {useTranslation} from "react-i18next";
import MemberBookPointsDialog from "@/components/member-book-points-dialog.tsx";
import {useAuth} from "@/context/use-auth.tsx";
import {addPointsToMember, CurrentPointsDTO} from "@/services/member-services.ts";
import {isDefined} from "@/helpers/sanitizer.ts";
import DeleteConfirmation from "@/components/generic-delete-confirmation.tsx";

interface MemberActionsProps {
    onAddPoints: (addPointsDTO: CurrentPointsDTO) => void;
    onDelete: () => void;
    memberData: Member
}

const MemberActions: React.FC<MemberActionsProps> = ({onAddPoints, onDelete, memberData}) => {
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [isPointsDialogOpen, setPointsDialogOpen] = useState(false);

    const {t} = useTranslation()
    const {token} = useAuth()

    const handleDeleteClick = () => {
        setDialogOpen(true);
    };

    const handleConfirm = () => {
        setDialogOpen(false);
        onDelete();
    };

    const handleCancel = () => {
        setDialogOpen(false);
    };

    function handleAddPoints() {
        setPointsDialogOpen(true)
    }

    const composeMemberName = () => {
        let nameForDialog: string = ""
        const hasFullName = Boolean(memberData.firstName) && Boolean(memberData.lastName)
        const hasCompanyName = Boolean(memberData.companyName)
        const hasSalutation = Boolean(memberData.salutation?.value)

        if (hasFullName) {
            nameForDialog = (hasSalutation ? `${memberData.salutation?.value} ` : "") +
                `${memberData.firstName} ${memberData.lastName}`
        }

        if (hasCompanyName) {
            let companyName = memberData.companyName

            if (hasFullName) {
                nameForDialog += ` (${companyName})`
            } else {
                nameForDialog = t("memberDetails.companyPrefix") + ` ${companyName}`
            }
        }

        return nameForDialog
    }

    function onPointDialogSubmission(pointsData: { points: number, description: string }) {
        const addPointsDTO = {
            memberId: memberData.id,
            points: pointsData.points,
            description: pointsData.description
        }
        console.debug("Adding points to member with data: ", addPointsDTO)

        const addPointsRequest = addPointsToMember(token, addPointsDTO)

        addPointsRequest
            .then((response) => {
                if (isDefined<CurrentPointsDTO>(response)) {
                    // TODO Update points in member data
                    onAddPoints(response)
                }
            })
            .catch((errorDetails) => {
                // FIXME show error message
                console.warn("Unable to add points to member. Error details: ", errorDetails)
            })
            .finally(() => {
                setPointsDialogOpen(false)
            })
    }

    return (
        <>
            <DropdownMenu>
                <DropdownMenuTrigger asChild>
                    <Button variant="ghost" className="px_3">
                        <AlignJustify className="mr-2 h-4 w-4 text-gray-600 dark:text-gray-200"/>
                        <Text>Aktionen</Text>
                    </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end" className="w-48">
                    <DropdownMenuItem onClick={handleAddPoints}>
                        <Plus className="mr-2 h-4 w-4"/>
                        <Text>Punkte gutschreiben</Text>
                    </DropdownMenuItem>
                    <DropdownMenuItem onClick={handleDeleteClick} className="text-red-600">
                        <Trash2 className="mr-2 h-4 w-4"/>
                        <Text>Löschen</Text>
                    </DropdownMenuItem>
                </DropdownMenuContent>
            </DropdownMenu>

            <MemberBookPointsDialog
                memberData={memberData}
                isOpen={isPointsDialogOpen}
                onClose={() => setPointsDialogOpen(false)}
                onSubmission={onPointDialogSubmission}
            />

            <DeleteConfirmation
                isOpen={isDialogOpen}
                onConfirm={handleConfirm}
                onCancel={handleCancel}
                confirmationMsg={t("memberActions.deleteConfirmationMsg", {memberName: composeMemberName()})}
            />

        </>
    );
};

export default MemberActions;
