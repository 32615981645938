import {Trans, useTranslation} from "react-i18next";
import {Dialog, DialogActions, DialogDescription, DialogTitle} from "@/components/tailwind/dialog.tsx";
import {Button} from "@/components/tailwind/button.tsx";
import {useState} from "react";

type DeleteConfirmationProps = {
    isOpen: boolean;
    onConfirm: (entityId: string | undefined) => void;
    onCancel: () => void;
    confirmationMsg?: string;
    entityId?: string | undefined;
};

export default function DeleteConfirmation({
                                               isOpen,
                                               onConfirm,
                                               onCancel,
                                               confirmationMsg,
                                               entityId
                                           }: DeleteConfirmationProps) {
    const {t} = useTranslation();

    if (!isOpen) return null;

    function handleSubmit() {
        onConfirm(entityId);
        onCancel();
    }

    return (
        <Dialog open={isOpen} onClose={onCancel}>
            <DialogTitle><Trans i18nKey={"deleteConfirmation.heading"}/></DialogTitle>
            <DialogDescription>
                {confirmationMsg || t("deleteConfirmation.defaultMessage")}
            </DialogDescription>
            <DialogActions>
                <Button
                    plain
                    onClick={onCancel}
                    aria-label={t("deleteConfirmation.buttons.cancel.ariaLabel")}
                >
                    <Trans i18nKey={"deleteConfirmation.buttons.cancel.text"}/>
                </Button>
                <Button
                    onClick={handleSubmit}
                    color={"red"}
                    aria-label={t("deleteConfirmation.buttons.submit.ariaLabel")}
                >
                    <Trans i18nKey={"deleteConfirmation.buttons.submit.text"}/>
                </Button>
            </DialogActions>
        </Dialog>

    );
}

export function useDeleteConfirmation() {
    const {t} = useTranslation();

    const [isOpen, setIsOpen] = useState(false);
    const [confirmationMsg, setConfirmationMsg] = useState<string | undefined>(undefined);
    const [entityId, setEntityId] = useState<string | undefined>(undefined);

    function openDialog(confirmationMsg?: string | undefined, entityId?: string | undefined) {
        setConfirmationMsg(confirmationMsg || t("deleteConfirmation.defaultMessage"))
        setEntityId(entityId)
        setIsOpen(true);
    }

    function closeDialog() {
        setIsOpen(false);
    }

    return {
        isDeleteConfirmationOpen: isOpen,
        confirmationMessage: confirmationMsg,
        givenEntityId: entityId,
        openDeleteConfirmationDialog: openDialog,
        closeDeleteConfirmationDialog: closeDialog,
        confirmationMsg,
        entityId: entityId,
        deleteConfirmationDialogProps: {
            isOpen: isOpen,
            onCancel: closeDialog,
            confirmationMsg: confirmationMsg,
            entityId: entityId
        }
    }
}
