"use client"

/**
 * See also: https://ui.shadcn.com/docs/components/data-table
 */

import {
    Column,
    ColumnDef,
    flexRender,
    getCoreRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    PaginationState,
    SortingState,
    useReactTable,
} from "@tanstack/react-table"

import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow,} from "@/components/ui/table.tsx"
import {ChangeEvent, useEffect, useState} from "react";
import {Trans} from "react-i18next";
import {ArrowDownAZ, ArrowUpAZ, ArrowUpDown} from "lucide-react";
import {Link} from "@/components/tailwind/link.tsx";
import {Button} from "@/components/tailwind/button.tsx";
import {Text} from "@/components/tailwind/text.tsx";
import {Select} from "@/components/tailwind/select.tsx";
import {useUserSettingsStore} from "@/stores/user-settings.ts";

interface DataTableProps<TData, TValue> {
    columns: ColumnDef<TData, TValue>[]
    data: TData[]
}

export function DataTable<TData, TValue>({
                                             columns,
                                             data,
                                         }: DataTableProps<TData, TValue>) {
    const {tableSettings, setPageSize} = useUserSettingsStore()
    const [sorting, setSorting] = useState<SortingState>([])
    const [pagination, setPagination] = useState<PaginationState>({
        pageSize: tableSettings.pageSize,
        pageIndex: 0
    })

    useEffect(() => {
        setPagination(prev => ({
            ...prev,
            pageSize: tableSettings.pageSize
        }))
    }, [tableSettings])

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        onSortingChange: setSorting,
        onPaginationChange: setPagination,
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        state: {
            sorting,
            pagination
        }
    })

    const handlePageSizeChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const newSize = Number(e.target.value)
        setPageSize(newSize)
        table.setPageSize(newSize)
    }

    return (
        <div>
            <Table className="min-w-full text-left text-sm/6 text-zinc-950 dark:text-white">
                <TableHeader className="text-zinc-500 dark:text-zinc-400 border-b">
                    {table.getHeaderGroups().map((headerGroup) => (
                        <TableRow key={headerGroup.id}
                                  className="has-[[data-row-link][data-focus]]:outline has-[[data-row-link][data-focus]]:outline-2 has-[[data-row-link][data-focus]]:-outline-offset-2 has-[[data-row-link][data-focus]]:outline-blue-500 dark:focus-within:bg-white/[2.5%] hover:bg-zinc-950/5 dark:hover:bg-white/5">
                            {headerGroup.headers.map((header) => {
                                return (
                                    <TableHead
                                        key={header.id}
                                        className="border-b-zinc-950/10 px-4 py-2 font-medium first:pl-[var(--gutter,theme(spacing.2))] last:pr-[var(--gutter,theme(spacing.2))] dark:border-b-white/10 "
                                    >
                                        {header.isPlaceholder
                                            ? null
                                            : flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                            )}
                                    </TableHead>
                                )
                            })}
                        </TableRow>
                    ))}
                </TableHeader>
                <TableBody>
                    {table.getRowModel().rows?.length ? (
                        table.getRowModel().rows.map((row) => (
                            <TableRow
                                key={row.id}
                                data-state={row.getIsSelected() && "selected"}
                                className="even:bg-zinc-950/[2.5%] dark:even:bg-white/[2.5%] hover:bg-zinc-950/5 dark:hover:bg-white/5 has-[[data-row-link][data-focus]]:outline has-[[data-row-link][data-focus]]:outline-2 has-[[data-row-link][data-focus]]:-outline-offset-2 has-[[data-row-link][data-focus]]:outline-blue-500 dark:focus-within:bg-white/[2.5%]"
                            >
                                {row.getVisibleCells().map((cell) => (
                                    <TableCell key={cell.id}
                                               className="text-base/6 text-zinc-500 sm:text-sm/6 dark:text-zinc-400">
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))
                    ) : (
                        <TableRow>
                            <TableCell colSpan={columns.length}
                                       className="even:bg-zinc-950/[2.5%] dark:even:bg-white/[2.5%] hover:bg-zinc-950/5 dark:hover:bg-white/5 has-[[data-row-link][data-focus]]:outline has-[[data-row-link][data-focus]]:outline-2 has-[[data-row-link][data-focus]]:-outline-offset-2 has-[[data-row-link][data-focus]]:outline-blue-500 dark:focus-within:bg-white/[2.5%]">
                                <Trans i18nKey="table.no-data"/>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>

            <div className="flex flex-col gap-4 px-2 py-4 md:flex-row md:items-center md:justify-between">
                {/* Einträge pro Seite */}
                <div className="flex items-center justify-center gap-4">
                    <Text className="whitespace-nowrap">
                        <Trans i18nKey="table.entriesPerPage"/>
                    </Text>
                    <Select
                        value={pagination.pageSize.toString()}
                        onChange={handlePageSizeChange}
                        className="w-32"
                    >
                        {[10, 20, 30, 40, 50].map((size) => (
                            <option key={size} value={size.toString()}>
                                {size}
                            </option>
                        ))}
                    </Select>
                </div>

                <div className="text-center md:flex-1">
                    <Text>
                        <Trans
                            i18nKey="table.pageInfo"
                            values={{
                                from: pagination.pageIndex * pagination.pageSize + 1,
                                to: Math.min((pagination.pageIndex + 1) * pagination.pageSize, table.getFilteredRowModel().rows.length),
                                total: table.getFilteredRowModel().rows.length
                            }}
                        />
                    </Text>
                </div>

                <div className="flex justify-center gap-2">
                    <Button
                        plain
                        onClick={() => table.previousPage()}
                        disabled={!table.getCanPreviousPage()}
                    >
                        <Trans i18nKey="table.previousPage"/>
                    </Button>
                    <Button
                        plain
                        onClick={() => table.nextPage()}
                        disabled={!table.getCanNextPage()}
                    >
                        <Trans i18nKey="table.nextPage"/>
                    </Button>
                </div>
            </div>
        </div>
    )
}

export function sortableColumnHeader<TData, TValue>(column: Column<TData, TValue>, i18nKey: string) {
    return (
        <Link
            href="#"
            role="button"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            <Trans i18nKey={i18nKey}/>
            {column.getIsSorted() === false && (
                <ArrowUpDown size={12} className="inline"/>
            )}
            {column.getIsSorted() === "asc" && (
                <ArrowDownAZ size={12} className="inline"/>
            )}
            {column.getIsSorted() === "desc" && (
                <ArrowUpAZ size={12} className="inline"/>
            )}

        </Link>
    )
}
