import React from 'react';
import {Plus} from 'lucide-react';
import {Heading} from "@/components/tailwind/heading.tsx";
import {Text} from "@/components/tailwind/text.tsx";
import {Button} from "@/components/tailwind/button.tsx";
import {Card} from "@/components/ui/card.tsx";

interface EmptyStateProps {
    title: string;
    description: string;
    buttonText: string;
    onAction: () => void;
    headerIcon?: React.ReactNode;
    buttonIcon?: React.ReactNode;
    className?: string;
}

function EmptyState({
                        title,
                        description,
                        buttonText,
                        onAction,
                        headerIcon = <Plus size={24}/>,
                        buttonIcon = <Plus size={16}/>,
                        className = "",
                    }: EmptyStateProps) {
    return (
        <Card className={`p-8 text-center ${className}`}>
            <div className="flex flex-col items-center justify-center space-y-4">
                <div className="rounded-full bg-gray-100 dark:bg-gray-800 p-3 mb-2">
                    {headerIcon}
                </div>
                <Heading level={2} className="text-lg font-medium">{title}</Heading>
                <Text className="text-gray-500 dark:text-gray-400 max-w-md mx-auto mb-4">
                    {description}
                </Text>
                <Button
                    onClick={onAction}
                    className="flex items-center gap-2"
                >
                    {buttonIcon}
                    <span>{buttonText}</span>
                </Button>
            </div>
        </Card>
    );
}

export default EmptyState;
